import { addParticipantSouvenirApi, getParticipantSouvenirListApi } from '@/api/souvenirApi';
import {
    IdSouvenirResult,
    SetSouvenir,
    Souvenir,
    SouvenirGenericType,
    SouvenirTrackType,
} from '@/helper/interface/souvenir.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { currentParticipant, participantMemories } from './sharedService';
import { MusicalListItemType } from '@/helper/enums/ui.enum';
import { IdPart } from '@/helper/interface/participant.interface';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { getAssets } from './assetsService';
import { AssetSourceEnum } from '@/helper/enums/music.enum';

/**
 * Get participant's souvenir list
 *
 * @param {number} participantId participant id
 * @return {*}  {Promise<IdSouvenirResult>} an id of response
 */
export const getParticipantSouvenirList = (participantId: number): Promise<Souvenir[]> => {
    return new Promise<Souvenir[]>(async (resolve, reject) => {
        const reqBody: IdPart = {
            par_id: participantId,
        };
        try {
            let souvenirs: Souvenir[] = await getParticipantSouvenirListApi(reqBody);
            if (typeof souvenirs !== 'string' && souvenirs.length > 0) {
                souvenirs = await Promise.all(
                    souvenirs.map(async (souvenir: Souvenir) => {
                        souvenir.logo = getIconUrl('/musics/music-round.png');
                        if (souvenir.artist) {
                            souvenir.title = souvenir.artist.name;
                            souvenir.logo = getIconUrl('/profiles/avatar.png');
                            if (souvenir.artist.asset) {
                                const logo = await getAssets([souvenir.artist.asset], AssetSourceEnum.Artist);
                                if (logo.length) souvenir.logo = logo[0].data;
                            }
                        } else if (souvenir.album) {
                            souvenir.title = souvenir.album.name;
                            if (souvenir.album.asset) {
                                const logo = await getAssets([souvenir.album.asset], AssetSourceEnum.Album);
                                if (logo.length) souvenir.logo = logo[0].data;
                            }
                        } else if (souvenir.track) {
                            souvenir.title = souvenir.track.name;
                            if (souvenir.asset) {
                                const logo = await getAssets([souvenir.asset], AssetSourceEnum.Album);
                                if (logo.length) souvenir.logo = logo[0].data;
                            }
                        }
                        return souvenir;
                    })
                );
            } else souvenirs = [];
            resolve(souvenirs);
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Set souvenir for a participant
 *
 * @param {MusicalListItem} item a musical list item
 * @param {string} description souvenir text
 * @param {number} souvenirId souvenir id for update
 * @return {*}  {Promise<IdSouvenirResult>} an id of response
 */
export const setParticipantSouvenir = (
    item: MusicalListItem,
    description: string,
    souvenirId?: number
): Promise<IdSouvenirResult> => {
    return new Promise<IdSouvenirResult>(async (resolve, reject) => {
        // prepare the request body dispending upon item type
        const reqBody: SetSouvenir = {
            par_id: currentParticipant.value?.par_id!,
            memory: {
                id: souvenirId,
                description,
            },
        };
        switch (item.type) {
            case MusicalListItemType.Artist:
                reqBody.memory.art_id = item.id;
                break;
            case MusicalListItemType.Album:
                reqBody.memory.alb_id = item.id;
                break;
            case MusicalListItemType.Track:
            case MusicalListItemType.Playlist:
                reqBody.memory.trk_id = item.id;
                break;
        }
        addParticipantSouvenirApi(reqBody)
            .then((response: IdSouvenirResult) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
