/**
 * types of questionnaire
 *
 * @export
 * @enum {number}
 */
export enum QuestionnaireType {
    Artist = 'ARTIST',
    Album = 'ALBUM',
    Genre = 'GENRE',
    Period = 'PERIOD',
    Track = 'TRACK',
    Category = 'CATEGORY',
    Summery = 'SUMMERY',
    List = 'LIST',
}
/**
 * types of user
 *
 * @export
 * @enum {number}
 */
export enum UserType {
    Staff = 'STAFF',
    Participant = 'PARTICIPANT',
}

/**
 * types of add user favorite response name
 *
 * @export
 * @enum {number}
 */
export enum UserFavoriteAddNameType {
    Track = 'TRACK',
    Artist = 'ARTIST',
    Album = 'ALBUM',
}
