import { QuestionnaireType, UserType } from '@/helper/enums/melovie.enum';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import { Eval, QuestionnaireData } from '@/helper/interface/eval.interface';
import {
    AlbumLike,
    ArtistLike,
    FavoritesPart,
    FavoritesStaff,
    TrackFavorite,
} from '@/helper/interface/favorite.interface';
import { AnsweredLayout } from '@/helper/interface/generic.interface';
import { Genre } from '@/helper/interface/music/genre.interface';
import { Period } from '@/helper/interface/music/period.interface';
import { ProfileParticipant } from '@/helper/interface/participant.interface';
import { ProfileStaff } from '@/helper/interface/staff.interface';
import { FavoriteCardItem } from '@/helper/interface/ui/components/favoriteCard';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { Asset, ConfirmProps } from '@/helper/interface/ui/ui.interface';
import {
    firstConnectionEvalName,
    partFavArtistViewCount,
    partFavGenresViewCount,
    partFavPeriodsViewCount,
    partFavSongViewCount,
    staffFavArtistViewCount,
    staffFavGenresViewCount,
    staffFavPeriodsViewCount,
    staffFavSongViewCount,
} from '@/helper/utils/constants';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { getAssets } from '@/services/assetsService';
import { loadGenericData, loadUserFavoriteData, loadUserProfileData } from '@/services/genericService';
import { generateNewQuestionnaireCount, getQuestionnaireData } from '@/services/questionnaireService';
import {
    isLoading,
    openAlertPopup,
    isAlertOpened,
    currentStaff,
    currentParticipant,
    staffFirstConnectionChecked,
    participantFirstConnectionChecked,
    allGenres,
    allPeriods,
    staffProfile,
    participantProfile,
    staffFavorites,
    participantFavorites,
    allEval,
    participantMemories,
} from '@/services/sharedService';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useQuestionnaire } from './useQuestionnaire';
import { getParticipantSouvenirList } from '@/services/souvenirService';

export function useGenericProfile() {
    const { t } = useI18n();
    const router = useRouter();
    const { onSubmit } = useQuestionnaire();
    const currentUserType = ref<UserType>(UserType.Staff);
    const favoriteGenres = ref<Genre[]>([]);
    const favoritePeriods = ref<Period[]>([]);
    const favoriteArtists = ref<ArtistLike[]>([]);
    const favoriteAlbums = ref<ArtistLike[]>([]);
    const favoriteTracks = ref<TrackFavorite[]>([]);
    const favoriteGenresItems = ref<FavoriteCardItem[]>([]);
    const favoritePeriodsItems = ref<FavoriteCardItem[]>([]);
    const favoriteArtistsItems = ref<FavoriteCardItem[]>([]);
    const favoriteTrackItems = ref<FavoriteCardItem[]>([]);
    const userProfile = ref<ProfileStaff | ProfileParticipant>({} as ProfileStaff);
    const firstConnectionEval = ref<Eval>();
    // first connection popup
    const isFirstConnectionPopup = ref<boolean>(false);

    const firstConnectionPopupData = ref<ConfirmProps>({
        confirmType: ConfirmType.Alert,
        title: t('views.dashboard.questionnaire.firstConnection'),
        headerIcon: 'fa-triangle-exclamation',
        left: {
            onClick: () => {
                isFirstConnectionPopup.value = false;
            },
        },
        right: {
            onClick: () => {
                isFirstConnectionPopup.value = false;
                router.push(
                    currentUserType.value === UserType.Participant ? '/participant/questionnaire' : '/questionnaire'
                );
            },
        },
    });
    const isPopupFinalResult = ref<boolean>(false);
    // edit popup
    const showQuestionnairePopup = ref<boolean>(false);
    const questionnaireData = ref<QuestionnaireData>({} as QuestionnaireData);

    let questionnaireListData = {
        eval_data: { title: t('generic.survey', 2) },
        children: [
            {
                children: [
                    {
                        eval_type: QuestionnaireType.List,
                    } as QuestionnaireData,
                ],
            } as QuestionnaireData,
        ],
    } as QuestionnaireData;

    let genreQuestionnaireData = {
        eval_data: { title: t('views.dashboard.questionnaire.yourFavoriteMusicalStyles') },
        children: [
            {
                eval_data: { title: t('views.dashboard.questionnaire.musicalStyles') },
                children: [
                    {
                        eval_type: QuestionnaireType.Genre,
                    } as QuestionnaireData,
                ],
            } as QuestionnaireData,
        ],
    } as QuestionnaireData;

    let periodQuestionnaireData = {
        eval_data: { title: t('views.dashboard.questionnaire.yourFavoritePeriod', 2) },
        children: [
            {
                eval_data: { title: t('views.dashboard.questionnaire.musicalPeriods') },
                children: [
                    {
                        eval_type: QuestionnaireType.Period,
                    } as QuestionnaireData,
                ],
            } as QuestionnaireData,
        ],
    } as QuestionnaireData;

    let artistQuestionnaireData = {
        eval_data: { title: t('views.dashboard.questionnaire.yourFavoriteArtists') },
        children: [
            {
                eval_data: { title: t('views.dashboard.profile.myFavoriteArtists') },
                children: [
                    {
                        eval_type: QuestionnaireType.Artist,
                    } as QuestionnaireData,
                ],
            } as QuestionnaireData,
        ],
    } as QuestionnaireData;

    let trackQuestionnaireData = {
        eval_data: { title: t('views.dashboard.questionnaire.yourFavoriteTracks') },
        children: [
            {
                eval_data: { title: t('views.dashboard.profile.myLastFavoriteSongs') },
                children: [
                    {
                        eval_type: QuestionnaireType.Track,
                    } as QuestionnaireData,
                ],
            } as QuestionnaireData,
        ],
    } as QuestionnaireData;

    /**
     * Questionnaire component onEdit function
     *
     * @param {QuestionnaireType} questionnaireType
     */
    const onEdit = async (questionnaireType: QuestionnaireType) => {
        try {
            isLoading.value = true;
            isPopupFinalResult.value = false;
            // fill popup default data
            if (firstConnectionEval.value?.eval_id) {
                if (genreQuestionnaireData.children?.[0].children?.[0]) {
                    genreQuestionnaireData.eval_id = firstConnectionEval.value.eval_id;
                    genreQuestionnaireData.children[0].eval_id = firstConnectionEval.value.eval_id;
                    genreQuestionnaireData.children[0].children[0].eval_id = firstConnectionEval.value.eval_id;
                }
                if (periodQuestionnaireData.children?.[0].children?.[0]) {
                    periodQuestionnaireData.eval_id = firstConnectionEval.value.eval_id;
                    periodQuestionnaireData.children[0].eval_id = firstConnectionEval.value.eval_id;
                    periodQuestionnaireData.children[0].children[0].eval_id = firstConnectionEval.value.eval_id;
                }
                if (artistQuestionnaireData.children?.[0].children?.[0]) {
                    artistQuestionnaireData.eval_id = firstConnectionEval.value.eval_id;
                    artistQuestionnaireData.children[0].eval_id = firstConnectionEval.value.eval_id;
                    artistQuestionnaireData.children[0].children[0].eval_id = firstConnectionEval.value.eval_id;
                }
            }

            // fill answers
            ((questionnaireListData.children as QuestionnaireData[])[0].children as QuestionnaireData[])[0].answer =
                allEval.value;
            ((genreQuestionnaireData.children as QuestionnaireData[])[0].children as QuestionnaireData[])[0].answer =
                favoriteGenres.value;
            ((periodQuestionnaireData.children as QuestionnaireData[])[0].children as QuestionnaireData[])[0].answer =
                favoritePeriods.value;
            ((artistQuestionnaireData.children as QuestionnaireData[])[0].children as QuestionnaireData[])[0].answer =
                await getFavArtistModalData();
            ((trackQuestionnaireData.children as QuestionnaireData[])[0].children as QuestionnaireData[])[0].answer =
                await getFavTrackModalData();

            // take proper questionnaire data
            switch (questionnaireType) {
                case QuestionnaireType.List:
                    questionnaireData.value = questionnaireListData;
                    break;
                case QuestionnaireType.Genre:
                    questionnaireData.value = genreQuestionnaireData;
                    break;
                case QuestionnaireType.Period:
                    questionnaireData.value = periodQuestionnaireData;
                    break;
                case QuestionnaireType.Artist:
                    questionnaireData.value = artistQuestionnaireData;
                    break;
                case QuestionnaireType.Track:
                    questionnaireData.value = trackQuestionnaireData;
                    break;
            }
        } catch (error: any) {
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: error,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
            isLoading.value = false;
        } finally {
            isLoading.value = false;
            showQuestionnairePopup.value = true;
        }
    };

    /**
     * On open an eval
     *
     * @param {number} evalId an eval id
     */
    const onOpenEval = async (evalId: number) => {
        try {
            showQuestionnairePopup.value = false;
            isLoading.value = true;
            isPopupFinalResult.value = true;
            questionnaireData.value = await getQuestionnaireData(evalId); // getting questionnaire data
            const questions = questionnaireData.value.children; // questions list

            // Adding answers if any question is already submitted before
            if (questions?.length)
                await Promise.all(
                    questions.map(async (question: QuestionnaireData) => {
                        const questionMetaData = question.children?.[0]; // getting question meta data
                        if (questionMetaData) {
                            // Adding answers
                            switch (questionMetaData.eval_type) {
                                case QuestionnaireType.Genre:
                                    questionMetaData.answer = favoriteGenres.value;
                                    break;
                                case QuestionnaireType.Period:
                                    questionMetaData.answer = favoritePeriods.value;
                                    break;
                                case QuestionnaireType.Artist:
                                    questionMetaData.answer = await getFavArtistModalData();
                                    break;
                                case QuestionnaireType.Album:
                                    questionMetaData.answer = await Promise.all(
                                        favoriteAlbums.value.map(async (album: AlbumLike) => {
                                            const result: MusicalListItem = {
                                                id: album.id,
                                                title: album.name || '',
                                                description: album.description || '',
                                                asset: album.asset ?? -1,
                                                type: MusicalListItemType.Album,
                                            };
                                            return result;
                                        })
                                    );
                                    break;
                                case QuestionnaireType.Track:
                                    questionMetaData.answer = await getFavTrackModalData();
                                    break;
                                // TODO: More case added in future depending upon new question types added
                            }
                        }
                    })!
                );
        } catch (error: any) {
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: error,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
            isLoading.value = false;
        } finally {
            isLoading.value = false;
            showQuestionnairePopup.value = true;
        }
    };

    /**
     * Get artist modal data
     *
     * @return {Promise<MusicalListItem[]>}  artist list
     */
    const getFavArtistModalData = async () => {
        return await Promise.all(
            favoriteArtists.value.map(async (artist: ArtistLike) => {
                const result: MusicalListItem = {
                    id: artist.id,
                    title: artist.name || '',
                    description: artist.description || '',
                    asset: artist.asset ?? -1,
                    type: MusicalListItemType.Artist,
                };
                return result;
            })
        );
    };

    /**
     * Get track modal data
     *
     * @return {Promise<MusicalListItem[]>}  music list
     */
    const getFavTrackModalData = async () => {
        return await Promise.all(
            favoriteTracks.value.map(async (track: TrackFavorite) => {
                const result: MusicalListItem = {
                    id: track.id,
                    title: track.name || '',
                    artist: track.artist || '',
                    description: track.description || '',
                    asset: track.asset ?? -1,
                    type: MusicalListItemType.Track,
                };
                return result;
            })
        );
    };

    /**
     * Questionnaire component onSubmit
     *
     * @param {QuestionnaireData[]} questionnaireData
     */
    const onSubmitQuestionnaire = (questionnaireData: QuestionnaireData[]) => {
        isLoading.value = true;
        onSubmit(questionnaireData, currentUserType.value)
            .then(async (res: boolean) => {
                if (res) {
                    await loadUserFavoriteData(currentUserType.value, true);
                    await initializeProfile();
                    closePopup();
                    openAlertPopup({
                        confirmType: ConfirmType.Success,
                        message: t('views.dashboard.profile.profileUpdated'),
                        title: t('generic.registered'),
                        right: {
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    });
                }
            })
            .catch((error) =>
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: error,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                })
            )
            .finally(() => (isLoading.value = false));
    };

    /**
     * Questionnaire close popup
     *
     */
    const closePopup = () => {
        showQuestionnairePopup.value = false;
    };

    /**
     * Getting profile details
     *
     */
    const initializeProfile = async () => {
        try {
            isLoading.value = true;
            await loadGenericData();
            if (
                (currentUserType.value === UserType.Staff && currentStaff.value?.staf_id) ||
                (currentUserType.value === UserType.Participant && currentParticipant.value?.par_id)
            ) {
                // profile
                await loadUserProfileData(currentUserType.value);

                generateNewQuestionnaireCount(currentUserType.value);

                userProfile.value =
                    currentUserType.value === UserType.Staff
                        ? staffProfile.value || {}
                        : currentUserType.value === UserType.Participant
                        ? participantProfile.value || {}
                        : ({} as ProfileStaff);

                firstConnectionEval.value = allEval.value.find(
                    (resEval: Eval) => resEval.eval_name === firstConnectionEvalName
                );

                if (
                    firstConnectionEval.value &&
                    !userProfile.value?.answeredLayouts?.find(
                        (ansLayout: AnsweredLayout) => ansLayout.eval_id === firstConnectionEval.value!.eval_id
                    )
                ) {
                    if (
                        (currentUserType.value === UserType.Staff && !staffFirstConnectionChecked.value) ||
                        (currentUserType.value === UserType.Participant && !participantFirstConnectionChecked.value)
                    ) {
                        isFirstConnectionPopup.value = true;
                        if (currentUserType.value === UserType.Staff) staffFirstConnectionChecked.value = true;
                        if (currentUserType.value === UserType.Participant)
                            participantFirstConnectionChecked.value = true;
                    }
                } else {
                    if (userProfile.value?.genres?.length || userProfile.value?.periods?.length) {
                        favoritePeriods.value = [];
                        favoriteGenres.value = [];
                        favoriteGenresItems.value =
                            allGenres.value
                                .filter((genre: Genre) => userProfile.value.genres?.includes(genre.gmus_id))
                                .map((genre: Genre) => {
                                    favoriteGenres.value.push(genre);
                                    return { text: genre.gmus_label, asset: genre.gmus_ass_id ?? -1, source: AssetSourceEnum.Reference };
                                })
                                .splice(
                                    0,
                                    currentUserType.value === UserType.Staff
                                        ? staffFavGenresViewCount
                                        : partFavGenresViewCount
                                ) || [];

                        favoritePeriodsItems.value =
                            allPeriods.value
                                .filter((period: Period) =>
                                    userProfile.value.periods?.includes(period.muspe_id)
                                )
                                .map((period: Period) => {
                                    favoritePeriods.value.push(period);
                                    return { text: period.muspe_label, asset: period.muspe_ass_id, source: AssetSourceEnum.Reference };
                                })
                                .splice(
                                    0,
                                    currentUserType.value === UserType.Staff
                                        ? staffFavPeriodsViewCount
                                        : partFavPeriodsViewCount
                                ) || [];
                    } else {
                        favoriteGenresItems.value = [];
                        favoritePeriodsItems.value = [];
                    }
                }

                // favorites
                await loadUserFavoriteData(currentUserType.value);
                const userFavorite: FavoritesStaff | FavoritesPart =
                    currentUserType.value === UserType.Staff
                        ? staffFavorites.value || {}
                        : currentUserType.value === UserType.Participant
                        ? participantFavorites.value || {}
                        : ({} as FavoritesStaff);

                favoriteArtists.value = [];
                favoriteArtistsItems.value = [];
                if (userFavorite?.artists?.length) {
                    favoriteArtistsItems.value = await Promise.all(
                        userFavorite.artists
                            .map(async (artist: ArtistLike) => {
                                favoriteArtists.value.push(artist);
                                return {
                                    text: artist.name,
                                    asset: artist.asset ?? -1,
                                    source: AssetSourceEnum.Artist,
                                };
                            })
                            .splice(
                                0,
                                currentUserType.value === UserType.Staff
                                    ? staffFavArtistViewCount
                                    : partFavArtistViewCount
                            )
                    );
                }

                if (userFavorite?.albums?.length) {
                    favoriteAlbums.value = userFavorite.albums;
                }

                favoriteTracks.value = [];
                favoriteTrackItems.value = [];
                if (userFavorite.tracks?.length) {
                    favoriteTrackItems.value = await Promise.all(
                        userFavorite.tracks
                            .map(async (track: TrackFavorite) => {
                                favoriteTracks.value.push(track);
                                return {
                                    text: track.name,
                                    asset: track.asset ?? -1,
                                    source: AssetSourceEnum.Album,
                                };
                            })
                            .splice(
                                0,
                                currentUserType.value === UserType.Staff ? staffFavSongViewCount : partFavSongViewCount
                            )
                    );
                }

                // Memories of participant
                if (currentUserType.value === UserType.Participant)
                    participantMemories.value = await getParticipantSouvenirList(currentParticipant.value?.par_id!);
            }
        } catch (error: any) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: error,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isLoading.value = false;
        }
    };

    const checkAnsweredQuestionnaire = (evalId: number): boolean =>
        userProfile.value.answeredLayouts?.find((ansLayout: AnsweredLayout) => ansLayout.eval_id === evalId)
            ? true
            : false;

    return {
        currentUserType,
        favoriteGenres,
        favoritePeriods,
        favoriteGenresItems,
        favoritePeriodsItems,
        favoriteArtistsItems,
        favoriteTrackItems,
        isFirstConnectionPopup,
        firstConnectionPopupData,
        isPopupFinalResult,
        showQuestionnairePopup,
        questionnaireData,
        onEdit,
        onOpenEval,
        onSubmitQuestionnaire,
        closePopup,
        initializeProfile,
        checkAnsweredQuestionnaire,
    };
}
