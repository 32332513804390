import { getAssetApi } from '@/api/music/assetApi';
import { AssetFormatEnum, AssetSourceEnum } from '@/helper/enums/music.enum';
import { AssetReqBody } from '@/helper/interface/music/asset.interface';
import { Asset, LocalAssetData } from '@/helper/interface/ui/ui.interface';
import * as jsZip from 'jszip';

const TWO_DAYS_MILLI = 172800000;

/**
 * Fetch asset of an item from the API
 *
 * @returns {Promise<Staff[]>} list of staff.
 */
export const getAssets = (ids: number[], source: AssetSourceEnum, format: AssetFormatEnum = AssetFormatEnum.Base64) => {
    return new Promise<Asset[]>(async (resolve, reject) => {
        try {
            let assetsToFetch: number[] = [];
            let assets: Asset[] = [];
            ids.forEach((id: number) => {
                let assetData = getLocalAsset(id, source);
                if (!assetData) assetsToFetch.push(id);
                else {
                    // Check if cache is outdated (> 2 days)
                    const lastUpdate = new Date(assetData.lastUpdate);
                    const now =  new Date();
                    if (now.getTime() - lastUpdate.getTime() >= TWO_DAYS_MILLI) {
                        assetsToFetch.push(id);
                    } else {
                        assets.push({ id, data: assetData.b64 });
                    }
                }
            });
            if (assetsToFetch.length) {
                const reqBody: AssetReqBody = { ids: assetsToFetch, source, format };
                const assetsResponse = await getAssetApi(reqBody);
                if (assetsToFetch.length === 1 && assetsResponse) {
                    assets.push({ id: assetsToFetch[0], data: assetsResponse });
                    if (source !== AssetSourceEnum.Track)
                        setLocalAsset(assetsToFetch[0], source, {
                            b64: assetsResponse,
                            lastUpdate: new Date(),
                        });
                } else if (assetsResponse) {
                    // for base64
                    if (format === AssetFormatEnum.Base64)
                        assets.push(
                            ...Object.entries(assetsResponse).map(([key, value]) => {
                                if (source !== AssetSourceEnum.Track)
                                    setLocalAsset(parseInt(key), source, {
                                        b64: value as string,
                                        lastUpdate: new Date(),
                                    });
                                return { id: parseInt(key), data: value as string };
                            })
                        );
                    // for binary
                    else if (format === AssetFormatEnum.Binary) {
                        const zip = await jsZip.loadAsync(assetsResponse);
                        await Promise.all(
                            assetsToFetch.map(async (id: number) => {
                                let asset = await zip.file(`${id}`)?.async('base64');
                                if (asset) {
                                    asset = 'data:/image;base64,' + asset;
                                    assets.push({ id, data: asset });
                                    if (source !== AssetSourceEnum.Track)
                                        setLocalAsset(id, source, {
                                            b64: asset,
                                            lastUpdate: new Date(),
                                        });
                                }
                            })
                        );
                    }
                }
            }
            resolve(assets);
        } catch (error) {
            reject(error);
        }
    });
};

export const getLocalAsset = (id: number, source: AssetSourceEnum) => {
    const assetData = localStorage.getItem(`${source}_${id}`);
    return assetData ? (JSON.parse(assetData) as LocalAssetData) : null;
};

export const setLocalAsset = (id: number, source: AssetSourceEnum, assetData: LocalAssetData) => {
    try {
        localStorage.setItem(`${source}_${id}`, JSON.stringify(assetData));
    } catch (e) {
        // no op
    }
};
