import { api, melovieApiPrefix } from '@/api';
import {
    GetPartLikes,
    IdPartLike,
    IdStaffLike,
    LikesPart,
    PartLike,
    StaffLike,
} from '@/helper/interface/like.interface';

/**
 * Get participant likes from API
 *
 * @param { GetPartLike } reqBody
 * @return {*}  {Promise<LikesPart[]>}
 */

export const getParticipantLikesApi = async (reqBody: GetPartLikes): Promise<LikesPart> => {
    const result = await api.post(`${melovieApiPrefix}/participant/like/list`, reqBody);
    return result.data;
};

/**
 * Add a like of a participant to the database
 *
 * @param { PartLike } reqBody
 * @return {*}  {Promise<IdPartLike>}
 */
export const addParticipantLikeApi = async (reqBody: PartLike): Promise<IdPartLike> => {
    const result = await api.post(`${melovieApiPrefix}/participant/like/add`, reqBody);
    return result.data;
};

/**
 * Delete a like of a participant
 *
 * @param {PartLike} reqBody
 * @return {*}  {Promise<IdPartLike>}
 */
export const deleteParticipantLikeApi = async (reqBody: PartLike): Promise<IdPartLike> => {
    const result = await api.post(`${melovieApiPrefix}/participant/like/del`, reqBody);
    return result.data;
};

/**
 * Add a dislike of a participant
 *
 * @param {PartLike} reqBody
 * @return {*}  {Promise<IdPartLike>}
 */
export const addParticipantDislikeApi = async (reqBody: PartLike): Promise<IdPartLike> => {
    const result = await api.post(`${melovieApiPrefix}/participant/like/unlike`, reqBody);
    return result.data;
};

// STAFF

/**
 * Add a like of a staff to the database
 *
 * @param { StaffLike } reqBody
 * @return {*}  {Promise<IdStaffLike>}
 */
export const addStaffLikeApi = async (reqBody: StaffLike): Promise<IdStaffLike> => {
    const result = await api.post(`${melovieApiPrefix}/staff/like/add`, reqBody);
    return result.data;
};

/**
 * Add a dislike of a staff
 *
 * @param {StaffLike} reqBody
 * @return {*}  {Promise<IdStaffLike>}
 */
export const addStaffDislikeApi = async (reqBody: StaffLike): Promise<IdStaffLike> => {
    const result = await api.post(`${melovieApiPrefix}/staff/like/unlike`, reqBody);
    return result.data;
};
