export default {
  "generic": {
    "melovie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melovie"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "wrongFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong file type"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
    "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficult"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "pleaseChooseAData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a data"])},
    "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolution"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected"])},
    "animationPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation place"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
    "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Later"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "survey": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Survey"]), _normalize(["Surveys"])])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["week"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year"])},
    "memory": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Memory"]), _normalize(["Memories"])])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGISTERED"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artist"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data found !"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidential"])}
  },
  "components": {
    "sidebar": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["library"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
      "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
      "discovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discovery"])},
      "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])}
    },
    "filter": {
      "placeOfActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place of activity"])}
    }
  },
  "views": {
    "auth": {
      "leaveTheSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave the session"])},
      "codePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code PIN"])},
      "enterYourCodeToStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your code to start"])},
      "whoAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who are you ?"])},
      "whereAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where are you ?"])},
      "wrongPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong pin!"])},
      "connectedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connected successfully!"])}
    },
    "dashboard": {
      "profile": {
        "myMusicalDna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My musical DNA"])},
        "myMusicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My musical styles"])},
        "myFavoritePeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My favorite periods"])},
        "myFavoriteArtists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent favorite artists"])},
        "myLastFavoriteSongs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent latest favorite songs"])},
        "launchASession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch a session"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
        "yourActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your activity"])},
        "residentsStimulated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimulated residents"])},
        "collectedMemories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memories collected"])},
        "stimulationHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours of stimulation"])},
        "sharedPlaylists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared Playlists"])},
        "activitiesOfTheEstablishment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity of the establishment"])},
        "activeResidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active residents"])},
        "wellnessRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wellness rate"])},
        "activeRelatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active relatives"])},
        "firstConnectionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome, this is your first connection to our tool. In order to optimize your experience, we suggest you fill in your profile, through a simple questionnaire."])},
        "firstConnectionConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to fill out your profile now ?"])},
        "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated successfully !"])},
        "firstConnectionQuestionnaireNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First connection questionnaire is not available !"])},
        "noFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["No favorite ", _interpolate(_named("type")), " !"])},
        "noFavoritePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No favorite period."])},
        "noFavoriteArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No favorite artist."])},
        "noFavoriteGenre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No favorite musical style."])},
        "noFavoriteSong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No favorite song."])},
        "noFavoritePlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No favorite playlist."])},
        "noRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No recommendation."])}
      },
      "questionnaire": {
        "firstConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First connection"])},
        "fillProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your profile, to optimize your use of the Melovie application."])},
        "filterYourPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter your periods"])},
        "summaryOfFavPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary of your favorite periods"])},
        "yourFavoriteMusicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your favorite musical styles"])},
        "yourFavoritePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your favorite period"])},
        "yourFavoriteArtists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your favorite artists"])},
        "yourFavoriteTracks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your favorite songs"])},
        "musicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musical Styles"])},
        "musicalPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musical periods"])},
        "yourFavoriteArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your favorite artists"])},
        "chooseYourFavArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your favorite artists"])},
        "findAnArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search artists"])},
        "findAnAlbum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search albums"])},
        "findATrack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search songs"])},
        "chooseYourFavAlbum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your favorite albums"])},
        "chooseYourFavTrack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your favorite songs"])},
        "noArtistsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No relevant artists found"])},
        "noAlbumFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No relevant albums found"])},
        "noTrackFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No relevant songs found"])}
      },
      "session": {
        "participantSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selection of a participant"])},
        "choseParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a participant"])},
        "selectedParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected participant"])},
        "launchTheSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch the session"])},
        "endOfSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of session"])},
        "thanksForSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for this session."])},
        "writeAReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to write a report?"])},
        "mustSelectOneParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must select a participant before you can launch the session."])}
      },
      "library": {
        "myLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My library"])},
        "playlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlists"])},
        "albums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albums"])},
        "artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artists"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorites"])},
        "musics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musics"])},
        "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shared"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
      }
    },
    "participantDashboard": {
      "report": {
        "meetingReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLOSE"])},
        "reportTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " - Session of ", _interpolate(_named("date")), " at ", _interpolate(_named("time"))])},
        "objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objective"])},
        "therapeuticWorkshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Therapeutic workshop"])},
        "evaluationOfTheSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation of the session"])},
        "attentionOfConcentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention and concentration"])},
        "understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understanding"])},
        "mobilization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilization"])},
        "valuation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valuation"])},
        "wellness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wellness"])},
        "pleasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleasure"])},
        "activityEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation of activities"])},
        "managementBehavioralDisorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of a behavioral disorder"])},
        "observation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation"])},
        "infoObjective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notify if it is a workshop that aims to work on specific functions, in connection with therapeutic objectives identified for each resident. In order to find this information within your management site and to be able to justify your PATHOS cuts."])},
        "ResidentsWithDisruptive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents with disruptive behavioral problems (such as wandering, agitation, aggression, euphoria, disinhibition, etc.) are mainly channeled."])},
        "AnxiousStates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anxious states are best soothed."])},
        "ResidentsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residents with either a non-disruptive behavioral disorder (such as apathy, adynamia, indifference to self and environment, etc.) or a depressive state are preferentially stimulated."])}
      },
      "discovery": {
        "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommendations"])},
        "discoverPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlists to discover"])},
        "filterToDiscoverNewMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter to discover new music"])},
        "periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periods"])},
        "musicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musical styles"])},
        "numberOfSongs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of songs to listen to"])},
        "songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["songs"])},
        "assessmentOfDiscovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment of your discovery"])},
        "fence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fence"])},
        "noMatchFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No match found ! Please choose different periods/musical styles."])},
        "musicalSessionDataSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musical session data saved !"])},
        "musicalSessionDataSaveAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need to save musical session data ?"])}
      }
    },
    "playlist": {
      "createPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to playlist"])},
      "newPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New playlist"])},
      "findPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a playlist"])},
      "choosePlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a playlist"])},
      "sidebarTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add this track to a playlist"])},
      "playlistName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlist name"])},
      "suggestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlist - Suggested by "])},
      "successfullyAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title added to your playlist"])},
      "noPlaylistAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No playlist available"])},
      "writePlaylistName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write a playlist name"])},
      "noMusicAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No music available"])},
      "launchIndividualMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Launch individual mode"])},
      "titleToAvoid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title to avoid"])},
      "hateAlertText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have just set the title below as one to be avoided."])},
      "hateAlertText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Title : ", _interpolate(_named("name")), " - ", _interpolate(_named("artist"))])},
      "hateAlertText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you also like to remove this track from your playlists?"])},
      "addMusics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add musics"])},
      "chooseMusics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose musics"])},
      "selectedMusics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected musics"])},
      "invalidAudioSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid audio source"])},
      "needToAddTracks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to add tracks in this playlist?"])}
    },
    "research": {
      "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by artist, song or genre"])},
      "favMusicStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite music styles"])},
      "chooseFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose filters"])}
    },
    "souvenir": {
      "recordAMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record a memory"])},
      "yourMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your memory"])},
      "typeOfMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de souvenir"])},
      "accessibleToFriendsAndFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessible to family and friends"])},
      "accessibleToFriendsAndFamilyAgreed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you choose the 'Accessible to friends and family' option, this memory can potentially be shared with your friends and family."])},
      "associateAMemoryWithTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associate a memory with this title"])},
      "noMemoryFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No memory available"])},
      "souvenirAddedSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir added successfully"])},
      "souvenirUpdatedSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir updated successfully"])}
    }
  }
}