
import { defineComponent, onBeforeMount, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AddTrack from '@/components/questionnaire_modal/questionnaire_input/QuestionnaireInputComponent.vue';
import MusicalList from '@/components/musical_list/MusicalListComponent.vue';
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import {
    currentPlaylistData,
    currentPlaylistTracks,
    isPlaylistShuffled,
    openSouvenirCreator,
    playlistAudio,
    resetPlaylistData,
} from '@/services/sharedService';
import { usePlaylist } from '@/composable/usePlaylist';
import { getCurrentUserName } from '@/helper/utils/genericUtils';

export default defineComponent({
    name: 'PlayListView',
    components: { MusicalList, Confirm, AddTrack },
    props: {
        playlistId: String,
    },
    setup(props: any) {
        const router = useRouter();
        const route = useRoute();

        // get functionalities by using playlist composable
        const {
            isPlaying,
            musicList,
            totalTimeMin,
            totalTimeHour,
            hateAlertData,
            selectedTracks,
            progressBarData,
            isEmptyPlaylist,
            currentHateMusic,
            trackAddModalData,
            isHateAlertOpened,
            isTrackAddModalOpened,
            onHate,
            onRemove,
            onComment,
            onFavorite,
            onRowClick,
            generateTime,
            onSingleView,
            shuffleMusics,
            onToggleAudio,
            removeMarking,
            handlePlayPause,
            initializePlayer,
            markPlayingTrack,
            loadPlaylistData,
            openTrackAddModal,
            generateTotalTime,
        } = usePlaylist(props);

        onBeforeMount(async () => {
            // handling a case, where initially there was no music in the playlist, then added music with add-track-modal.
            if (router.options.history.state.forward === '/playlist/music-player') isEmptyPlaylist.value = false;

            if (props.playlistId?.length) {
                const playlistId = parseInt(props.playlistId);
                // loading playlist data if not in cache
                if (!currentPlaylistTracks.value.length) await loadPlaylistData(playlistId);
                else {
                    generateTotalTime(); // calculate times for playlist
                    generateTime(); // calculate time for music
                }
                // initialize the player with condition
                initializePlayer(router.options.history.state.forward === '/playlist/music-player');

                // assigning functions to audio events
                playlistAudio.onended = () => {
                    onToggleAudio();
                };
                playlistAudio.onpause = () => (isPlaying.value = false);
                playlistAudio.onplay = () => (isPlaying.value = true);
                playlistAudio.ontimeupdate = () => generateTime();
                playlistAudio.onloadedmetadata = () => generateTime();
            } else router.back(); // go back if playlist id not available
        });

        // check if component opening from single music player view, then mark currently playing track
        if (router.options.history.state.forward === '/playlist/music-player')
            onMounted(() => {
                markPlayingTrack();
            });

        onUnmounted(() => {
            removeMarking(); // remove marking from playlist musics
            // if single music player view going to open, then don't reset playlist data
            if (route.name !== 'PlaylistMusicPlayer') resetPlaylistData(); // else reset
        });

        return {
            isPlaying,
            musicList,
            totalTimeMin,
            totalTimeHour,
            hateAlertData,
            selectedTracks,
            progressBarData,
            currentHateMusic,
            isHateAlertOpened,
            trackAddModalData,
            isPlaylistShuffled,
            currentPlaylistData,
            isTrackAddModalOpened,
            currentPlaylistTracks,
            onHate,
            onRemove,
            onComment,
            onRowClick,
            onFavorite,
            onSingleView,
            shuffleMusics,
            handlePlayPause,
            openTrackAddModal,
            getCurrentUserName,
            openSouvenirCreator,
        };
    },
});
