import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c909a32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "body-container" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = { class: "count-container" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "player-container" }
const _hoisted_7 = { class: "logo-container" }
const _hoisted_8 = { class: "player-body" }
const _hoisted_9 = { class: "header" }
const _hoisted_10 = { class: "text-container" }
const _hoisted_11 = { class: "title-container" }
const _hoisted_12 = { class: "artist-container" }
const _hoisted_13 = { class: "icon-container" }
const _hoisted_14 = { class: "track-main-container" }
const _hoisted_15 = { class: "track-container" }
const _hoisted_16 = { class: "duration-container" }
const _hoisted_17 = { class: "current-container" }
const _hoisted_18 = { class: "duration" }
const _hoisted_19 = { class: "action-container" }
const _hoisted_20 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageComponent = _resolveComponent("ImageComponent")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["music-player-main-container", { 'single-audio': $props.data?.length === 1 }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onClose()))
          }, _toDisplayString(_ctx.$t('generic.exit')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          ($props.data?.length > 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.currentAudioIndex + 1) + "/" + _toDisplayString($setup.audioData.length), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ImageComponent, {
            assId: $setup.currentAudio.alb_ass_id ?? -1,
            source: $setup.Album
          }, null, 8, ["assId", "source"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString($setup.currentAudio.trk_name || _ctx.$t('generic.title')), 1),
              _createElementVNode("div", _hoisted_12, _toDisplayString($setup.currentAudio.alb_name || _ctx.$t('generic.artist')), 1)
            ]),
            _createElementVNode("div", _hoisted_13, [
              ($setup.isAudioPaused)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    class: "play",
                    icon: "fa-solid fa-play",
                    onClick: $setup.handlePlayPause
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "icon",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.handlePlayPause && $setup.handlePlayPause(...args)))
                  }, [
                    _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-pause" })
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("span", null, _toDisplayString($setup.currentAudioDuration), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("span", null, _toDisplayString($setup.audioDuration), 1)
                ])
              ]),
              _createElementVNode("div", {
                ref: "track",
                class: "track",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.onTrackClick && $setup.onTrackClick(...args)))
              }, [
                _createElementVNode("div", {
                  class: "track-progress",
                  style: _normalizeStyle({ width: $setup.trackWidth })
                }, null, 4)
              ], 512)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleLikeDislike())),
          class: _normalizeClass(["like", { disabled: $setup.canToggle && $setup.recentSessionDislikes.includes($setup.currentAudio) }])
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-thumbs-up" })
        ], 2),
        _createElementVNode("div", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleLikeDislike(false))),
          class: _normalizeClass(["dislike", { disabled: $setup.canToggle && $setup.recentSessionLikes.includes($setup.currentAudio) }])
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-thumbs-down" })
        ], 2)
      ])
    ]),
    ($props.data?.length > 1)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.canToggle && $setup.onToggleAudio(1))),
          class: _normalizeClass([{ enable: $setup.canToggle }, "sidebar-container"])
        }, [
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_font_awesome_icon, {
              icon: `fa-solid ${$setup.isLastTrack() ? 'fa-check' : 'fa-angle-right'}`
            }, null, 8, ["icon"])
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}