//* all constants of this project stored here

export const firstConnectionEvalName = 'melovie_profil';
export const staffFavGenresViewCount: number = 4;
export const staffFavPeriodsViewCount: number = 4;
export const staffFavArtistViewCount: number = 3;
export const staffFavSongViewCount: number = 3;
export const partFavGenresViewCount: number = 4;
export const partFavPeriodsViewCount: number = 4;
export const partFavArtistViewCount: number = 6;
export const partFavSongViewCount: number = 6;
export const maxPagesMusicalListChooseComponent: number = 7;
export const itemsPerPageMusicalListChooseComponent: number = 20;
export const musicalListItemIdTextPrefix: string = 'musicItem-';
export const maxNumberOfSouvenirInProfile: number = 5;
