import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34e3b980"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "memory_main-container" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "sidebar" }
const _hoisted_4 = { class: "logo-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "title-tag" }
const _hoisted_8 = { class: "sub-title" }
const _hoisted_9 = {
  key: 0,
  class: "action-button"
}
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: $props.memory.logo
          }, null, 8, _hoisted_5)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", _hoisted_7, _toDisplayString($props.memory.title), 1),
        _createElementVNode("span", _hoisted_8, _toDisplayString($props.memory.track?.artist), 1)
      ]),
      ($props.onPlay !== undefined && $props.memory.track?.id)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($props.onPlay($props.memory.track.id)), ["stop"]))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-play" })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("span", null, _toDisplayString($props.memory.description), 1)
      ])
    ])
  ]))
}