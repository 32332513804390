
import ListComponent from './questionnaire_list/QuestionnaireListComponent.vue';
import PeriodComponent from './questionnaire_period/QuestionnairePeriodComponent.vue';
import GenreList from '@/components/genre_list/GenreList.vue';
import FinalComponent from './questionnaire_final/QuestionnaireFinalComponent.vue';
import InputComponent from './questionnaire_input/QuestionnaireInputComponent.vue';
import { QuestionnaireType } from '@/helper/enums/melovie.enum';
import { defineComponent, onMounted, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Eval, QuestionnaireData } from '@/helper/interface/eval.interface';
import { useQuestionnaire } from '@/composable/useQuestionnaire';
import { ConfirmType } from '@/helper/enums/ui.enum';
import {
    openAlertPopup,
    isAlertOpened,
    isLoading,
    allAlbumData,
    allArtistData,
    allMusicData,
} from '@/services/sharedService';
import { useI18n } from 'vue-i18n';
import { getAllMusicList } from '@/services/music/trackService';
import { getAllAlbumList } from '@/services/music/albumService';
import { getAllArtistList } from '@/services/music/artistService';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { useGenericProfile } from '@/composable/useGenericProfile';

/**
 * questionnaire component
 *
 * @param {function} onSubmit is the function when user submits
 * @param {function} onCancel is the function that triggers when user is pressing left bottom cancel button
 * @param {Boolean} isModal is to check if it is a type of modal or not
 * @param {Object} data contains all the information to be display in current page
 * @param {Boolean} openResult is parameter to if the result to be shown
 *
 */
export default defineComponent({
    name: 'DashboardModalComponent',
    components: { ListComponent, PeriodComponent, GenreList, InputComponent, FinalComponent },
    props: {
        data: { type: Object as PropType<QuestionnaireData>, required: true },
        onSubmit: Function as PropType<(questionnaireData: QuestionnaireData[]) => void>,
        onCancel: Function as PropType<() => void>,
        checkAnsweredQuestionnaire: Function as PropType<(evalId: number) => boolean>,
        onRowClick: Function as PropType<(evalId: number) => void>,
        isModal: { type: Boolean, default: false },
        openResult: { type: Boolean, default: false },
        openListView: { type: Function as PropType<(questionnaireType: QuestionnaireType) => any> },
    },
    setup(props: any) {
        const { t } = useI18n();
        const parentQuestionnaire = ref<QuestionnaireData>({} as QuestionnaireData);
        const questionnaireData = ref<QuestionnaireData[]>([]);
        const currentQuestionnaire = ref<QuestionnaireData>();
        const currentQesDataIndex = ref<number>(0);
        const currentQuestionnaireType = ref<QuestionnaireType>();
        const isVisitedFinal = ref<boolean>(false);
        const router = useRouter();

        const { selectedGenres, onSelectGenre, selectedPeriods, onSelectPeriod } = useQuestionnaire();
        const { onEdit, closePopup } = useGenericProfile();
        // Questionnaire list
        const questionnaireList = ref<Eval[]>([]);

        // Tracks
        const trackList = ref<MusicalListItem[]>([]);
        const selectedTracks = ref<MusicalListItem[]>([]);

        // Artist
        const artistList = ref<MusicalListItem[]>([]);
        const selectedArtists = ref<MusicalListItem[]>([]);

        // Album
        const albumList = ref<MusicalListItem[]>([]);
        const selectedAlbums = ref<MusicalListItem[]>([]);

        // methods
        const fillQuestionnaireAnswer = () => {
            questionnaireData.value = questionnaireData.value.map((questionnaire: QuestionnaireData) => {
                switch (questionnaire.children?.[0].eval_type) {
                    case QuestionnaireType.Period:
                        questionnaire.children[0].answer = selectedPeriods.value;
                        break;
                    case QuestionnaireType.Genre:
                        questionnaire.children[0].answer = selectedGenres.value;
                        break;
                    case QuestionnaireType.Artist:
                        questionnaire.children[0].answer = selectedArtists.value;
                        break;
                    case QuestionnaireType.Album:
                        questionnaire.children[0].answer = selectedAlbums.value;
                        break;
                    case QuestionnaireType.Track:
                        questionnaire.children[0].answer = selectedTracks.value;
                        break;
                }
                isVisitedFinal.value = true;
                return questionnaire;
            });
        };

        const onChange = (position: number) => {
            if (
                currentQesDataIndex.value + position <= questionnaireData.value.length ||
                currentQesDataIndex.value + position >= 0
            ) {
                currentQesDataIndex.value = currentQesDataIndex.value + position;
                if (currentQesDataIndex.value === questionnaireData.value.length) {
                    fillQuestionnaireAnswer();
                }
                currentQuestionnaire.value = questionnaireData.value[currentQesDataIndex.value];
                currentQuestionnaireType.value = currentQuestionnaire.value?.children?.[0].eval_type;
            } else {
                router.push('/profile');
            }
        };

        const onClickSubmit = () => {
            if (props.onSubmit) {
                fillQuestionnaireAnswer();
                props.onSubmit(questionnaireData.value);
            }
        };

        // navigate between questions
        const handleLeftButton = () => {
            if (currentQesDataIndex.value === 0 || isVisitedFinal.value) {
                if (props.isModal) {
                    // handle if the component open as modal view
                    if (questionnaireData.value.length === 1) {
                        if (props.onCancel) props.onCancel(); // close the modal
                    } else if (currentQesDataIndex.value === questionnaireData.value.length) {
                        if (props.onCancel) props.onCancel();
                        if (props.openListView) props.openListView(QuestionnaireType.List);
                    } else onChange(questionnaireData.value.length - currentQesDataIndex.value); // go to final page
                } else router.back(); // go back to previous page
            } else onChange(-1); // navigate to previous question
        };
        const handleRightButton = () => {
            if (
                (props.isModal && questionnaireData.value.length === 1) ||
                currentQesDataIndex.value === questionnaireData.value.length
            ) {
                onClickSubmit(); // submit answers
            } else onChange(isVisitedFinal.value ? questionnaireData.value.length - currentQesDataIndex.value : 1); // go to next question
        };

        onMounted(async () => {
            try {
                isLoading.value = true;
                if (!allMusicData.value.length) allMusicData.value = await getAllMusicList();
                if (!allAlbumData.value.length) allAlbumData.value = await getAllAlbumList();
                if (!allArtistData.value.length) allArtistData.value = await getAllArtistList();
            } catch (error) {
                isLoading.value = false;
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: `${error}`,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            } finally {
                isLoading.value = false;
            }
            if (props.data) {
                parentQuestionnaire.value = props.data;
                questionnaireData.value = props.data.children || [];
                currentQuestionnaire.value = props.data.children?.[0];
                currentQuestionnaireType.value = props.data.children?.[0].children?.[0].eval_type;
                (props.data.children as QuestionnaireData[])?.forEach((quest: QuestionnaireData) => {
                    switch (quest.children?.[0]?.eval_type) {
                        case QuestionnaireType.List:
                            questionnaireList.value = quest.children[0].answer || [];
                            break;
                        case QuestionnaireType.Period:
                            selectedPeriods.value = quest.children[0].answer || [];
                            break;
                        case QuestionnaireType.Genre:
                            selectedGenres.value = quest.children[0].answer || [];
                            break;
                        case QuestionnaireType.Artist:
                            selectedArtists.value = quest.children[0].answer || [];
                            const selectedArtistIds = selectedArtists.value.map(
                                (artist: MusicalListItem) => artist.id!
                            );
                            artistList.value = allArtistData.value.filter(
                                (artist: MusicalListItem) => artist.id && !selectedArtistIds.includes(artist.id)
                            );
                            break;
                        case QuestionnaireType.Album:
                            selectedAlbums.value = quest.children[0].answer || [];
                            const selectedAlbumIds = selectedAlbums.value.map((album: MusicalListItem) => album.id!);
                            albumList.value = allAlbumData.value.filter(
                                (album: MusicalListItem) => album.id && !selectedAlbumIds.includes(album.id)
                            );
                            break;
                        case QuestionnaireType.Track:
                            selectedTracks.value = quest.children[0].answer || [];
                            const selectedTrackIds = selectedTracks.value.map((track: MusicalListItem) => track.id!);
                            trackList.value = allMusicData.value.filter(
                                (track: MusicalListItem) => track.id && !selectedTrackIds.includes(track.id)
                            );
                            break;
                    }
                });
                if (props.openResult) {
                    onChange(questionnaireData.value.length - currentQesDataIndex.value);
                }
            }
        });
        return {
            questionnaireList,
            currentQuestionnaireType,
            QuestionnaireType,
            onChange,
            currentQesDataIndex,
            selectedGenres,
            onSelectGenre,
            parentQuestionnaire,
            currentQuestionnaire,
            questionnaireData,
            isVisitedFinal,
            selectedPeriods,
            onSelectPeriod,
            onClickSubmit,
            handleLeftButton,
            handleRightButton,
            trackList,
            artistList,
            albumList,
            selectedTracks,
            selectedArtists,
            selectedAlbums,
        };
    },
});
