import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-061e2efa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "launch-button_main-container" }
const _hoisted_2 = { class: "text-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.onClick ? $props.onClick() : null)),
      class: _normalizeClass(["logo-container", { 'cursor-pointer': $props.onClick !== undefined }])
    }, [
      _createVNode(_component_font_awesome_icon, {
        class: "fa-4x",
        icon: "fa-regular fa-circle-play"
      })
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString($props.text), 1)
    ])
  ]))
}