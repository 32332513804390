
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import ModalSidebar from '@/components/modal_sidebar/ModalSidebarComponent.vue';
import { onMounted, onUnmounted, PropType, ref, computed } from 'vue';
import MusicalList from '@/components/musical_list/MusicalListComponent.vue';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import { useI18n } from 'vue-i18n';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import {
    isAlertOpened,
    isLoading,
    isSouvenirCreatorOpened,
    openAlertPopup,
    participantMemories,
} from '@/services/sharedService';
import { setParticipantSouvenir } from '@/services/souvenirService';
import {
    IdSouvenirResult,
    Souvenir,
    SouvenirGenericType,
    SouvenirTrackType,
} from '@/helper/interface/souvenir.interface';

/**
 * Bubble list contain list of string as bubble view.
 *
 * @param {string[]} dataList list of string type data.
 */
export default {
    name: 'SouvenirCreatorComponent',
    components: {
        MusicalList,
        Confirm,
        ModalSidebar,
    },
    props: {
        item: { type: Object as PropType<MusicalListItem>, required: true },
        souvenirData: { type: Object as PropType<Souvenir> },
    },
    setup(props: any) {
        const { t, locale } = useI18n();
        const isMicOn = ref<boolean>(false);

        // Speech recognition
        const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
        const voiceSource = SpeechRecognition ? new SpeechRecognition() : null;

        const recognizedSpeech = ref<string>(
            props?.souvenirData?.description ? (props.souvenirData as Souvenir).description : ''
        );

        /**
         * Setting participant's souvenir popup
         *
         */
        const souvenirPopupData = ref<ConfirmProps>({
            confirmType: ConfirmType.Alert,
            title: t('views.souvenir.recordAMemory'),
            headerIcon: 'fa-message',
            onClose: () => {
                isSouvenirCreatorOpened.value = false;
            },
            left: {
                text: t('generic.cancel'),
                onClick: () => {
                    isSouvenirCreatorOpened.value = false;
                },
            },
            right: {
                text: t('generic.register'),
                onClick: () => {
                    recognizedSpeech.value !== '' ? handleSubmitSouvenirData() : undefined;
                },
            },
        });

        /**
         * Handling souvenir submit/update and saving data in cache
         *
         */
        const handleSubmitSouvenirData = () => {
            isLoading.value = true;
            setParticipantSouvenir(props.item, recognizedSpeech.value, props.souvenirData?.id)
                .then((res: IdSouvenirResult) => {
                    const isUpdate = props.souvenirData?.id ? true : false;
                    // also save in cache
                    if (res.pmem_id) {
                        if (isUpdate) {
                            // For update
                            const cacheMemory = participantMemories.value.find(
                                (memory: Souvenir) => memory.id === props.souvenirData.id
                            );
                            if (cacheMemory) {
                                cacheMemory.description = recognizedSpeech.value;
                            }
                        } else {
                            // For create
                            const memory: Souvenir = {
                                id: res.pmem_id!,
                                date: new Date(),
                                logo: props.item.logo,
                                description: recognizedSpeech.value,
                                title: props.item.title,
                            };
                            switch (props.item.type) {
                                case MusicalListItemType.Artist:
                                    memory.artist = {
                                        id: props.item.id!,
                                        name: props.item.title,
                                    } as SouvenirGenericType;
                                    break;
                                case MusicalListItemType.Album:
                                    memory.album = {
                                        id: props.item.id!,
                                        name: props.item.title,
                                    } as SouvenirGenericType;
                                    break;
                                case MusicalListItemType.Track:
                                    memory.track = {
                                        id: props.item.id!,
                                        name: props.item.title,
                                        artist: props.item.artist,
                                        description: props.item.description,
                                    } as SouvenirTrackType;
                                    break;
                            }
                            participantMemories.value.push(memory);
                        }
                    }
                    isSouvenirCreatorOpened.value = false;

                    // Displaying success message of souvenir created/updated
                    openAlertPopup({
                        confirmType: ConfirmType.Success,
                        message: t(
                            isUpdate
                                ? 'views.souvenir.souvenirUpdatedSuccessMsg'
                                : 'views.souvenir.souvenirAddedSuccessMsg'
                        ),
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    });
                })
                .catch((error) => {
                    isSouvenirCreatorOpened.value = false;
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error.message || error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    });
                })
                .finally(() => (isLoading.value = false));
        };

        const toggleMic = () => (isMicOn.value ? voiceSource?.stop() : voiceSource?.start());

        onMounted(() => {
            // Speech recognition
            if (voiceSource !== null) {
                voiceSource.lang = locale.value;
                voiceSource.continuous = true;
                voiceSource.interimResults = true;

                voiceSource.onstart = () => (isMicOn.value = true);
                voiceSource.onend = () => (isMicOn.value = false);

                // Function to handle speech recognition results
                voiceSource.onresult = (event: any) => {
                    const data = Array.from(event.results as SpeechRecognitionResultList)
                        .map((result: SpeechRecognitionResult) => result[0]);
                    // Ugly behavior for tab android ...
                    recognizedSpeech.value = data[data.length - 1].transcript;
                };
            }
        });

        const hasSpeechRecognition = computed(() => SpeechRecognition !== undefined);

        // Stop microphone before leaving the popup
        onUnmounted(() => {
            isMicOn.value ? voiceSource?.stop() : '';
        });
        return {
            souvenirPopupData,
            recognizedSpeech,
            toggleMic,
            isMicOn,
            hasSpeechRecognition,
        };
    },
};
