import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f36209e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "sidebar-container" }
const _hoisted_3 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Sidebar, {
        "menu-items": _ctx.menuItems,
        "user-name": 
                    _ctx.currentUser
                        ? ` ${_ctx.currentUser?.staf_firstname} ${_ctx.currentUser?.staf_lastname}`
                        : `${_ctx.$t('generic.userName')}`
                ,
        "profile-photo": require('@/assets/profiles/avatar.png')
      }, null, 8, ["menu-items", "user-name", "profile-photo"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }))
    ])
  ]))
}