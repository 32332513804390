import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04e5bd73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "non-modal-container" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "header-close-btn" }
const _hoisted_5 = { class: "header-title-icons" }
const _hoisted_6 = { class: "img-cont" }
const _hoisted_7 = { class: "header-text" }
const _hoisted_8 = { class: "header-text-count" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "text-container"
}
const _hoisted_11 = {
  key: 3,
  class: "body_questionnaire-container"
}
const _hoisted_12 = { class: "footer" }
const _hoisted_13 = { class: "footer-btn-left" }
const _hoisted_14 = {
  key: 0,
  class: "leftArrowSpan"
}
const _hoisted_15 = { class: "footer-btn-right" }
const _hoisted_16 = {
  key: 0,
  class: "rightArrowSpan"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ListComponent = _resolveComponent("ListComponent")!
  const _component_FinalComponent = _resolveComponent("FinalComponent")!
  const _component_PeriodComponent = _resolveComponent("PeriodComponent")!
  const _component_GenreList = _resolveComponent("GenreList")!
  const _component_InputComponent = _resolveComponent("InputComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "headerLeftButton", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-user" })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h4", null, _toDisplayString(_ctx.parentQuestionnaire.eval_data?.title || ''), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.parentQuestionnaire.children && _ctx.parentQuestionnaire.children.length > 1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.currentQesDataIndex + 1) + "/" + _toDisplayString(_ctx.parentQuestionnaire.children.length + 1), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["body", {
                    'no-padding':
                        _ctx.currentQesDataIndex === _ctx.questionnaireData.length ||
                        _ctx.currentQuestionnaireType === _ctx.QuestionnaireType.List,
                }])
      }, [
        (
                        _ctx.currentQuestionnaireType === _ctx.QuestionnaireType.Period ||
                        _ctx.currentQuestionnaireType === _ctx.QuestionnaireType.Genre
                    )
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", null, _toDisplayString(_ctx.currentQuestionnaire?.eval_data?.title || ''), 1)
            ]))
          : _createCommentVNode("", true),
        (_ctx.currentQuestionnaireType === _ctx.QuestionnaireType.List)
          ? (_openBlock(), _createBlock(_component_ListComponent, {
              key: 1,
              data: _ctx.questionnaireList,
              "on-row-click": _ctx.onRowClick,
              "check-answered": _ctx.checkAnsweredQuestionnaire
            }, null, 8, ["data", "on-row-click", "check-answered"]))
          : (_ctx.questionnaireData.length > 1 && _ctx.currentQesDataIndex === _ctx.questionnaireData.length)
            ? (_openBlock(), _createBlock(_component_FinalComponent, {
                key: 2,
                data: _ctx.questionnaireData,
                "on-edit": _ctx.onChange
              }, null, 8, ["data", "on-edit"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_ctx.currentQuestionnaireType === _ctx.QuestionnaireType.Period)
                  ? (_openBlock(), _createBlock(_component_PeriodComponent, {
                      key: 0,
                      "selected-data": _ctx.selectedPeriods,
                      "on-submit": _ctx.onSelectPeriod
                    }, null, 8, ["selected-data", "on-submit"]))
                  : _createCommentVNode("", true),
                (_ctx.currentQuestionnaireType === _ctx.QuestionnaireType.Genre)
                  ? (_openBlock(), _createBlock(_component_GenreList, {
                      key: 1,
                      "selected-data": _ctx.selectedGenres,
                      "on-select": _ctx.onSelectGenre
                    }, null, 8, ["selected-data", "on-select"]))
                  : _createCommentVNode("", true),
                (_ctx.currentQuestionnaireType === _ctx.QuestionnaireType.Artist)
                  ? (_openBlock(), _createBlock(_component_InputComponent, {
                      key: 2,
                      title: _ctx.currentQuestionnaire?.eval_data?.title,
                      "list-title": _ctx.$t('views.dashboard.questionnaire.chooseYourFavArtist'),
                      items: _ctx.artistList,
                      "selected-items": _ctx.selectedArtists,
                      "search-placeholder": _ctx.$t('views.dashboard.questionnaire.findAnArtist'),
                      "no-item-found": _ctx.$t('views.dashboard.questionnaire.noArtistsFound')
                    }, null, 8, ["title", "list-title", "items", "selected-items", "search-placeholder", "no-item-found"]))
                  : _createCommentVNode("", true),
                (_ctx.currentQuestionnaireType === _ctx.QuestionnaireType.Album)
                  ? (_openBlock(), _createBlock(_component_InputComponent, {
                      key: 3,
                      title: _ctx.currentQuestionnaire?.eval_data?.title,
                      "list-title": _ctx.$t('views.dashboard.questionnaire.chooseYourFavAlbum'),
                      items: _ctx.albumList,
                      "selected-items": _ctx.selectedAlbums,
                      "search-placeholder": _ctx.$t('views.dashboard.questionnaire.findAnAlbum'),
                      "no-item-found": _ctx.$t('views.dashboard.questionnaire.noAlbumFound')
                    }, null, 8, ["title", "list-title", "items", "selected-items", "search-placeholder", "no-item-found"]))
                  : _createCommentVNode("", true),
                (_ctx.currentQuestionnaireType === _ctx.QuestionnaireType.Track)
                  ? (_openBlock(), _createBlock(_component_InputComponent, {
                      key: 4,
                      title: _ctx.currentQuestionnaire?.eval_data?.title,
                      "list-title": _ctx.$t('views.dashboard.questionnaire.chooseYourFavTrack'),
                      items: _ctx.trackList,
                      "selected-items": _ctx.selectedTracks,
                      "search-placeholder": _ctx.$t('views.dashboard.questionnaire.findATrack'),
                      "no-item-found": _ctx.$t('views.dashboard.questionnaire.noTrackFound')
                    }, null, 8, ["title", "list-title", "items", "selected-items", "search-placeholder", "no-item-found"]))
                  : _createCommentVNode("", true)
              ]))
      ], 2),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleLeftButton())),
            class: _normalizeClass(
                            _ctx.currentQesDataIndex === 0 || _ctx.isVisitedFinal
                                ? 'footer-btn-left-first_ques'
                                : 'footer-btn-left'
                        )
          }, [
            (_ctx.currentQesDataIndex !== 0 && !_ctx.isVisitedFinal)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-arrow-left" })
                ]))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.currentQesDataIndex === 0 || _ctx.isVisitedFinal ? _ctx.$t('generic.back') : _ctx.$t('generic.previous')), 1)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleRightButton())),
            class: _normalizeClass(
                            (_ctx.isModal && _ctx.questionnaireData.length === 1) ||
                            _ctx.currentQesDataIndex === _ctx.questionnaireData.length ||
                            _ctx.isVisitedFinal
                                ? 'footer-btn-register'
                                : 'footer-btn-next'
                        )
          }, [
            _createTextVNode(_toDisplayString(_ctx.currentQesDataIndex === _ctx.questionnaireData.length
                                ? _ctx.$t('generic.register')
                                : (_ctx.isModal && _ctx.questionnaireData.length === 1) || _ctx.isVisitedFinal
                                ? _ctx.$t('generic.ok')
                                : _ctx.$t('generic.next')) + " ", 1),
            (
                                !(_ctx.isModal && _ctx.questionnaireData.length === 1) &&
                                _ctx.currentQesDataIndex !== _ctx.questionnaireData.length &&
                                !_ctx.isVisitedFinal
                            )
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                  _createVNode(_component_font_awesome_icon, { icon: "fa-arrow-right" })
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ])
  ]))
}