/**
 * types of confirm modal component
 *
 */
export enum ConfirmType {
    Alert = 'ALERT',
    Error = 'ERROR',
    Success = 'SUCCESS',
}

/**
 * types of musical list item
 *
 */
export enum MusicalListItemType {
    Track = 'TRACK',
    Album = 'ALBUM',
    Artist = 'ARTIST',
    Playlist = 'PLAYLIST',
    Favorite = 'FAVORITE',
    Shared = 'SHARED',
}

export enum ActionLabel {
    Start = 'START',
    Select = 'SELECT',
    Play = 'PLAY',
    Pause = 'PAUSE',
    Resume = 'RESUME',
    EndPlay = 'ENDPLAY',
    End = 'END',
}

export enum ActionOrigin {
    Discovery = 'DISCOVERY',
    Library = 'LIBRARY',
    Bilan = 'BILAN',
}

export enum PaginatorAlignmentType {
    Start = 'START',
    Center = 'CENTER',
    End = 'END',
}
