import { BubbleListData } from '@/helper/interface/ui/components/bubbleList';
import { FilterData } from '@/helper/interface/ui/components/filter';
import { Group } from '@/helper/interface/group.interface';
import { Service } from '@/helper/interface/service.interface';
import { Staff } from '@/helper/interface/staff.interface';
import { filterConnectionData } from '@/services/filterService';
import { ref } from 'vue';

export function useFilter() {
    const users = ref<Staff[]>([]);
    const userNames = ref<BubbleListData[]>([]);
    const locations = ref<Service[]>([]);
    const groups = ref<Group[]>([]);
    /**
     * Filter Data.
     * @param {FilterData} data filter form data from filter component.
     */
    const filterData = (data: FilterData) => {
        resetFilter();
        userNames.value = filterConnectionData(data, users.value);
    };
    /**
     * Reset or Initialize data.
     */
    const resetFilter = () =>
        (userNames.value = users.value.map((user: Staff) => {
            return { id: user.staf_id, value: `${user.staf_firstname} ${user.staf_lastname}` };
        }));
    return { users, userNames, locations, groups, filterData, resetFilter };
}
