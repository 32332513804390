import { api, melovieApiPrefix } from '@/api';
import { IdPart } from '@/helper/interface/participant.interface';
import {
    IdPartPlayList,
    IdPlayList,
    IdStaffPlayList,
    IdTracksPlayList,
    Playlist,
    PlayListParticipant,
    PlaylistReqBody,
    PlayListStaff,
    PlayListStaffId,
    PlayListTracks,
    SetPlayListPart,
} from '@/helper/interface/playlist.interface';
import { IdStaff } from '@/helper/interface/staff.interface';

/**
 * Get a playlist
 *
 * @param {IdPlayList} reqBody
 * @return {*}  {Promise<Playlist>}
 */
export const getPlaylistApi = async (reqBody: IdPlayList): Promise<Playlist> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/get`, reqBody);
    return result.data;
};

/**
 * Set a playlist
 *
 * @param {PlaylistReqBody} reqBody
 * @return {*}  {Promise<IdPlayList>}
 */
export const setPlaylistApi = async (reqBody: PlaylistReqBody): Promise<IdPlayList> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/set`, reqBody);
    return result.data;
};

/**
 * Delete a playlist
 *
 * @param {PlayListTracks} reqBody
 * @return {*}  {Promise<IdPlayList>}
 */
export const deletePlaylistApi = async (reqBody: IdPlayList): Promise<IdPlayList> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/del`, reqBody);
    return result.data;
};

/**
 * Add tracks to a playlist
 *
 * @param {PlayListTracks} reqBody
 * @return {*}  {Promise<IdTracksPlayList[]>}
 */
export const addPlaylistTracksApi = async (reqBody: PlayListTracks): Promise<IdTracksPlayList[]> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/track/add`, reqBody);
    return result.data;
};

/**
 * Delete playlist tracks
 *
 * @param {PlayListTracks} reqBody
 * @return {*}  {Promise<IdTracksPlayList[]>}
 */
export const delPlaylistTracksApi = async (reqBody: PlayListTracks): Promise<IdTracksPlayList[]> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/track/del`, reqBody);
    return result.data;
};

/**
 * Get participant's playlists
 *
 * @param {IdPart} reqBody
 * @return {*}  {Promise<PlayListParticipant[]>}
 */
export const getParticipantPlaylistsApi = async (reqBody: IdPart): Promise<PlayListParticipant[]> => {
    const result = await api.post(`${melovieApiPrefix}/participant/playlists`, reqBody);
    return result.data;
};

/**
 * Add participant playlist
 *
 * @param {PlayListParticipant} reqBody
 * @return {*}  {Promise<IdPartPlayList>}
 */
export const addParticipantPlaylistApi = async (reqBody: SetPlayListPart): Promise<IdPartPlayList> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/participant/add`, reqBody);
    return result.data;
};

/**
 * Delete participant playlist
 *
 * @param {PlayListParticipant} reqBody
 * @return {*}  {Promise<IdPartPlayList>}
 */
export const deleteParticipantPlaylistApi = async (reqBody: SetPlayListPart): Promise<IdPartPlayList> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/participant/del`, reqBody);
    return result.data;
};

/**
 * Add staff playlist
 *
 * @param {PlayListStaffId} reqBody
 * @return {*}  {Promise<IdStaffPlayList>}
 */
export const addStaffPlaylistApi = async (reqBody: PlayListStaffId): Promise<IdStaffPlayList> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/staff/add`, reqBody);
    return result.data;
};

/**
 * Delete staff playlist
 *
 * @param {PlayListStaffId} reqBody
 * @return {*}  {Promise<IdStaffPlayList>}
 */
export const deleteStaffPlaylistApi = async (reqBody: PlayListStaffId): Promise<IdStaffPlayList> => {
    const result = await api.post(`${melovieApiPrefix}/playlist/staff/del`, reqBody);
    return result.data;
};

/**
 * Get Staff's available playlists
 *
 * @param {IdStaff} reqBody
 * @return {*}  {Promise<PlayListStaff>}
 */
export const getStaffPlaylistsApi = async (reqBody: IdStaff): Promise<PlayListStaff[]> => {
    const result = await api.post(`${melovieApiPrefix}/staff/playlists`, reqBody);
    return result.data;
};
