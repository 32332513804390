import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", {
    style: _normalizeStyle({ backgroundColor: $props.bgColor, height: $props.height })
  }, [
    _renderSlot(_ctx.$slots, "leftButton", {}, undefined, true),
    _createElementVNode("div", {
      class: "title",
      style: _normalizeStyle({ justifyContent: $props.titleAlign })
    }, [
      _createElementVNode("span", {
        style: _normalizeStyle({ color: $props.titleColor, fontSize: $props.fontSize })
      }, _toDisplayString($props.title), 5)
    ], 4)
  ], 4))
}