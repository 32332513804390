export default {
  "generic": {
    "melovie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mélo-Vie"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "wrongFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier incorrect"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conserver"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
    "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facile"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen"])},
    "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficile"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "pleaseChooseAData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir une donnée"])},
    "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évolution"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
    "animationPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'animation"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "later": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus tard"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas"])},
    "survey": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Questionnaire"]), _normalize(["Questionnaires"])])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["semaine"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
    "memory": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Souvenir"]), _normalize(["Souvenirs"])])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["précédent"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suivant"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENREGISTRÉ"])},
    "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
    "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avant"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actuelles"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Années"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "sorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
    "artist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artiste"])},
    "alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerte"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée trouvée !"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Article"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauver"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partager"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
    "confidential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentiel"])}
  },
  "components": {
    "sidebar": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
      "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance"])},
      "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer"])},
      "discovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découverte"])},
      "relax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relax"])}
    },
    "filter": {
      "placeOfActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'activité"])}
    }
  },
  "views": {
    "auth": {
      "leaveTheSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la session"])},
      "codePin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code PIN"])},
      "enterYourCodeToStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre code pour débuter"])},
      "whoAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui êtes-vous ?"])},
      "whereAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Où êtes-vous ?"])},
      "wrongPin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code PIN incorrect !"])},
      "connectedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté avec succès!"])}
    },
    "dashboard": {
      "profile": {
        "myMusicalDna": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon ADN musical"])},
        "myMusicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes styles musicaux"])},
        "myFavoritePeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes périodes favorites"])},
        "myFavoriteArtists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artistes préférés récents"])},
        "myLastFavoriteSongs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières chansons préférées"])},
        "launchASession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer une séance"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
        "yourActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre activité"])},
        "residentsStimulated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résidents stimulés"])},
        "collectedMemories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenirs récoltés"])},
        "stimulationHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heures de stimulation"])},
        "sharedPlaylists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlists partagées"])},
        "activitiesOfTheEstablishment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'activité de l'établissement"])},
        "activeResidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de résidents actifs"])},
        "wellnessRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de taux de bien-ètre"])},
        "activeRelatives": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de proches actifs"])},
        "firstConnectionMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue, il s'agit de votre première connexion à notre outil. Afin d'optimiser votre expérience, nous vous proposons de remplir votre profil, grâce à un questionnaire simple."])},
        "firstConnectionConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous remplir votre profil maintenant ?"])},
        "profileUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil mis à jour avec succès !"])},
        "firstConnectionQuestionnaireNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le questionnaire de première connexion n'est pas disponible !"])},
        "noFavorite": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pas de ", _interpolate(_named("type")), " préférée !"])},
        "noFavoritePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune période favorite."])},
        "noFavoriteArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun artiste favori."])},
        "noFavoriteGenre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun style musical favori."])},
        "noFavoriteSong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune chanson favorite."])},
        "noFavoritePlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune playlist favorite."])},
        "noRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune recommandation."])}
      },
      "questionnaire": {
        "firstConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première connexion"])},
        "fillProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez votre profil pour optimiser votre usage de l'application Mélovie."])},
        "filterYourPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer vos périodes"])},
        "summaryOfFavPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif de vos périodes favorites"])},
        "yourFavoriteMusicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos styles musicaux favoris"])},
        "yourFavoritePeriod": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Votre période favorite"]), _normalize(["Vos périodes favorites"])])},
        "yourFavoriteArtists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos artistes favoris"])},
        "yourFavoriteTracks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos musique favoris"])},
        "musicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles musicaux"])},
        "musicalPeriods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périodes musicales"])},
        "yourFavoriteArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos artistes préférés"])},
        "chooseYourFavArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez vos artistes préférés"])},
        "findAnArtist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche d'un artiste"])},
        "findAnAlbum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche d'un albums"])},
        "findATrack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de musique"])},
        "chooseYourFavAlbum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez vos albums préférés"])},
        "chooseYourFavTrack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez vos morceaux favoris"])},
        "noArtistsFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun artiste n'a été trouvé"])},
        "noAlbumFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun albums pertinent n'a été trouvé"])},
        "noTrackFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune piste pertinente n'a été trouvée"])}
      },
      "session": {
        "participantSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection d'un participant"])},
        "choseParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un participant"])},
        "selectedParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participant sélectionné"])},
        "launchTheSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la séance"])},
        "endOfSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de séance"])},
        "thanksForSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour cette séance."])},
        "writeAReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous rédiger un compte-rendu ?"])},
        "mustSelectOneParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner un participant avant de pouvoir lancer la session."])}
      },
      "library": {
        "myLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma bibliothèque"])},
        "playlists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlists"])},
        "albums": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albums"])},
        "artists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artistes"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoris"])},
        "musics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musiques"])},
        "shared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partagés"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
      }
    },
    "participantDashboard": {
      "report": {
        "meetingReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte rendu"])},
        "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLOTURER"])},
        "reportTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " - Séance du ", _interpolate(_named("date")), " à ", _interpolate(_named("time"))])},
        "objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectif"])},
        "therapeuticWorkshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atelier thérapeutique"])},
        "evaluationOfTheSession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appréciation de la séance"])},
        "attentionOfConcentration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention et concentration"])},
        "understanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compréhension"])},
        "mobilization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilisation"])},
        "valuation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisation"])},
        "wellness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien-être"])},
        "pleasure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaisir"])},
        "activityEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation des activités"])},
        "managementBehavioralDisorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion d'un trouble du comportement"])},
        "observation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observation"])},
        "infoObjective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifier si c'est un atelier qui a pour but de travailler des fonctions spécifiques, en lien avec des objectifs thérapeutiques identifiés pour chaque résident. Afin de retrouver cette information au sein de votre site de gestion et de pouvoir justifier vos coupes PATHOS."])},
        "ResidentsWithDisruptive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On canalisera principalement des résidents présentant des troubles du comportement perturbateurs (de type déambulation, agitation, agressivité, euphorie, désinhibition, etc.)"])},
        "AnxiousStates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On apaisera de préférence des états anxieux."])},
        "ResidentsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On stimule préférentiellement des résidents présentant soit un trouble du comportement non perturbateur (de type apathie, adynamie, indifférence à soi-même et à l'environnement, etc.) ou un état dépressif."])}
      },
      "discovery": {
        "recommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandations"])},
        "discoverPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlists à découvrir"])},
        "filterToDiscoverNewMusic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer pour découvrir de nouvelles musiques"])},
        "periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périodes"])},
        "musicalStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles musicaux"])},
        "numberOfSongs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de musiques à écouter"])},
        "songs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chansons"])},
        "assessmentOfDiscovery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan de votre découverte"])},
        "fence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôturer"])},
        "noMatchFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune correspondance trouvée ! Veuillez choisir des périodes/styles musicaux différents."])},
        "musicalSessionDataSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données de la session musicale sauvegardées !"])},
        "musicalSessionDataSaveAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous besoin de sauvegarder les données de la session musicale ?"])}
      }
    },
    "playlist": {
      "createPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter à une playlist"])},
      "newPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle playlist"])},
      "findPlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver une playlist"])},
      "choosePlaylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez une playlist"])},
      "sidebarTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter ce titre à une playlist"])},
      "playlistName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la playlist"])},
      "suggestedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playlist - Proposée par "])},
      "successfullyAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre ajouté à votre playlist"])},
      "noPlaylistAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de playlist disponible"])},
      "writePlaylistName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Écrire un nom de playlist"])},
      "noMusicAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de musique disponible"])},
      "launchIndividualMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer le mode individuel"])},
      "titleToAvoid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre à éviter"])},
      "hateAlertText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous venez de placer le titre ci-dessous comme à éviter."])},
      "hateAlertText2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Titre : ", _interpolate(_named("name")), " - ", _interpolate(_named("artist"))])},
      "hateAlertText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous également retirer ce titre de vos playlists ?"])},
      "addMusics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des musiques"])},
      "chooseMusics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir la musique"])},
      "selectedMusics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musiques sélectionnées"])},
      "invalidAudioSource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source audio non valide"])},
      "needToAddTracks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez ajouter des titres à cette liste de lecture ?"])}
    },
    "research": {
      "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par artiste, chanson ou genre"])},
      "favMusicStyles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Styles musicaux préférés"])},
      "chooseFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir les filtres"])}
    },
    "souvenir": {
      "recordAMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer un souvenir"])},
      "yourMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre souvenir"])},
      "typeOfMemory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de souvenir"])},
      "accessibleToFriendsAndFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessible aux proches"])},
      "accessibleToFriendsAndFamilyAgreed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous choisissez l'option 'Accessible aux proches', ce souvenir pourra potentiellement être partagé avec vos proches."])},
      "associateAMemoryWithTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Associer un souvenir à ce titre"])},
      "noMemoryFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de souvenir disponible"])},
      "souvenirAddedSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir ajouté avec succès"])},
      "souvenirUpdatedSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souvenir mis à jour avec succès "])}
    }
  }
}