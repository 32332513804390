

import { setAuthToken, clear } from '@/storage'
import { defineComponent, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { getAllStaffs } from '@/services/staffService';

export default defineComponent({
  name: 'Login',
  props: {
    uid: {
      type: String
    }
  },
  setup(props: any) {
    const router = useRouter();
    onBeforeMount(async () => {
      if (props?.uid) {
        setAuthToken(props.uid)
         try {
          await getAllStaffs();
          router.push({ path: '/auth', replace: true})
        } catch (error) {

          router.push( { path: '/401', replace: true})
        }
        
      }
      else {
        clear()
        router.push({ path: '/401', replace: true})
      }
    })
  }
}
  
)
