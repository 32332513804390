import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05e29b27"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "tab-container" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "count-container" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "count-container" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "count-container" }
const _hoisted_10 = { class: "icon-container" }
const _hoisted_11 = {
  key: 0,
  class: "alphabet-container"
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  key: 0,
  class: "location-container"
}
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  class: "group-container"
}
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.selectedTab = $setup.nameTab)),
        class: _normalizeClass(["tab", { 'selected-tab': $setup.selectedTab === $setup.nameTab, 'already-selected': $setup.filterData.alphabets.length }])
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.name')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString($setup.filterData.alphabets.length), 1)
        ])
      ], 2),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.selectedTab = $setup.locationTab)),
        class: _normalizeClass(["tab", {
                    'selected-tab': $setup.selectedTab === $setup.locationTab,
                    'already-selected': $setup.filterData.locations.length,
                }])
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('components.filter.placeOfActivity')), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString($setup.filterData.locations.length), 1)
        ])
      ], 2),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.selectedTab = $setup.groupTab)),
        class: _normalizeClass(["tab", { 'selected-tab': $setup.selectedTab === $setup.groupTab, 'already-selected': $setup.filterData.groups.length }])
      }, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.group')), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", null, _toDisplayString($setup.filterData.groups.length), 1)
        ])
      ], 2),
      _createElementVNode("div", {
        onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.filterInitializer())),
        class: "tab"
      }, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-rotate-right" })
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.reset')), 1)
      ])
    ]),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        ($setup.selectedTab === $setup.nameTab)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.alphabets, (alphabet) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: alphabet,
                  onClick: ($event: any) => ($setup.handleSelectAlphabetTab(alphabet)),
                  class: _normalizeClass(["alphabet", { 'selected-alphabets': $setup.filterData.alphabets.includes(alphabet) }])
                }, [
                  _createElementVNode("span", null, _toDisplayString(alphabet), 1)
                ], 10, _hoisted_12))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        ($setup.selectedTab === $setup.locationTab)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.locations, (location, locationIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: locationIndex,
                  class: _normalizeClass(["location", { 'selected-items': $setup.filterData.locations.includes(location.serv_id) }]),
                  onClick: ($event: any) => ($setup.handleSelectTab('locations', location.serv_id))
                }, [
                  _createElementVNode("span", null, _toDisplayString(location.serv_name), 1)
                ], 10, _hoisted_14))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        ($setup.selectedTab === $setup.groupTab)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.groups, (group, groupIndex) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: groupIndex,
                  class: _normalizeClass(["group", { 'selected-items': $setup.filterData.groups.includes(group.grp_id) }]),
                  onClick: ($event: any) => ($setup.handleSelectTab('groups', group.grp_id))
                }, [
                  _createElementVNode("span", null, _toDisplayString(group.grp_name), 1)
                ], 10, _hoisted_16))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}