
import Header from '@/components/header/HeaderComponent.vue';
import Filter from '@/components/filter/FilterComponent.vue';
import BubbleList from '@/components/bubble_list/BubbleListComponent.vue';
import LaunchButton from '@/components/launch_button/LaunchButtonComponent.vue';
import { defineComponent, onMounted, ref } from 'vue';
import { BubbleListData } from '@/helper/interface/ui/components/bubbleList';
import { getSessionPageData, initializeSessionData } from '@/services/sessionService';
import {
    currentParticipant,
    isAlertOpened,
    isLoading,
    openAlertPopup,
    resetMusicalSessionData,
    resetParticipantData,
} from '@/services/sharedService';
import { SessionPageData } from '@/helper/interface/ui/ui.interface';
import { Participant } from '@/helper/interface/participant.interface';
import { filterSessionData } from '@/services/filterService';
import { Service } from '@/helper/interface/service.interface';
import { Group } from '@/helper/interface/group.interface';
import { FilterData } from '@/helper/interface/ui/components/filter';
import { useRouter } from 'vue-router';
import { ConfirmType } from '@/helper/enums/ui.enum';
import { useI18n } from 'vue-i18n';
/**
 * Session page to start a session by selecting a participant
 */
export default defineComponent({
    name: 'Session',
    components: {
        Filter,
        Header,
        BubbleList,
        LaunchButton,
    },
    setup() {
        const router = useRouter();
        const { t } = useI18n();
        const users = ref<Participant[]>([]);
        const userNames = ref<BubbleListData[]>([]);
        const locations = ref<Service[]>([]);
        const groups = ref<Group[]>([]);
        const selectedUser = ref<Participant>();
        /**
         * Filter Data.
         * @param {FilterData} data filter form data from filter component.
         */
        const filterData = (data: FilterData) => {
            resetFilter();
            userNames.value = filterSessionData(data, users.value);
        };
        /**
         * Reset or Initialize data.
         */
        const resetFilter = () =>
            (userNames.value = users.value.map((user: Participant) => {
                return { id: user.par_id, value: `${user.par_firstname} ${user.par_lastname}` };
            }));
        /**
         * Handle select user
         *
         * @param {BubbleListData} userName selected user name from interface.
         */
        const selectUser = (userName: BubbleListData) =>
            (selectedUser.value = users.value.find((user: Participant) => user.par_id === userName.id));

        const onStartSession = () => {
            if (selectedUser.value) {
                currentParticipant.value = selectedUser.value;
                initializeSessionData();
                router.push('/participant');
            } else {
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    headerIcon: 'fa-triangle-exclamation',
                    message: t('views.dashboard.session.mustSelectOneParticipant'),
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            }
        };
        // initialization
        onMounted(() => {
            // fetching page data
            isLoading.value = true;
            resetParticipantData();
            resetMusicalSessionData();

            getSessionPageData()
                .then((data: SessionPageData) => {
                    users.value = data.participants;
                    userNames.value = users.value.map((user: Participant) => {
                        return { id: user.par_id, value: `${user.par_firstname} ${user.par_lastname}` };
                    });
                    groups.value = data.groups;
                    locations.value = data.services;
                })
                .catch((error) =>
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    })
                )
                .finally(() => (isLoading.value = false));
        });
        return {
            users,
            userNames,
            locations,
            groups,
            filterData,
            resetFilter,
            selectUser,
            selectedUser,
            onStartSession,
        };
    },
});
