/**
 * Asset sources
 *
 * @export
 * @enum {number}
 */
export enum AssetSourceEnum {
    Artist = 'art',
    Album = 'alb',
    Track = 'trk',
    Reference = 'ref',
}
/**
 * Asset format
 *
 * @export
 * @enum {number}
 */
export enum AssetFormatEnum {
    Binary = 'bin',
    Base64 = 'b64',
}

export enum AssetDetailedSizeEnum {
    Small = 'SMALL',
    Medium = 'MEDIUM',
    Large = 'LARGE',
}
