
import { defineComponent } from 'vue';
import Loader from 'vue-loading-overlay';
import PlaylistCreator from '@/components/playlist_creator/PlaylistCreatorComponent.vue';
import SouvenirCreatorComponent from '@/components/souvenir_creator/SouvenirCreatorComponent.vue';
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import {
    isAlertOpened,
    alertData,
    isLoading,
    isPlaylistCreatorOpened,
    tracksToAddInPlaylist,
    isSouvenirCreatorOpened,
    itemToAddSouvenir,
    souvenirToEdit,
} from './services/sharedService';
export default defineComponent({
    name: 'App',
    components: {
        Loader,
        Confirm,
        PlaylistCreator,
        SouvenirCreatorComponent,
    },
    setup() {
        return {
            isLoading,
            isAlertOpened,
            alertData,
            isPlaylistCreatorOpened,
            tracksToAddInPlaylist,
            itemToAddSouvenir,
            isSouvenirCreatorOpened,
            souvenirToEdit,
        };
    },
});
