import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/musics/playlist.png'


const _withScopeId = n => (_pushScopeId("data-v-3a32ee70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert-text-container" }
const _hoisted_2 = { class: "playlist-main-container" }
const _hoisted_3 = { class: "head-section" }
const _hoisted_4 = { class: "back-container" }
const _hoisted_5 = { class: "logo-container" }
const _hoisted_6 = { class: "image-container" }
const _hoisted_7 = ["alt"]
const _hoisted_8 = { class: "info-container" }
const _hoisted_9 = { class: "title-container" }
const _hoisted_10 = { class: "sub-title-container" }
const _hoisted_11 = { class: "person-name" }
const _hoisted_12 = { class: "time-container" }
const _hoisted_13 = { class: "icon-container" }
const _hoisted_14 = { class: "text-container" }
const _hoisted_15 = { class: "action-container" }
const _hoisted_16 = { class: "icon-container" }
const _hoisted_17 = { class: "button-container" }
const _hoisted_18 = { class: "icon-container" }
const _hoisted_19 = { class: "icon-container" }
const _hoisted_20 = { class: "list-section" }
const _hoisted_21 = {
  key: 1,
  class: "no-track-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddTrack = _resolveComponent("AddTrack")!
  const _component_Confirm = _resolveComponent("Confirm")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_MusicalList = _resolveComponent("MusicalList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isTrackAddModalOpened)
      ? (_openBlock(), _createBlock(_component_Confirm, {
          key: 0,
          class: "track-add-modal",
          data: _ctx.trackAddModalData,
          maxWidth: "90vw",
          maxHeight: "95vh"
        }, {
          alertBody: _withCtx(() => [
            _createVNode(_component_AddTrack, {
              items: _ctx.musicList,
              "selected-items": _ctx.selectedTracks,
              title: _ctx.$t('views.playlist.selectedMusics'),
              "no-item-found": _ctx.$t('views.playlist.noMusicAvailable'),
              "list-title": _ctx.$t('views.playlist.chooseMusics'),
              "search-placeholder": _ctx.$t('views.dashboard.questionnaire.findATrack')
            }, null, 8, ["items", "selected-items", "title", "no-item-found", "list-title", "search-placeholder"])
          ]),
          _: 1
        }, 8, ["data"]))
      : _createCommentVNode("", true),
    (_ctx.isHateAlertOpened)
      ? (_openBlock(), _createBlock(_component_Confirm, {
          key: 1,
          class: "hate-alert-modal",
          data: _ctx.hateAlertData,
          "max-height": "50vh",
          "max-width": "65vw"
        }, {
          alertBody: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.hateAlertText1')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.hateAlertText2', {
                            name: _ctx.currentHateMusic?.title,
                            artist: _ctx.currentHateMusic?.artist || '',
                        })), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.hateAlertText3')), 1)
            ])
          ]),
          _: 1
        }, 8, ["data"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-arrow-left" })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: _ctx.currentPlaylistData?.play_name
            }, null, 8, _hoisted_7)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.currentPlaylistData?.play_name), 1),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.suggestedBy')), 1),
            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getCurrentUserName()), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-regular fa-clock" })
            ]),
            _createElementVNode("div", _hoisted_14, _toDisplayString(`${_ctx.totalTimeHour > 0 ? _ctx.totalTimeHour + 'h' : ''} ${_ctx.totalTimeMin} min`), 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("button", {
              class: _normalizeClass({ 'play-icon': !_ctx.isPlaying }),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handlePlayPause()))
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: !_ctx.isPlaying ? 'fa-play' : 'fa-pause'
              }, null, 8, ["icon"])
            ], 2),
            _createElementVNode("button", {
              class: _normalizeClass(["shuffle-icon", { active: _ctx.isPlaylistShuffled }]),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.shuffleMusics && _ctx.shuffleMusics(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-shuffle" })
            ], 2),
            (_ctx.$route.name === 'PlaylistParticipant')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn-with-text",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSingleView && _ctx.onSingleView(...args)))
                }, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_font_awesome_icon, { icon: "fa-music" })
                  ]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.launchIndividualMode')), 1)
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("button", null, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-share-nodes" })
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.share')), 1)
          ]),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openTrackAddModal && _ctx.openTrackAddModal(...args)))
          }, [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-add" })
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.add')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        (_ctx.currentPlaylistTracks.length)
          ? (_openBlock(), _createBlock(_component_MusicalList, {
              key: 0,
              "list-items": _ctx.currentPlaylistTracks,
              height: "auto",
              rowColor: "#fff",
              "on-row-click": _ctx.onRowClick,
              "on-favorite": _ctx.onFavorite,
              "on-remove": _ctx.onRemove,
              "on-hate": _ctx.$route.name === 'PlaylistParticipant' ? _ctx.onHate : undefined,
              "on-comment": _ctx.$route.name === 'PlaylistParticipant' ? _ctx.onComment : undefined
            }, null, 8, ["list-items", "on-row-click", "on-favorite", "on-remove", "on-hate", "on-comment"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_21, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.noMusicAvailable')), 1)
            ]))
      ])
    ])
  ], 64))
}