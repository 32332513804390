import { api, melovieApiPrefix } from '@/api';
import { IdPart } from '@/helper/interface/participant.interface';
import { IdSouvenirResult, SetSouvenir, Souvenir } from '@/helper/interface/souvenir.interface';

/**
 * Get participant's souvenir list
 *
 * @param {SetSouvenir} reqBody souvenir set req body
 * @return {*}  {Promise<Souvenir[]>} list of souvenir
 */
export const getParticipantSouvenirListApi = async (reqBody: IdPart): Promise<Souvenir[]> => {
    const result = await api.post(`${melovieApiPrefix}/participant/memory/list`, reqBody);
    return result.data;
};

/**
 * Add souvenir for a participant
 *
 * @param {SetSouvenir} reqBody souvenir set req body
 * @return {*}  {Promise<IdSouvenirResult>} an id of response
 */
export const addParticipantSouvenirApi = async (reqBody: SetSouvenir): Promise<IdSouvenirResult> => {
    const result = await api.post(`${melovieApiPrefix}/participant/memory/set`, reqBody);
    return result.data;
};
