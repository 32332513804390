import { api, musicApiPrefix } from '@/api';
import { Artist, ArtistDetailed, GetArtist, GetManyArtists } from '@/helper/interface/music/artist.interface';

/**
 * Get artist
 *
 * @return {*}  {Promise<ArtistDetailed[]>}
 */
export const getArtistApi = async (reqBody: GetArtist): Promise<ArtistDetailed[]> => {
    const result = await api.post(`${musicApiPrefix}/artist/get`, reqBody);
    return result.data;
};

export const getMultipleArtistsApi = async (reqBody: GetManyArtists): Promise<ArtistDetailed[]> => {
    const result = await api.post(`${musicApiPrefix}/artist/gets`, reqBody);
    return result.data;
};

/**
 * Get all artists
 *
 * @return {*}  {Promise<Artist[]>}
 */
export const getAllArtistsApi = async (): Promise<Artist[]> => {
    const result = await api.post(`${musicApiPrefix}/artist/list`, {});
    return result.data;
};
