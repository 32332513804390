
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import ModalSidebar from '@/components/modal_sidebar/ModalSidebarComponent.vue';
import MusicalList from '@/components/musical_list/MusicalListComponent.vue';
import { PropType, onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import {
    currentParticipant,
    currentStaff,
    currentUserType,
    isAlertOpened,
    isLoading,
    isPlaylistCreatorOpened,
    openAlertPopup,
    participantPlaylists,
    staffPlaylists,
} from '@/services/sharedService';
import { UserType } from '@/helper/enums/melovie.enum';
import { getStaffPlaylists, addStaffPlaylist } from '@/services/staffService';
import { PlayListStaff, IdTracksPlayList } from '@/helper/interface/playlist.interface';
import { debounce, getCurrentUserName, getIconUrl, isContainSubString } from '@/helper/utils/genericUtils';
import { getAssets } from '@/services/assetsService';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { getParticipantPlaylists, addParticipantPlaylist } from '@/services/participantService';
import { addPlaylistTracks } from '@/services/music/trackService';
import { musicalListItemIdTextPrefix } from '@/helper/utils/constants';

/**
 * Playlist creator component
 *
 * @param {MusicalListItem[]} tracks list of tracks to added in playlist.
 */
export default {
    name: 'PlaylistCreatorComponent',
    components: { Confirm, ModalSidebar, MusicalList },
    props: {
        tracks: { type: Array as PropType<MusicalListItem[]>, required: true },
    },
    setup(props: any) {
        const { t } = useI18n();
        const isNewPopup = ref<boolean>(false);
        const isCompleted = ref<boolean>(false);
        const userPlaylist = ref<PlayListStaff[]>([]);
        const playLists = ref<MusicalListItem[]>([]);
        const filteredPlayLists = ref<MusicalListItem[]>([]);
        const newPlaylistName = ref<string>();
        const selectedPlaylist = ref<MusicalListItem>();

        /**
         * create a playlist and assigned to the user, also tracks can be added in the playlist
         *
         * @param { number[] } trackIds track ids to add in the playlist
         */
        const createPlaylist = async (trackIds?: number[]) => {
            try {
                isLoading.value = true;
                if (currentUserType.value == UserType.Staff) {
                    staffPlaylists.value = await addStaffPlaylist(
                        currentStaff.value?.staf_id!,
                        newPlaylistName.value!,
                        trackIds
                    );
                    userPlaylist.value = staffPlaylists.value;
                } else if (currentUserType.value == UserType.Participant) {
                    participantPlaylists.value = await addParticipantPlaylist(
                        currentParticipant.value?.par_id!,
                        newPlaylistName.value!,
                        trackIds
                    );
                    userPlaylist.value = participantPlaylists.value;
                }
                if (trackIds?.length) isCompleted.value = true; // if tracks added, it means completed
                else {
                    // add new playlist in cache
                    playLists.value = await Promise.all(
                        userPlaylist.value.map(async (playlist: PlayListStaff) => {
                            const result: MusicalListItem = {
                                id: playlist.play_id,
                                title: playlist.play_name || '',
                                asset: playlist.play_ass_id ?? -1,
                                type: MusicalListItemType.Playlist,
                            };
                            return result;
                        })
                    );
                    playLists.value = playLists.value.reverse(); // reverse to move new playlist to top
                    onSearch(''); // reinitialize the search
                    const el = document.getElementById(musicalListItemIdTextPrefix + playLists.value[1]?.id);
                    if (el) el.scrollIntoView({ behavior: 'smooth' }); // scroll to top
                }
                newPlaylistName.value = undefined;
                isNewPopup.value = false;
            } catch (error) {
                isLoading.value = false;
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: `${error}`,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            } finally {
                isLoading.value = false;
            }
        };

        /**
         * handle on create click
         *
         */
        const onCreate = () => {
            if (newPlaylistName.value?.length) {
                openAlertPopup({
                    confirmType: ConfirmType.Alert,
                    message: t('views.playlist.needToAddTracks'),
                    left: {
                        text: t('generic.no'),
                        onClick: () => {
                            createPlaylist(); // create playlist
                            isAlertOpened.value = false;
                        },
                    },
                    right: {
                        text: t('generic.yes'),
                        onClick: () => {
                            const trackIds: number[] = (props.tracks as MusicalListItem[]).map(
                                (item: MusicalListItem) => item.id!
                            );
                            createPlaylist(trackIds); // create playlist and add tracks
                            isAlertOpened.value = false;
                        },
                    },
                });
            } else
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: t('views.playlist.writePlaylistName'),
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
        };

        // playlist creator modal data
        const playlistCreatorModalData = ref<ConfirmProps>({
            confirmType: ConfirmType.Alert,
            title: t('views.playlist.createPlaylist'),
            headerIcon: 'fa-regular fa-square-plus',
            onClose: () => (isPlaylistCreatorOpened.value = false),
        });

        // new playlist modal data
        const newPlaylistModalData = ref<ConfirmProps>({
            confirmType: ConfirmType.Alert,
            title: t('views.playlist.newPlaylist'),
            left: {
                text: t('generic.cancel'),
                onClick: () => {
                    isNewPopup.value = false;
                    newPlaylistName.value = undefined; // reset playlist name
                },
            },
            right: {
                text: t('generic.validate'),
                onClick: onCreate, // create playlist
            },
        });

        /**
         * open new playlist modal
         *
         */
        const onNewPlaylist = () => (isNewPopup.value = true);

        /**
         * choose and add tracks to playlist
         *
         * @param { MusicalListItem } playlist chose playlist
         */
        const onChoosePlaylist = (playlist: MusicalListItem) => {
            isLoading.value = true;
            selectedPlaylist.value = playlist;
            const trackIds: number[] = (props.tracks as MusicalListItem[]).map((item: MusicalListItem) => item.id!);
            addPlaylistTracks(playlist.id!, trackIds)
                .then((data: IdTracksPlayList[]) => {
                    isCompleted.value = true;
                })
                .catch((error) =>
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    })
                )
                .finally(() => (isLoading.value = false));
        };

        /**
         * handle search playlist
         *
         * @param { HTMLInputEvent } event input event
         */
        const handleSearch = (event: any) => {
            const search: string = event.target.value;
            onSearch(search);
        };

        /**
         * search playlist in the musical list
         *
         * @param { string } search search text
         */
        const onSearch = debounce((search: string) => {
            filteredPlayLists.value = playLists.value.filter((filData: MusicalListItem) =>
                isContainSubString(search, filData.title)
            );
        });

        /**
         * load playlists from cache if available, otherwise form api
         *
         */
        const loadPlaylists = async () => {
            try {
                isLoading.value = true;
                if (currentUserType.value == UserType.Staff) {
                    if (!staffPlaylists.value.length)
                        staffPlaylists.value = await getStaffPlaylists(currentStaff.value?.staf_id!);
                    userPlaylist.value = staffPlaylists.value;
                } else if (currentUserType.value == UserType.Participant) {
                    if (!participantPlaylists.value.length)
                        participantPlaylists.value = await getParticipantPlaylists(currentParticipant.value?.par_id!);
                    userPlaylist.value = participantPlaylists.value;
                }

                // convert playlist to musical list item, with loading logo if any, to show in the list UI
                playLists.value = await Promise.all(
                    userPlaylist.value.map(async (playlist: PlayListStaff) => {
                        const result: MusicalListItem = {
                            id: playlist.play_id,
                            title: playlist.play_name || '',
                            asset: playlist.play_ass_id ?? -1,
                            type: MusicalListItemType.Playlist,
                        };
                        return result;
                    })
                );
                playLists.value = playLists.value.reverse();
                onSearch('');
            } catch (error) {
                isLoading.value = false;
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: `${error}`,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            } finally {
                isLoading.value = false;
            }
        };

        onBeforeMount(() => loadPlaylists());

        return {
            isNewPopup,
            isCompleted,
            currentStaff,
            newPlaylistName,
            selectedPlaylist,
            filteredPlayLists,
            newPlaylistModalData,
            playlistCreatorModalData,
            handleSearch,
            onNewPlaylist,
            onChoosePlaylist,
            getCurrentUserName,
        };
    },
};
