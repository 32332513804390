import {
    currentParticipant,
    currentStaff,
    currentUserType,
    isAlertOpened,
    openAlertPopup,
} from '@/services/sharedService';
import { UserType } from '../enums/melovie.enum';
import { ConfirmType } from '../enums/ui.enum';
import i18n from '@/i18n';

/**
 * Get icon url after compile
 *
 * @param {String} icon name of the icon
 * @return {String} url of the icon
 */
export const getIconUrl = (path: string): string => {
    const renderPath = require(`@/assets${path}`);
    return renderPath;
};

/**
 * Convert special characters to normal
 *
 * @param {String} source text
 * @return {String} converted text
 */
export const removeDiacritics = (source: string): string => {
    return source.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

/**
 * Delay a function calling
 *
 * @param {Function} func function to call
 * @param {number} timeout delay time
 */
export const debounce = (func: Function, timeout: number = 300) => {
    let timer: any;
    return (...args: any[]) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

/**
 * Search with regex
 *
 * @param {String} searchValue search text
 * @param {String} targetValue target text to find if it contains search text or not
 * @return {boolean} if contain true otherwise false
 */
export const isContainSubString = (searchValue: string, targetValue: string): boolean => {
    searchValue = searchValue.trim();
    let status = true;
    try {
        status = new RegExp(removeDiacritics(searchValue).toLowerCase()).test(
            removeDiacritics(targetValue).toLowerCase()
        );
    } catch (error) {
        openAlertPopup({
            confirmType: ConfirmType.Error,
            message: `${error}`,
            right: {
                text: i18n.global.t('generic.back'),
                onClick: () => {
                    isAlertOpened.value = false;
                },
            },
        });
    }
    return status;
};

/**
 * Get current user name
 */
export const getCurrentUserName = (): string =>
    currentUserType.value === UserType.Staff
        ? `${currentStaff.value?.staf_firstname} ${currentStaff.value?.staf_lastname}`
        : `${currentParticipant.value?.par_firstname} ${currentParticipant.value?.par_lastname}`;
