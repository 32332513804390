import { api, melovieApiPrefix } from '@/api';
import { AnimationPlace } from '@/helper/interface/animationPlace.interface';
import { Group } from '@/helper/interface/group.interface';
import { Trouble } from '@/helper/interface/medicalReport.interface';
import { Service } from '@/helper/interface/service.interface';
/**
 * Get list of animation places from the API
 *
 * @returns {AnimationPlace[]} list of animation places.
 */
export const getAnimationPlaceApi = async (): Promise<AnimationPlace[]> => {
    const result = await api.post(`${melovieApiPrefix}/list/animationplaces`, {});
    return result.data;
};

/**
 * Get list of groups from the API
 *
 * @returns {Group[]} list of groups.
 */
export const getGroupsApi = async (): Promise<Group[]> => {
    const result = await api.post(`${melovieApiPrefix}/list/groups`, {});
    return result.data;
};
/**
 * Get list of services from the API
 *
 * @returns {Service[]} list of services.
 */
export const getServicesApi = async (): Promise<Service[]> => {
    const result = await api.post(`${melovieApiPrefix}/list/services`, {});
    return result.data;
};
/**
 * Get list of troubles from the API
 *
 * @returns {Trouble[]} list of troubles.
 */
export const getTroublesApi = async (): Promise<Trouble[]> => {
    const result = await api.post(`${melovieApiPrefix}/list/troubles`, {});
    return result.data;
};
