import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41fbe18d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar_main-container" }
const _hoisted_2 = { class: "user-section" }
const _hoisted_3 = { class: "image-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-container" }
const _hoisted_6 = { class: "user-name" }
const _hoisted_7 = { class: "user-designation" }
const _hoisted_8 = { class: "menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          alt: "",
          src: _ctx.profilePhoto
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('generic.welcome')), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.userName), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Menu, { "menu-items": _ctx.menuItems }, null, 8, ["menu-items"])
    ])
  ]))
}