
import { SidebarMenuItem } from '@/helper/interface/generic.interface';
import { defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router';

/**
 * Menu component for sidebar
 *
 * @param {SidebarMenuItem[]} menuItems contains id, name, icon & path of the sidebar
 */
export default defineComponent({
    name: 'Menu',
    props: {
        menuItems: { type: Array as PropType<SidebarMenuItem[]>, required: true },
    },
    setup() {
        const route = useRoute();
        const checkActiveRoute = (tab: SidebarMenuItem): boolean => {
            if (route.path === tab.path) return true;

            if (route.path === '/participant/questionnaire' && tab.path === '/participant/profile') return true;

            if (route.path === '/participant/music-report' && tab.path === '/participant/discovery') return true;

            if (route.path === '/questionnaire' && tab.path === '/profile') return true;

            if (route.name === 'PlaylistStaff' && tab.path === '/library') return true;

            if (route.name === 'PlaylistParticipant' && tab.path === '/participant/library') return true;

            return false;
        };
        return { checkActiveRoute };
    },
});
