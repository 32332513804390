
import Menu from './menu/MenuComponent.vue';
import { defineComponent, PropType } from 'vue';
import { SidebarMenuItem } from '@/helper/interface/generic.interface';

/**
 * Sidebar component for navigation
 *
 * @param {SidebarMenuItem[]} menuItems contains id, name, icon & path of the sidebar
 * @param {string} userName shows the name of the current user logged in
 * @param {string} profilePhoto shows the profile picture of the current user
 */
export default defineComponent({
    name: 'Sidebar',
    components: { Menu },
    props: {
        userName: { type: String, required: true },
        profilePhoto: String,
        menuItems: { type: Array as PropType<SidebarMenuItem[]>, required: true },
    },
});
