import { QuestionnaireType, UserType } from '@/helper/enums/melovie.enum';
import { Field, QuestionnaireData, ResultEval } from '@/helper/interface/eval.interface';
import { Genre } from '@/helper/interface/music/genre.interface';
import { Period } from '@/helper/interface/music/period.interface';
import { ref } from 'vue';
import {
    currentParticipant,
    currentStaff,
    currentUserType,
    participantFavorites,
    staffFavorites,
} from '@/services/sharedService';
import {
    removeParticipantFavorite,
    setParticipantFavorite,
    setParticipantProfile,
} from '@/services/participantService';
import { removeStaffFavorite, setStaffFavorite, setStaffProfile } from '@/services/staffService';
// import { setResult } from '@/services/questionnaireService';
import { AnsweredLayout } from '@/helper/interface/generic.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import {
    FavoritesStaff,
    FavoritesPart,
    TrackFavorite,
    ArtistLike,
    AlbumLike,
} from '@/helper/interface/favorite.interface';

export function useQuestionnaire() {
    // Period step
    const selectedPeriods = ref<Period[]>([]);

    /**
     * handles user selected periods
     *
     * @param {Period} period
     */
    const onSelectPeriod = (period: Period) =>
        selectedPeriods.value.find((checkPeriod: Period) => checkPeriod.muspe_id === period.muspe_id)
            ? selectedPeriods.value.splice(
                  selectedPeriods.value.findIndex(
                      (checkPeriod: Period) => checkPeriod.muspe_id === period.muspe_id
                  ),
                  1
              )
            : selectedPeriods.value.push(period);

    // Genre step
    const selectedGenres = ref<Genre[]>([]);
    /**
     * handles user selected genres
     *
     * @param {Genre} genre
     */
    const onSelectGenre = (genre: Genre) =>
        selectedGenres.value.find((checkGenre: Genre) => checkGenre.gmus_id === genre.gmus_id)
            ? selectedGenres.value.splice(
                  selectedGenres.value.findIndex((checkGenre: Genre) => checkGenre.gmus_id === genre.gmus_id),
                  1
              )
            : selectedGenres.value.push(genre);

    /**
     * handles when user submits questionnaire
     *
     * @param {QuestionnaireData[]} questionnaireData
     * @param {UserType} [userType=UserType.Staff]
     * @return {*}
     */
    const onSubmit = (questionnaireData: QuestionnaireData[], userType: UserType = UserType.Staff) => {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                let genres: number[] | undefined = undefined;
                let artists: number[] | undefined = undefined;
                let albums: number[] | undefined = undefined;
                let tracks: number[] | undefined = undefined;
                let periods: number[] | undefined = undefined;
                let results: ResultEval = {
                    date: new Date(),
                    eval_id: questionnaireData[0].eval_id,
                    fields: [] as Field[],
                } as ResultEval;
                let answeredLayouts: AnsweredLayout[] = [];
                if (questionnaireData[0]?.eval_id)
                    answeredLayouts.push({ eval_id: questionnaireData[0].eval_id, evr_id: 0 });

                // fill answers
                questionnaireData.forEach((questionnaire: QuestionnaireData) => {
                    const questionMetaData = questionnaire?.children?.[0];
                    if (questionMetaData?.answer) {
                        switch (questionMetaData.eval_type) {
                            case QuestionnaireType.Period:
                                periods = (questionMetaData.answer as Period[]).map(
                                    (period: Period) => period.muspe_id
                                );
                                break;
                            case QuestionnaireType.Genre:
                                genres = (questionMetaData.answer as Genre[]).map(
                                    (genre: Genre) => genre.gmus_id
                                );
                                break;
                            case QuestionnaireType.Artist:
                                artists = (questionMetaData.answer as MusicalListItem[]).map(
                                    (artist: MusicalListItem) => artist.id!
                                );
                                break;
                            case QuestionnaireType.Album:
                                albums = (questionMetaData.answer as MusicalListItem[]).map(
                                    (albums: MusicalListItem) => albums.id!
                                );
                                break;
                            case QuestionnaireType.Track:
                                tracks = (questionMetaData.answer as MusicalListItem[]).map(
                                    (track: MusicalListItem) => track.id!
                                );
                                break;

                            default:
                                if (typeof questionMetaData.answer == 'string') {
                                    results.fields.push({
                                        evf_id: questionMetaData.evf_id,
                                        evf_score: questionMetaData.answer,
                                    });
                                }
                                break;
                        }
                    }
                });

                // submit answers
                if (genres || periods || artists || tracks) {
                    switch (userType) {
                        case UserType.Staff:
                            await setStaffProfile(
                                currentStaff.value?.staf_id!,
                                periods,
                                genres,
                                artists,
                                tracks,
                                answeredLayouts.length ? answeredLayouts : undefined
                            );
                            break;
                        case UserType.Participant:
                            await setParticipantProfile(
                                currentParticipant.value?.par_id!,
                                periods,
                                genres,
                                artists,
                                tracks,
                                answeredLayouts.length ? answeredLayouts : undefined
                            );
                            break;
                    }
                }

                // update favorites
                const userFavorite: FavoritesStaff | FavoritesPart =
                    currentUserType.value == UserType.Staff
                        ? staffFavorites.value || {}
                        : currentUserType.value == UserType.Participant
                        ? participantFavorites.value || {}
                        : ({} as FavoritesStaff);

                try {
                    // delete favorites
                    // track
                    if (tracks) {
                        const trackToDelete = userFavorite.tracks?.filter(
                            (track: TrackFavorite) => !tracks?.includes(track.id)
                        );
                        if (trackToDelete?.length) {
                            await Promise.all(
                                trackToDelete.map(async (trackDel: TrackFavorite) => {
                                    switch (userType) {
                                        case UserType.Staff:
                                            await removeStaffFavorite(currentStaff.value?.staf_id!, trackDel.id);
                                            break;
                                        case UserType.Participant:
                                            await removeParticipantFavorite(
                                                currentParticipant.value?.par_id!,
                                                trackDel.id
                                            );
                                            break;
                                    }
                                })
                            );
                        }
                    }

                    // artist
                    if (artists) {
                        const artistToDelete = userFavorite.artists?.filter(
                            (artist: ArtistLike) => !artists?.includes(artist.id)
                        );
                        if (artistToDelete?.length) {
                            await Promise.all(
                                artistToDelete.map(async (artistDel: ArtistLike) => {
                                    switch (userType) {
                                        case UserType.Staff:
                                            await removeStaffFavorite(
                                                currentStaff.value?.staf_id!,
                                                undefined,
                                                artistDel.id
                                            );
                                            break;
                                        case UserType.Participant:
                                            await removeParticipantFavorite(
                                                currentParticipant.value?.par_id!,
                                                undefined,
                                                artistDel.id
                                            );
                                            break;
                                    }
                                })
                            );
                        }
                    }

                    // album
                    if (albums) {
                        const albumToDelete = userFavorite.albums?.filter(
                            (album: AlbumLike) => !albums?.includes(album.id)
                        );
                        if (albumToDelete?.length)
                            await Promise.all(
                                albumToDelete.map(async (albumDel: AlbumLike) => {
                                    switch (userType) {
                                        case UserType.Staff:
                                            await removeStaffFavorite(
                                                currentStaff.value?.staf_id!,
                                                undefined,
                                                undefined,
                                                albumDel.id
                                            );
                                            break;
                                        case UserType.Participant:
                                            await removeParticipantFavorite(
                                                currentParticipant.value?.par_id!,
                                                undefined,
                                                undefined,
                                                albumDel.id
                                            );
                                            break;
                                    }
                                })
                            );
                    }

                    // add new favorites
                    switch (userType) {
                        case UserType.Staff:
                            await setStaffFavorite(currentStaff.value?.staf_id!, tracks, artists, albums);
                            break;
                        case UserType.Participant:
                            await setParticipantFavorite(currentParticipant.value?.par_id!, tracks, artists, albums);
                            break;
                    }
                } catch (err) {
                    throw err;
                }

                if (results.fields?.length) {
                    switch (userType) {
                        case UserType.Staff:
                            results.staf_id = currentStaff.value?.staf_id!;
                            break;
                        case UserType.Participant:
                            results.par_id = currentParticipant.value?.par_id!;
                            break;
                    }
                    // TODO solve the issue in API "data should have required property '.participant'"
                    // await setResult(results);
                }
                resolve(true);
            } catch (error) {
                reject(error);
            }
        });
    };
    return { selectedGenres, onSelectGenre, selectedPeriods, onSelectPeriod, onSubmit };
}
