import { getParticipantFavoritesAndHates, getParticipantPlaylists } from '@/services/participantService';
import { UserType } from '@/helper/enums/melovie.enum';
import {
    currentParticipant,
    currentStaff,
    participantFavorites,
    participantPlaylists,
    staffFavorites,
    staffPlaylists,
} from './sharedService';
import { getStaffFavoritesAndHates, getStaffPlaylists } from './staffService';

export const loadLibraryData = (userType: UserType) => {
    return new Promise<void>(async (resolve, resect) => {
        try {
            if (userType === UserType.Staff) {
                if (!staffFavorites.value)
                    staffFavorites.value = await getStaffFavoritesAndHates(currentStaff.value?.staf_id!);

                if (!staffPlaylists.value.length)
                    staffPlaylists.value = await getStaffPlaylists(currentStaff.value?.staf_id!);
            }

            if (userType === UserType.Participant) {
                if (!participantFavorites.value)
                    participantFavorites.value = await getParticipantFavoritesAndHates(
                        currentParticipant.value?.par_id!
                    );

                if (!participantPlaylists.value.length)
                    participantPlaylists.value = await getParticipantPlaylists(currentParticipant.value?.par_id!);
            }
            resolve();
        } catch (error) {
            resect(error);
        }
    });
};
