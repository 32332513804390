
import { defineComponent, onBeforeMount, ref } from 'vue';
import Sidebar from '@/components/sidebar/SidebarComponent.vue';
import { SidebarMenuItem } from '@/helper/interface/generic.interface';
import { useI18n } from 'vue-i18n';
import { Participant } from '@/helper/interface/participant.interface';
import {
    currentParticipant,
    currentUserType,
    endDate,
    isAlertOpened,
    isLoading,
    openAlertPopup,
    currentSessionId,
} from '@/services/sharedService';
import { useRouter } from 'vue-router';
import { ActionLabel, ConfirmType } from '@/helper/enums/ui.enum';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import { UserType } from '@/helper/enums/melovie.enum';
import { addAction, getSessionData, setSessionData } from '@/services/sessionService';
import { Session, SessionData } from '@/helper/interface/session.interface';
/**
 * layout of dashboard page
 */
export default defineComponent({
    name: 'ParticipantDashboard',
    components: {
        Sidebar,
        Confirm,
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const isReportPopup = ref<boolean>(false);
        const reportPopupData = ref<ConfirmProps>({
            confirmType: ConfirmType.Alert,
            title: t('views.dashboard.session.endOfSession'),
            headerIcon: 'fa-solid fa-arrow-right-from-bracket',
            onClose: () => (isReportPopup.value = false),
            left: {
                onClick: () => {
                    endDate.value = new Date();
                    addAction(ActionLabel.End);
                    setSession(getSessionData());
                    isReportPopup.value = false;
                },
            },
            right: {
                onClick: () => {
                    endDate.value = new Date();
                    addAction(ActionLabel.End);
                    isLoading.value = true;
                    setSession(getSessionData(), true);
                    isReportPopup.value = false;
                },
            },
        });

        const setSession = (sessionData: SessionData, gotoReport: boolean = false) => {
            isLoading.value = true;
            setSessionData(sessionData)
                .then((res: Session) => {
                    currentSessionId.value = res.sess_id ?? undefined;
                    if (res.sess_id) {
                        router.push(gotoReport ? `/report/${res.sess_id}` : '/session');
                    }
                })
                .catch((error) =>
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    })
                )
                .finally(() => (isLoading.value = false));
        };

        const menuItems: SidebarMenuItem[] = [
            {
                id: 1,
                name: t('components.sidebar.profile'),
                icon: 'fa-user',
                path: '/participant/profile',
            },
            {
                id: 2,
                name: t('components.sidebar.discovery'),
                icon: 'fa-headphones-simple',
                path: '/participant/discovery',
            },
            {
                id: 3,
                name: t('components.sidebar.library'),
                icon: 'fa-heart',
                path: '/participant/library',
            },
            // {
            //     id: 4,
            //     name: t('components.sidebar.relax'),
            //     icon: 'fa-spa',
            //     // path: '/participant/relax', // to disabled the relax page, for now
            // },
            {
                id: 5,
                name: t('components.sidebar.search'),
                icon: 'fa-magnifying-glass',
                path: '/participant/search',
            },
            {
                id: 6,
                name: t('components.sidebar.complete'),
                icon: 'fa-right-from-bracket',
                onClick() {
                    triggerConfirmModal();
                },
            },
        ];
        // get saved current user from local storage
        const currentUser = ref<Participant>();
        currentUser.value = currentParticipant.value;

        const triggerConfirmModal = () => {
            isReportPopup.value = true;
        };

        onBeforeMount(() => (currentUserType.value = UserType.Participant));
        return { menuItems, currentUser, isReportPopup, reportPopupData };
    },
});
