import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-517fce82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "discovery-main-container" }
const _hoisted_2 = { class: "recommendation-container small-container" }
const _hoisted_3 = { class: "recommended-tracks" }
const _hoisted_4 = { class: "play-container disabled" }
const _hoisted_5 = { class: "play-icon" }
const _hoisted_6 = { disabled: "" }
const _hoisted_7 = { class: "playlist-container small-container" }
const _hoisted_8 = { class: "playlist-tracks" }
const _hoisted_9 = { class: "play-container disabled" }
const _hoisted_10 = { class: "play-icon" }
const _hoisted_11 = { disabled: "" }
const _hoisted_12 = { class: "filter-header" }
const _hoisted_13 = { class: "filter-container" }
const _hoisted_14 = { class: "filter-objects" }
const _hoisted_15 = { class: "filter-period" }
const _hoisted_16 = { class: "filter-genre" }
const _hoisted_17 = { class: "filter-songs-by-number" }
const _hoisted_18 = { class: "song-number-button" }
const _hoisted_19 = { class: "song-number-button" }
const _hoisted_20 = { class: "song-number-button" }
const _hoisted_21 = { class: "song-number-button" }
const _hoisted_22 = { class: "play-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FavoriteCardComponent = _resolveComponent("FavoriteCardComponent")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Header = _resolveComponent("Header")!
  const _component_PeriodList = _resolveComponent("PeriodList")!
  const _component_GenreList = _resolveComponent("GenreList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FavoriteCardComponent, {
          title: _ctx.$t('views.participantDashboard.discovery.recommendation'),
          "no-item-text": _ctx.$t('views.dashboard.profile.noRecommendation'),
          items: _ctx.recommendedTracks,
          "logo-size": "5.5em",
          "is-editable": false
        }, null, 8, ["title", "no-item-text", "items"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", _hoisted_6, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-play" })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_FavoriteCardComponent, {
          title: _ctx.$t('views.participantDashboard.discovery.discoverPlaylist'),
          "no-item-text": _ctx.$t('views.dashboard.profile.noFavoritePlaylist'),
          items: _ctx.playlistTracks,
          "logo-size": "5.5em"
        }, null, 8, ["title", "no-item-text", "items"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("button", _hoisted_11, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-play" })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_Header, {
        title: _ctx.$t('views.participantDashboard.discovery.filterToDiscoverNewMusic'),
        "bg-color": "transparent",
        "title-color": "#36AEC1",
        "title-align": "flex-start"
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('views.participantDashboard.discovery.periods')), 1),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_PeriodList, {
            "selected-data": _ctx.selectedPeriods,
            "on-submit": _ctx.onSelectPeriod
          }, null, 8, ["selected-data", "on-submit"])
        ]),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('views.participantDashboard.discovery.musicalStyles')), 1),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_GenreList, {
            "selected-data": _ctx.selectedGenres,
            "on-select": _ctx.onSelectGenre
          }, null, 8, ["selected-data", "on-select"])
        ]),
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('views.participantDashboard.discovery.numberOfSongs')), 1),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("button", {
              class: _normalizeClass({ selected: _ctx.trackCount === 5 }),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.traceTrackCount(5)))
            }, " 5 " + _toDisplayString(_ctx.$t('views.participantDashboard.discovery.songs')), 3)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("button", {
              class: _normalizeClass({ selected: _ctx.trackCount === 10 }),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.traceTrackCount(10)))
            }, " 10 " + _toDisplayString(_ctx.$t('views.participantDashboard.discovery.songs')), 3)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("button", {
              class: _normalizeClass({ selected: _ctx.trackCount === 15 }),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.traceTrackCount(15)))
            }, " 15 " + _toDisplayString(_ctx.$t('views.participantDashboard.discovery.songs')), 3)
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("button", {
              class: _normalizeClass({ selected: _ctx.trackCount === 20 }),
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.traceTrackCount(20)))
            }, " 20 " + _toDisplayString(_ctx.$t('views.participantDashboard.discovery.songs')), 3)
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: "play-container cursor-pointer",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.playButtonClicked && _ctx.playButtonClicked(...args)))
      }, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("button", null, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-play" })
          ])
        ])
      ])
    ])
  ]))
}