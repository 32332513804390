
import { defineComponent, onBeforeMount, onUnmounted, watch } from 'vue';
import Header from '@/components/header/HeaderComponent.vue';
import MusicalList from '@/components/musical_list/MusicalListComponent.vue';
import LibraryFilterComponent from '@/components/library_filter/LibraryFilterComponent.vue';
import Paginator from '@/components/paginator/PaginatorComponent.vue';
import { useGenericLibrary } from '@/composable/useGenericLibrary';
import {
    currentSelectedLibraryPage,
    isAlertOpened,
    isLoading,
    librarySearchText,
    openAlertPopup,
    participantPlaylists,
    resetLibraryData,
    selectedLibraryFilters,
    staffPlaylists,
} from '@/services/sharedService';
import { loadLibraryData } from '@/services/libraryService';
import { UserType } from '@/helper/enums/melovie.enum';
import { ConfirmType } from '@/helper/enums/ui.enum';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'Library',
    components: {
        Header,
        Paginator,
        MusicalList,
        LibraryFilterComponent,
    },
    setup() {
        const {
            currentData,
            filteredData,
            currentUserType,
            onSearch,
            onRowClick,
            onFilterChange,
            onAddToPlaylist,
            onAddToSouvenir,
            initializeLibraryData,
            getCurrentSelectedPage,
        } = useGenericLibrary();
        const route = useRoute();
        const { t } = useI18n();

        watch(
            () => (currentUserType.value === UserType.Staff ? staffPlaylists.value : participantPlaylists.value),
            async () => {
                await initializeLibraryData();
                onSearch(librarySearchText.value, selectedLibraryFilters.value);
            },
            { deep: true }
        );

        onBeforeMount(async () => {
            isLoading.value = true;
            try {
                if (route.name === 'ParticipantLibrary') currentUserType.value = UserType.Participant;
                else currentUserType.value = UserType.Staff;
                await loadLibraryData(currentUserType.value);
                await initializeLibraryData();
                onSearch(librarySearchText.value, selectedLibraryFilters.value);
            } catch (error) {
                isLoading.value = false;
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: `${error}`,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            } finally {
                isLoading.value = false;
            }
        });
        onUnmounted(() => {
            // if single music player view going to open, then don't reset library data
            if (route.name !== 'MusicPlayer') resetLibraryData(); // else reset
        });
        return {
            currentData,
            filteredData,
            currentUserType,
            currentSelectedLibraryPage,
            onSearch,
            onRowClick,
            onFilterChange,
            onAddToPlaylist,
            onAddToSouvenir,
            initializeLibraryData,
            getCurrentSelectedPage,
        };
    },
});
