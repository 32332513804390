import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/musics/playlist.png'


const _withScopeId = n => (_pushScopeId("data-v-4e8aa808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "playlist-creator-container" }
const _hoisted_2 = { class: "playlist-creator_main-container" }
const _hoisted_3 = { class: "body-section" }
const _hoisted_4 = {
  key: 0,
  class: "creator"
}
const _hoisted_5 = { class: "head-container" }
const _hoisted_6 = { class: "title-container" }
const _hoisted_7 = { class: "button-container" }
const _hoisted_8 = { class: "icon-container" }
const _hoisted_9 = { class: "search-container" }
const _hoisted_10 = { class: "icon-container" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "list-container" }
const _hoisted_13 = {
  key: 1,
  class: "no-playlist-container"
}
const _hoisted_14 = {
  key: 1,
  class: "complete"
}
const _hoisted_15 = { class: "details-container" }
const _hoisted_16 = { class: "image-container" }
const _hoisted_17 = ["alt"]
const _hoisted_18 = { class: "text-container" }
const _hoisted_19 = { class: "playlist-name" }
const _hoisted_20 = { class: "person-container" }
const _hoisted_21 = { class: "person-name" }
const _hoisted_22 = { class: "status-container" }
const _hoisted_23 = { class: "image-container" }
const _hoisted_24 = { class: "text" }
const _hoisted_25 = { class: "input-container" }
const _hoisted_26 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalSidebar = _resolveComponent("ModalSidebar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_MusicalList = _resolveComponent("MusicalList")!
  const _component_Confirm = _resolveComponent("Confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Confirm, {
        data: $setup.playlistCreatorModalData,
        maxWidth: "90vw",
        maxHeight: "95vh"
      }, {
        alertBody: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ModalSidebar, {
              title: _ctx.$t('views.playlist.sidebarTitle'),
              tracks: $props.tracks
            }, null, 8, ["title", "tracks"]),
            _createElementVNode("div", _hoisted_3, [
              (!$setup.isCompleted)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.choosePlaylist')), 1)
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("button", {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onNewPlaylist()))
                        }, [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_font_awesome_icon, { icon: "fa-add" })
                          ]),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.newPlaylist')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "search-icon",
                            icon: "fa-magnifying-glass"
                          })
                        ]),
                        _createElementVNode("input", {
                          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.handleSearch && $setup.handleSearch(...args))),
                          type: "text",
                          placeholder: _ctx.$t('views.playlist.findPlaylist')
                        }, null, 40, _hoisted_11)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      ($setup.filteredPlayLists.length > 0)
                        ? (_openBlock(), _createBlock(_component_MusicalList, {
                            key: 0,
                            "list-items": $setup.filteredPlayLists,
                            "on-row-click": $setup.onChoosePlaylist,
                            height: "auto",
                            rowColor: "#fff"
                          }, null, 8, ["list-items", "on-row-click"]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.noPlaylistAvailable')), 1)
                          ]))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("img", {
                          src: _imports_0,
                          alt: $setup.selectedPlaylist?.title
                        }, null, 8, _hoisted_17)
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString($setup.selectedPlaylist?.title), 1),
                        _createElementVNode("div", _hoisted_20, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.playlist.suggestedBy')), 1),
                          _createElementVNode("span", _hoisted_21, _toDisplayString($setup.getCurrentUserName()), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-check" })
                      ]),
                      _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('views.playlist.successfullyAdded')), 1)
                    ])
                  ]))
            ])
          ])
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    ($setup.isNewPopup)
      ? (_openBlock(), _createBlock(_component_Confirm, {
          key: 0,
          class: "new-playlist-modal",
          data: $setup.newPlaylistModalData
        }, {
          alertBody: _withCtx(() => [
            _createElementVNode("div", _hoisted_25, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.newPlaylistName) = $event)),
                type: "text",
                placeholder: _ctx.$t('views.playlist.playlistName')
              }, null, 8, _hoisted_26), [
                [_vModelText, $setup.newPlaylistName]
              ])
            ])
          ]),
          _: 1
        }, 8, ["data"]))
      : _createCommentVNode("", true)
  ], 64))
}