import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f110e9ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }
const _hoisted_2 = { class: "icon-container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "items" }
const _hoisted_5 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (tab) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "item-container",
        key: tab.id
      }, [
        (tab.path)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: tab.path,
              onClick: ($event: any) => (tab.onClick && tab.onClick())
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["items", {
                        selected: _ctx.checkActiveRoute(tab),
                    }])
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: tab.icon
                    }, null, 8, ["icon"])
                  ]),
                  _createElementVNode("span", null, _toDisplayString(tab.name), 1)
                ], 2)
              ]),
              _: 2
            }, 1032, ["to", "onClick"]))
          : (_openBlock(), _createElementBlock("a", {
              key: 1,
              onClick: ($event: any) => (tab.onClick && tab.onClick())
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_font_awesome_icon, {
                    icon: tab.icon
                  }, null, 8, ["icon"])
                ]),
                _createElementVNode("span", null, _toDisplayString(tab.name), 1)
              ])
            ], 8, _hoisted_3))
      ]))
    }), 128))
  ]))
}