import { api, melovieApiPrefix } from '@/api';
import { CheckPin, IsGood } from '@/helper/interface/auth.interface';
import { IdStaffProfile, ProfileStaff, ProfileStaffRequest, Staff } from '@/helper/interface/staff.interface';

/**
 * Get list of staffs from the API
 *
 * @returns {Staff[]} list of staffs.
 */
export const getStaffApi = async (): Promise<Staff[]> => {
    const result = await api.post(`${melovieApiPrefix}/list/staffs`, {});
    return result.data;
};

/**
 * Get profile of a staff
 *
 * @param {ProfileStaff} reqBody
 * @return {*}  {Promise<ProfileStaff>}
 */
export const getStaffProfileApi = async (staffId: number): Promise<ProfileStaff> => {
    const result = await api.post(`${melovieApiPrefix}/staff/profil/get`, { staf_id: staffId });
    return result.data;
};

/**
 * Set staff profile
 *
 * @param {ProfileStaffRequest} reqBody
 * @return {*}  {Promise<IdStaffProfile>}
 */
export const setStaffProfileApi = async (reqBody: ProfileStaffRequest): Promise<IdStaffProfile> => {
    const result = await api.post(`${melovieApiPrefix}/staff/profil/set`, reqBody);
    return result.data;
};

/**
 * Check pin of a staff
 *
 * @param {CheckPin} reqBody
 * @return {*}  {Promise<IsGood>}
 */
export const checkStaffPinApi = async (reqBody: CheckPin): Promise<IsGood> => {
    const result = await api.post(`${melovieApiPrefix}/staff/checkpin`, reqBody);
    return result.data;
};
