
import ImageComponent from '@/components/ImageComponent.vue';
import PeriodList from '@/components/period_list/PeriodList.vue';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { Period } from '@/helper/interface/music/period.interface';
import { defineComponent, PropType } from 'vue';

/**
 * Questionnaire component containing text and icons of current page.
 * @param {array} selectedData an array to show the selected period icons and texts
 * @param {Function} onSubmit  function to execute codes when an user selects period and submit
 */
export default defineComponent({
    name: 'QuestionnairePeriodComponent',
    components: { PeriodList, ImageComponent },
    props: {
        selectedData: Array as PropType<Period[]>,
        onSubmit: Function as PropType<(period: Period) => void>,
    },
    setup() {
        return {
            ref: AssetSourceEnum.Reference,
        };
    },
});
