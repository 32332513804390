import { api, melovieApiPrefix } from '@/api';
import {
    Report,
    ReportActivityData,
    ReportActivityParticipant,
    ReportData,
} from '@/helper/interface/medicalReport.interface';

/**
 * Set medical report
 *
 * @param {ReportData} reqBody
 * @return {*}  {Promise<Report>}
 */
export const setMedicalReportApi = async (reqBody: ReportData): Promise<Report> => {
    const result = await api.post(`${melovieApiPrefix}/report`, reqBody);
    return result.data;
};

/**
 * Set medical report activity
 *
 * @param {ReportActivityData} reqBody
 * @return {*}  {Promise<ReportActivityParticipant>}
 */
export const setMedicalReportActivityApi = async (reqBody: ReportActivityData): Promise<ReportActivityParticipant> => {
    const result = await api.post(`${melovieApiPrefix}/reportactivityparticipant`, reqBody);
    return result.data;
};
