import { allEval, currentParticipant, participantProfile, staffProfile } from '@/services/sharedService';
import { UserType } from '@/helper/enums/melovie.enum';
import { getAllEvalApi, getEvalLayoutApi, setEvalResultApi } from '@/api/evalApi';
import { Eval, EvalLayout, IdResultEval, QuestionnaireData, ResultEval } from '@/helper/interface/eval.interface';
import { currentStaff, newStaffQuestionnaireCount, newParticipantQuestionnaireCount } from './sharedService';
import { AnsweredLayout } from '@/helper/interface/generic.interface';

/**
 * Get all eval
 *
 * @returns list of eval
 */
export const getAllEval = () => {
    return new Promise<Eval[]>(async (resolve, reject) => {
        getAllEvalApi()
            .then((response: Eval[]) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/**
 * Get all eval layouts
 *
 * @returns list of eval layouts
 */
export const getEvalLayouts = (evalId: number) => {
    return new Promise<EvalLayout[]>(async (resolve, reject) => {
        getEvalLayoutApi({ eval_id: evalId })
            .then((response: EvalLayout[]) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Set questionnaire result
 *
 * @param {ResultEval} reqBody result
 * @returns {Promise<IdResultEval>} eval result id
 */
export const setResult = (reqBody: ResultEval) => {
    return new Promise<IdResultEval>(async (resolve, reject) => {
        setEvalResultApi(reqBody)
            .then((response: IdResultEval) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Get structured questionnaire data
 *
 * @return {Promise<QuestionnaireData>}
 */
export const getQuestionnaireData = (evalId: number) => {
    return new Promise<QuestionnaireData>((resolve, reject) => {
        getEvalLayouts(evalId)
            .then((response: EvalLayout[]) => {
                response.forEach((qesData: QuestionnaireData) => {
                    qesData.children = response
                        .filter((evalLayout: EvalLayout) => evalLayout.eval_suborder === qesData.evf_id)
                        .sort(
                            (current: EvalLayout, next: EvalLayout) =>
                                (current.eval_order || 0) - (next.eval_order || 0)
                        ) as QuestionnaireData[];
                });
                resolve(
                    response.find((qesData: QuestionnaireData) => !qesData.eval_suborder) || ({} as QuestionnaireData)
                );
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const generateNewQuestionnaireCount = (userType: UserType) => {
    if (userType === UserType.Staff && currentStaff.value?.staf_id) {
        newStaffQuestionnaireCount.value = staffProfile.value?.answeredLayouts?.length
            ? allEval.value.filter(
                  (resEval: Eval) =>
                      !staffProfile.value?.answeredLayouts?.find(
                          (ansLayout: AnsweredLayout) =>
                              ansLayout.eval_id === resEval.eval_id &&
                              (ansLayout.evr_id !== undefined || ansLayout.evr_id !== null)
                      )
              ).length
            : allEval.value.length;
    } else if (userType === UserType.Participant && currentParticipant.value?.par_id) {
        newParticipantQuestionnaireCount.value = participantProfile.value?.answeredLayouts?.length
            ? allEval.value.filter(
                  (resEval: Eval) =>
                      !participantProfile.value?.answeredLayouts?.find(
                          (ansLayout: AnsweredLayout) =>
                              ansLayout.eval_id === resEval.eval_id &&
                              (ansLayout.evr_id !== undefined || ansLayout.evr_id !== null)
                      )
              ).length
            : allEval.value.length;
    }
};
