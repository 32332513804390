
import { QuestionnaireType } from '@/helper/enums/melovie.enum';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { Genre } from '@/helper/interface/music/genre.interface';
import { allGenres } from '@/services/sharedService';
import { defineComponent, PropType, ref } from 'vue';
import ImageComponent from '../ImageComponent.vue';

/**
 * Activity card component containing text and numbers of current page.
 * @param {array} selectedData an array to show the selected genre icons
 * @param {Function} onSelect  function to execute codes when an user selects genres
 */
export default defineComponent({
    name: 'QuestionnaireGenreContainer',
    props: {
        genreData: { type: Array as PropType<Genre[]> },
        selectedData: Array as PropType<Genre[]>,
        onSelect: Function as PropType<(genre: Genre) => void>,
    },
    setup(props: any) {
        const genres = ref<Genre[]>(props.genreData ?? allGenres.value);
        /**
         * Checking if the genre received as props is actually in db or not
         */
        const isGenreSelected = (genre: Genre): boolean => props.selectedData?.find((checkGenre: Genre) => checkGenre.gmus_id === genre.gmus_id)
            ? true
            : false;
        return {
            genres,
            isGenreSelected,
            QuestionnaireType,
            ref: AssetSourceEnum.Reference,
        };
    },
    components: { ImageComponent }
});
