import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26a2f0f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-numbers" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return ($setup.totalPages > 1)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["pagination-container", 
            $props.alignment === $setup.PaginatorAlignmentType.Center
                ? 'center'
                : $props.alignment === $setup.PaginatorAlignmentType.Start
                ? 'start'
                : $props.alignment === $setup.PaginatorAlignmentType.End
                ? 'end'
                : ''
        ])
      }, [
        _createElementVNode("div", {
          class: "pagination-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.handlePaginationItem('PREV')))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-left" })
        ]),
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.displayedPages, (page, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "first-page",
              key: index
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["pagination-item", { active: $setup.selectedCurrentPage.toString() === page }]),
                onClick: ($event: any) => ($setup.handlePaginationItem(page))
              }, _toDisplayString(page), 11, _hoisted_2)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", {
          class: "pagination-icon",
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handlePaginationItem('NEXT')))
        }, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-chevron-right" })
        ])
      ], 2))
    : _createCommentVNode("", true)
}