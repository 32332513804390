import { api, musicApiPrefix } from '@/api';
import { Period, PeriodReqBody } from '@/helper/interface/music/period.interface';
/**
 * Get all periods
 *
 * @return {*}  {Promise<Period[]>}
 */
export const getAllPeriodsApi = async (reqBody: PeriodReqBody): Promise<Period[]> => {
    const result = await api.post(`${musicApiPrefix}/list/periods`, reqBody);
    return result.data;
};
