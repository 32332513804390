import { getAllServices } from './establishmentService';
import { SessionPageData } from '@/helper/interface/ui/ui.interface';
import { getAllGroups } from './genericService';
import { getAllParticipants } from './participantService';
import { ActionLabel } from '@/helper/enums/ui.enum';
import {
    startDate,
    ongoingAction,
    actions,
    endDate,
    currentStaff,
    currentParticipant,
    currentUserType,
    currentAnimationPlace,
} from './sharedService';
import { ActionData, Session, SessionAction, SessionData } from '@/helper/interface/session.interface';
import { setSessionDataApi } from '@/api/sessionApi';
import { UserType } from '@/helper/enums/melovie.enum';

/**
 * Fetch all data to start a session, from the API
 *
 * @returns {SessionPageData} list of user, services and groups.
 */
export const getSessionPageData = () => {
    return new Promise<SessionPageData>(async (resolve, reject) => {
        let sessionPageData: SessionPageData = {} as SessionPageData;
        try {
            sessionPageData.participants = await getAllParticipants();
            sessionPageData.services = await getAllServices();
            sessionPageData.groups = await getAllGroups();
            resolve(sessionPageData);
        } catch (error) {
            reject(error);
        }
    });
};

export const addAction = (label: ActionLabel, data?: ActionData) => {
    if (currentUserType.value === UserType.Participant)
        switch (label) {
            case ActionLabel.Start:
                actions.value.push({
                    label: ActionLabel.Start,
                    date_start: startDate.value!,
                    timessincestart: (new Date().getTime() - startDate.value!.getTime()) / 60000,
                });

                break;

            case ActionLabel.Select:
                const selectAction: SessionAction = createAction(label, data);
                if (!ongoingAction.value) {
                    ongoingAction.value = selectAction;
                } else {
                    addAction(ActionLabel.EndPlay, data);
                    ongoingAction.value = selectAction;
                }
                actions.value.push(selectAction);

                break;

            case ActionLabel.Play:
                const playAction: SessionAction = createAction(label, data);
                actions.value.push(playAction);

                break;

            case ActionLabel.Pause:
                const pauseAction: SessionAction = createAction(label, data);
                actions.value.push(pauseAction);

                break;

            case ActionLabel.Resume:
                const resumeAction: SessionAction = createAction(label, data);
                actions.value.push(resumeAction);

                break;

            case ActionLabel.EndPlay:
                const endPlayAction: SessionAction = createAction(label, data);
                actions.value.push(endPlayAction);
                ongoingAction.value = undefined;

                break;

            case ActionLabel.End:
                actions.value.push(createAction(label, data));

                break;
        }
};

function createAction(label: ActionLabel, data?: ActionData): SessionAction {
    const action: SessionAction = {
        label,
        date_start: new Date(),
        timessincestart: (new Date().getTime() - startDate.value!.getTime()) / 60000,
    };
    if (data) action.data = data;
    return action;
}

export const initializeSessionData = () => {
    startDate.value = new Date();
    endDate.value = undefined;
    ongoingAction.value = undefined;
    actions.value = [];
    currentUserType.value = UserType.Participant;
    addAction(ActionLabel.Start);
};

export const getSessionData = (): SessionData => {
    const sessionData: SessionData = {
        date_start: startDate.value!,
        date_end: endDate.value!,
        staf_id: currentStaff.value?.staf_id!,
        par_id: currentParticipant.value?.par_id!,
        anp_id: currentAnimationPlace.value?.anp_id ?? null,
        activity: {
            date_start: startDate.value!,
            date_end: endDate.value!,
            actions: actions.value,
        },
    };
    return sessionData;
};

export const setSessionData = (sessionData: SessionData) => {
    return new Promise<Session>((resolve, reject) => {
        setSessionDataApi(sessionData)
            .then((data: Session) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
