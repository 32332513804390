import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06d8429e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "library-filter-main-container" }
const _hoisted_2 = { class: "filter-buttons" }
const _hoisted_3 = { class: "button-items" }
const _hoisted_4 = { class: "non-searchable" }
const _hoisted_5 = { class: "search-field" }
const _hoisted_6 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleClick(_ctx.MusicalListItemType.Playlist))),
            class: _normalizeClass(
                            _ctx.selectedLibraryFilters.includes(_ctx.MusicalListItemType.Playlist)
                                ? 'active-button'
                                : 'default-button'
                        )
          }, _toDisplayString(_ctx.$t('views.dashboard.library.playlists')), 3),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick(_ctx.MusicalListItemType.Artist))),
            class: _normalizeClass(
                            _ctx.selectedLibraryFilters.includes(_ctx.MusicalListItemType.Artist)
                                ? 'active-button'
                                : 'default-button'
                        )
          }, _toDisplayString(_ctx.$t('views.dashboard.library.artists')), 3),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleClick(_ctx.MusicalListItemType.Album))),
            class: _normalizeClass(
                            _ctx.selectedLibraryFilters.includes(_ctx.MusicalListItemType.Album)
                                ? 'active-button'
                                : 'default-button'
                        )
          }, _toDisplayString(_ctx.$t('views.dashboard.library.albums')), 3),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleClick(_ctx.MusicalListItemType.Track))),
            class: _normalizeClass(
                            _ctx.selectedLibraryFilters.includes(_ctx.MusicalListItemType.Track)
                                ? 'active-button'
                                : 'default-button'
                        )
          }, _toDisplayString(_ctx.$t('views.dashboard.library.musics')), 3),
          _createElementVNode("button", {
            disabled: "",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleClick(_ctx.MusicalListItemType.Shared))),
            class: _normalizeClass(
                            _ctx.selectedLibraryFilters.includes(_ctx.MusicalListItemType.Shared)
                                ? 'active-button'
                                : 'default-button'
                        )
          }, _toDisplayString(_ctx.$t('views.dashboard.library.shared')), 3)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_font_awesome_icon, {
            class: "search-icon",
            icon: "fa-magnifying-glass"
          }),
          _withDirectives(_createElementVNode("input", {
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.librarySearchText) = $event)),
            class: "search-button",
            placeholder: _ctx.$t('views.dashboard.library.search')
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.librarySearchText]
          ])
        ])
      ])
    ])
  ]))
}