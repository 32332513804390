import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b5d1188"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-container" }
const _hoisted_2 = { class: "alert-text-container" }
const _hoisted_3 = { class: "musical-dna-container" }
const _hoisted_4 = { class: "musical-dna_header" }
const _hoisted_5 = { class: "musical-dna_body" }
const _hoisted_6 = { class: "favorite-musical-styles-container" }
const _hoisted_7 = { class: "favorite-musical-periods-container" }
const _hoisted_8 = { class: "favorite-musical-artists-container" }
const _hoisted_9 = { class: "favorite-songs-container" }
const _hoisted_10 = { class: "action-container" }
const _hoisted_11 = { class: "notification-container" }
const _hoisted_12 = { class: "launch-container" }
const _hoisted_13 = { class: "activity-container" }
const _hoisted_14 = { class: "activity-container_header" }
const _hoisted_15 = { class: "header-text" }
const _hoisted_16 = { class: "header-calender" }
const _hoisted_17 = { class: "icon-container" }
const _hoisted_18 = { class: "activity-container_body" }
const _hoisted_19 = { class: "person-activity-container" }
const _hoisted_20 = { class: "person-activity_header" }
const _hoisted_21 = { class: "person-activity_body" }
const _hoisted_22 = { class: "residents" }
const _hoisted_23 = { class: "memories" }
const _hoisted_24 = { class: "hours" }
const _hoisted_25 = { class: "playlists" }
const _hoisted_26 = { class: "establishment-activity-container" }
const _hoisted_27 = { class: "establishment-activity_header" }
const _hoisted_28 = { class: "establishment-activity_body" }
const _hoisted_29 = { class: "chart-container" }
const _hoisted_30 = { class: "chart" }
const _hoisted_31 = { class: "text-container" }
const _hoisted_32 = { class: "chart-container" }
const _hoisted_33 = { class: "chart" }
const _hoisted_34 = { class: "text-container" }
const _hoisted_35 = { class: "chart-container" }
const _hoisted_36 = { class: "chart" }
const _hoisted_37 = { class: "text-container" }
const _hoisted_38 = { class: "questionnaire-parent" }
const _hoisted_39 = {
  key: 0,
  class: "questionnairePopup"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Confirm = _resolveComponent("Confirm")!
  const _component_Header = _resolveComponent("Header")!
  const _component_FavoriteCard = _resolveComponent("FavoriteCard")!
  const _component_NotificationButton = _resolveComponent("NotificationButton")!
  const _component_LaunchButton = _resolveComponent("LaunchButton")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ActivityCard = _resolveComponent("ActivityCard")!
  const _component_Chart = _resolveComponent("Chart")!
  const _component_QuestionnaireModal = _resolveComponent("QuestionnaireModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isFirstConnectionPopup)
        ? (_openBlock(), _createBlock(_component_Confirm, {
            key: 0,
            data: _ctx.firstConnectionPopupData
          }, {
            alertBody: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.firstConnectionMessage')), 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.firstConnectionConfirm')), 1)
              ])
            ]),
            _: 1
          }, 8, ["data"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Header, {
            title: _ctx.$t('views.dashboard.profile.myMusicalDna'),
            "bg-color": "#ffff",
            "title-color": "#36AEC1",
            "title-align": "flex-start"
          }, null, 8, ["title"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_FavoriteCard, {
              title: _ctx.$t('views.dashboard.profile.myMusicalStyles'),
              "no-item-text": _ctx.$t('views.dashboard.profile.noFavoriteGenre'),
              items: _ctx.favoriteGenresItems,
              "logo-size": "3.5em",
              "item-type": _ctx.QuestionnaireType.Genre,
              "on-edit": 
                            () => {
                                _ctx.onEdit(_ctx.QuestionnaireType.Genre);
                            }
                        
            }, null, 8, ["title", "no-item-text", "items", "item-type", "on-edit"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_FavoriteCard, {
              title: _ctx.$t('views.dashboard.profile.myFavoritePeriods'),
              "no-item-text": _ctx.$t('views.dashboard.profile.noFavoritePeriod'),
              items: _ctx.favoritePeriodsItems,
              "item-type": _ctx.QuestionnaireType.Period,
              "logo-size": "3.5em",
              "on-edit": 
                            () => {
                                _ctx.onEdit(_ctx.QuestionnaireType.Period);
                            }
                        
            }, null, 8, ["title", "no-item-text", "items", "item-type", "on-edit"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_FavoriteCard, {
              title: _ctx.$t('views.dashboard.profile.myFavoriteArtists'),
              "no-item-text": _ctx.$t('views.dashboard.profile.noFavoriteArtist'),
              items: _ctx.favoriteArtistsItems,
              "logo-size": "8em",
              "item-type": _ctx.QuestionnaireType.Artist,
              "on-edit": 
                            () => {
                                _ctx.onEdit(_ctx.QuestionnaireType.Artist);
                            }
                        
            }, null, 8, ["title", "no-item-text", "items", "item-type", "on-edit"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_FavoriteCard, {
              title: _ctx.$t('views.dashboard.profile.myLastFavoriteSongs'),
              "no-item-text": _ctx.$t('views.dashboard.profile.noFavoriteSong'),
              items: _ctx.favoriteTrackItems,
              "logo-size": "8em",
              "item-type": _ctx.QuestionnaireType.Track,
              "on-edit": 
                            () => {
                                _ctx.onEdit(_ctx.QuestionnaireType.Track);
                            }
                        
            }, null, 8, ["title", "no-item-text", "items", "item-type", "on-edit"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_NotificationButton, {
              text: _ctx.$t('generic.survey'),
              logo: "fa-user",
              "on-click": 
                            () => {
                                _ctx.onEdit(_ctx.QuestionnaireType.List);
                            }
                        ,
              count: _ctx.newStaffQuestionnaireCount
            }, null, 8, ["text", "on-click", "count"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_LaunchButton, {
              text: _ctx.$t('views.dashboard.profile.launchASession'),
              "on-click": () => _ctx.$router.push('/session')
            }, null, 8, ["text", "on-click"])
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_Header, {
              title: _ctx.$t('views.dashboard.profile.dashboard'),
              "bg-color": "#ebf3f5",
              "title-color": "#36AEC1",
              "title-align": "flex-start"
            }, null, 8, ["title"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-calendar-day" })
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("span", null, "3 " + _toDisplayString(_ctx.$t('generic.month')), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.yourActivity')), 1)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_ActivityCard, {
                  text: _ctx.$t('views.dashboard.profile.residentsStimulated'),
                  digits: _ctx.totalResidents
                }, null, 8, ["text", "digits"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_ActivityCard, {
                  text: _ctx.$t('views.dashboard.profile.collectedMemories'),
                  digits: _ctx.totalMemories
                }, null, 8, ["text", "digits"])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_ActivityCard, {
                  text: _ctx.$t('views.dashboard.profile.stimulationHours'),
                  digits: _ctx.totalHours
                }, null, 8, ["text", "digits"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_ActivityCard, {
                  text: _ctx.$t('views.dashboard.profile.sharedPlaylists'),
                  digits: _ctx.totalPlaylists
                }, null, 8, ["text", "digits"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.activitiesOfTheEstablishment')), 1)
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_component_Chart, {
                    value: _ctx.activeResidents,
                    height: "auto",
                    width: "auto"
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.activeResidents')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createVNode(_component_Chart, {
                    value: _ctx.wellnessRate,
                    height: "auto",
                    width: "auto"
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.wellnessRate')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("div", _hoisted_36, [
                  _createVNode(_component_Chart, {
                    value: _ctx.activeRelatives,
                    height: "auto",
                    width: "auto"
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.profile.activeRelatives')), 1)
                ])
              ])
            ])
          ])
        ])
      ], 512), [
        [_vShow, false]
      ])
    ]),
    _createElementVNode("div", _hoisted_38, [
      (_ctx.showQuestionnairePopup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
            _createVNode(_component_QuestionnaireModal, {
              "on-cancel": _ctx.closePopup,
              data: _ctx.questionnaireData,
              isModal: true,
              "on-submit": _ctx.onSubmitQuestionnaire,
              "open-result": _ctx.isPopupFinalResult,
              "check-answered-questionnaire": _ctx.checkAnsweredQuestionnaire,
              "on-row-click": _ctx.onOpenEval,
              "open-list-view": _ctx.onEdit
            }, {
              headerLeftButton: _withCtx(() => [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closePopup && _ctx.closePopup(...args))),
                  class: "close-btn"
                }, "X")
              ]),
              _: 1
            }, 8, ["on-cancel", "data", "on-submit", "open-result", "check-answered-questionnaire", "on-row-click", "open-list-view"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}