import { api, melovieApiPrefix } from '@/api';
import {
    AddPartFavResponse,
    AddStaffFavResponse,
    FavoritesPart,
    FavoritesStaff,
    GetPartFavorite,
    GetStaffFavorite,
    IdPartFav,
    IdStaffFav,
    PartAddFavoriteReqBody,
    PartFavorite,
    StaffAddFavoriteReqBody,
    StaffFavorite,
} from '@/helper/interface/favorite.interface';

/**
 * Get participant favorites from API
 *
 * @param { GetPartFavorite } reqBody
 * @return {*}  {Promise<PartFavorite[]>}
 */

export const getParticipantFavoritesApi = async (reqBody: GetPartFavorite): Promise<FavoritesPart> => {
    const result = await api.post(`${melovieApiPrefix}/participant/favorite/list`, reqBody);
    return result.data;
};

/**
 * Add a favorite of a participant to the database
 *
 * @param { PartAddFavoriteReqBody } reqBody
 * @return {*}  {Promise<AddPartFavResponse[]>}
 */
export const addParticipantFavoriteApi = async (reqBody: PartAddFavoriteReqBody): Promise<AddPartFavResponse[]> => {
    const result = await api.post(`${melovieApiPrefix}/participant/favorite/add`, reqBody);
    return result.data;
};

/**
 * Delete a favorite of a participant
 *
 * @param {PartFavorite} reqBody
 * @return {*}  {Promise<IdPartFav>}
 */
export const deleteParticipantFavoriteApi = async (reqBody: PartFavorite): Promise<IdPartFav> => {
    const result = await api.post(`${melovieApiPrefix}/participant/favorite/del`, reqBody);
    return result.data;
};

/**
 * Add a hate of a participant
 *
 * @param {PartFavorite} reqBody
 * @return {*}  {Promise<IdPartFav>}
 */
export const addParticipantHateApi = async (reqBody: PartFavorite): Promise<IdPartFav> => {
    const result = await api.post(`${melovieApiPrefix}/participant/favorite/hate`, reqBody);
    return result.data;
};

/**
 * Get staff favorites from API
 *
 * @param {GetStaffFavorite} reqBody
 * @return {*}  {Promise<FavoritesStaff>}
 */
export const getStaffFavoritesApi = async (reqBody: GetStaffFavorite): Promise<FavoritesStaff> => {
    const result = await api.post(`${melovieApiPrefix}/staff/favorite/list`, reqBody);
    return result.data;
};

/**
 * Add a favorite of a staff
 *
 * @param {StaffAddFavoriteReqBody} reqBody
 * @return {*}  {Promise<AddStaffFavResponse>}
 */
export const addStaffFavoriteApi = async (reqBody: StaffAddFavoriteReqBody): Promise<AddStaffFavResponse> => {
    const result = await api.post(`${melovieApiPrefix}/staff/favorite/add`, reqBody);
    return result.data;
};

/**
 * Delete a favorite of a staff
 *
 * @param {StaffFavorite} reqBody
 * @return {*}  {Promise<IdStaffFav>}
 */
export const deleteStaffFavoriteApi = async (reqBody: StaffFavorite): Promise<IdStaffFav> => {
    const result = await api.post(`${melovieApiPrefix}/staff/favorite/del`, reqBody);
    return result.data;
};

/**
 * Add a hate of a staff
 *
 * @param {StaffFavorite} reqBody
 * @return {*}  {Promise<IdStaffFav>}
 */
export const addStaffHateApi = async (reqBody: StaffFavorite): Promise<IdStaffFav> => {
    const result = await api.post(`${melovieApiPrefix}/staff/favorite/list`, reqBody);
    return result.data;
};
