
import { defineComponent, onBeforeMount, ref } from 'vue';
import MusicPlayer from '@/components/music_player/MusicPlayerComponent.vue';
import { musicPlayerData } from '@/services/sharedService';
import { Track } from '@/helper/interface/music/track.interface';
export default defineComponent({
    name: 'MusicPlayerView',
    components: { MusicPlayer },
    setup() {
        const audioData = ref<Track[]>([]);
        onBeforeMount(() => (audioData.value = musicPlayerData.value));
        return {
            audioData,
        };
    },
});
