import { delPlaylistTracksApi, getPlaylistApi } from '@/api/playlistApi';
import { UserType } from '@/helper/enums/melovie.enum';
import {
    IdPlayList,
    IdTracksPlayList,
    PlayListStaff,
    PlayListTracks,
    Playlist,
} from '@/helper/interface/playlist.interface';
import { currentUserType, staffPlaylists, participantPlaylists } from './sharedService';

/**
 * get playlist information
 *
 * @param {number} playlistId playlist id
 * @return { Promise<Playlist> } a playlist with tracks
 */
export const getPlaylistDetails = (playlistId: number): Promise<Playlist> => {
    return new Promise<Playlist>((resolve, reject) => {
        const reqBody: IdPlayList = {
            play_id: playlistId,
        };
        getPlaylistApi(reqBody)
            .then((data: Playlist) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/**
 * delete track from playlist
 *
 * @param {number} playlistId playlist id
 * @param {number[]} trackIds track ids
 * @return { Promise<IdTracksPlayList[]> } a playlist with tracks
 */
export const deleteTracksFromPlaylist = (playlistId: number, trackIds: number[]): Promise<IdTracksPlayList[]> => {
    return new Promise<IdTracksPlayList[]>((resolve, reject) => {
        const reqBody: PlayListTracks = {
            play_id: playlistId,
            tracks: trackIds,
        };
        delPlaylistTracksApi(reqBody)
            .then((data: IdTracksPlayList[]) => {
                // remove from cache also
                const userPlaylist: PlayListStaff | undefined = (
                    currentUserType.value === UserType.Staff ? staffPlaylists.value : participantPlaylists.value
                ).find((playlist: PlayListStaff) => playlist.play_id === playlistId);

                if (userPlaylist?.tracks) {
                    userPlaylist.tracks = userPlaylist.tracks.filter((trackId: number) => !trackIds.includes(trackId));
                }
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
