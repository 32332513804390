import { api, musicApiPrefix } from '@/api';
import {
    GetTrack,
    GetTracksWithCriteria,
    SearchTracksRequestBody,
    Track,
} from '@/helper/interface/music/track.interface';

/**
 * Get track
 *
 * @return {*}  {Promise<Track[]>}
 */
export const getTrackApi = async (reqBody: GetTrack): Promise<Track[]> => {
    const result = await api.post(`${musicApiPrefix}/track/get`, reqBody);
    return result.data;
};

/**
 * Get all tracks
 *
 * @return {*}  {Promise<Track[]>}
 */
export const getAllTracksApi = async (reqBody: GetTracksWithCriteria): Promise<Track[]> => {
    const result = await api.post(`${musicApiPrefix}/track/list`, reqBody);
    return result.data;
};

/**
 * Search tracks
 *
 * @return {*}  {Promise<Track[]>}
 */
export const searchTracksApi = async (reqBody: SearchTracksRequestBody): Promise<Track[]> => {
    const result = await api.post(`${musicApiPrefix}/search`, reqBody);
    return result.data;
};
