
import { QuestionnaireType } from '@/helper/enums/melovie.enum';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { Period } from '@/helper/interface/music/period.interface';
import { allPeriods } from '@/services/sharedService';
import { defineComponent, PropType, ref } from 'vue';
import ImageComponent from '../ImageComponent.vue';

/**
 * Activity card component containing text and numbers of current page.
 * @param {array} selectedData an array to show the selected period icons and texts
 * @param {Function} onSubmit  function to execute codes when an user selects period and submit
 */
export default defineComponent({
    name: 'PeriodList',
    inheritAttrs: false,
    props: {
        selectedData: Array as PropType<Period[]>,
        onSubmit: Function as PropType<(period: Period) => void>,
    },
    setup(props: any) {
        // Periods
        const periodData = ref<Period[]>(allPeriods.value);
        // Order by date
        periodData.value.sort((a, b) => (a.muspe_start_year ?? -1) - (b.muspe_start_year ?? -1));
        /**
         * Checking if the period received as props
         */
        const isPeriodSelected = (period: Period): boolean => {
            return props.selectedData?.find((checkPeriod: Period) => checkPeriod.muspe_id === period.muspe_id)
                ? true
                : false;
        };
        return {
            QuestionnaireType,
            periodData,
            isPeriodSelected,
            ref: AssetSourceEnum.Reference,
        };
    },
    components: { ImageComponent }
});
