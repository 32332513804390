
import { BubbleListData } from '@/helper/interface/ui/components/bubbleList';
import { PropType, ref } from 'vue';

/**
 * Bubble list contain list of string as bubble view.
 *
 * @param {string[]} dataList list of string type data.
 * @param {string} bubbleWidth width of bubbles.
 * @param {string} maxHeight maximum height of bubbles.
 * @param {Function} onSelect call when user select any data.
 */
export default {
    name: 'BubbleListComponent',
    props: {
        dataList: { type: Array as PropType<Array<BubbleListData>>, required: true },
        bubbleWidth: String,
        maxHeight: String,
        onSelect: Function as PropType<(data: BubbleListData) => void>,
    },
    setup() {
        const selectedDataId = ref<number>();
        return { selectedDataId };
    },
};
