import { Buffer } from 'buffer'
import axios from 'axios';
import { Configuration } from '@/helper/interface/configuration.interface';

const { localStorage } = window;
let expiration = (10 * 60000)
const config:Configuration = {
  apiUrl: '',
  apiKey: '',
  apiVersion: 1,
  apiToken: ''
}


export const getStoreConfig = async (): Promise<Configuration>  => {
  if (config?.apiUrl === '' ) {
    const result = await axios.get('/config.json');
    config.apiUrl = result.data.apiUrl;
    config.apiVersion = result.data.apiVersion;
    config.apiKey = result.data.apiKey;
    expiration = result.data.expiration;
  }
  if (!config.apiToken) config.apiToken = getAuthToken()
  return config;
};

function b64e(str: string): string {
  if (str) return Buffer.from(str).toString('base64')
  return ""
}

function b64d(str: string): string {
  return Buffer.from(str, 'base64').toString()
}

Storage.prototype.setObjectHash = function (key: string, myObject: any) {
  this.setItem(key, b64e(JSON.stringify(myObject)))
}

Storage.prototype.getObjectHash = function (key: string): any {
  const svalue = this.getItem(key)
  if (svalue) {
    return JSON.parse(b64d(svalue))
  }
}

function refreshTime(ttl: number) {
  const item = localStorage.getObjectHash('data') || {}
  const now = new Date()
  item.temp = now.getTime() + ttl
  localStorage.setObjectHash('data', item)
}

function setWithExpiry(key: string, value :any, ttl: number) {
  const item = localStorage.getObjectHash('data') || {}
  const now = new Date()
  item.temp = now.getTime() + ttl
  item[key] = value
  localStorage.setObjectHash('data', item)
}

function getWithExpiry(key: string) {
  const item = localStorage.getObjectHash('data')
  // if the item doesn't exist, return null
  if (!item) {
    return null
  }
  const value = item[key]
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.temp) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem('data')
    return null
  }

  // actualisation du temps restant après chaque interrogation
  localStorage.setObjectHash('data', item)
  return value
}

export function refresh() {
  refreshTime(expiration)
}

export function clear() {
  setAuthToken('');
}
/**
 * Save auth token in local storage
 *
 * @param {string} newToken authentication token from api
 * @return {*} auth token
 */
export function setAuthToken(newToken: string): any {
  setWithExpiry('token', newToken, expiration)
}

export function getAuthToken():string {
  return getWithExpiry('token') || ''
}