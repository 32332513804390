import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueApexCharts from 'vue3-apexcharts';
import '@/fas';

// Creating and Defining new app and essentials.
const app = createApp(App).use(i18n).use(router).use(VueApexCharts);
// Initialize font awesome icon component
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
