
import { PropType, onMounted, ref, watch } from 'vue';
import { itemsPerPageMusicalListChooseComponent, maxPagesMusicalListChooseComponent } from '@/helper/utils/constants';
import { PaginatorAlignmentType } from '@/helper/enums/ui.enum';

/**
 * Paginate a list of items
 *
 * @param {any[]} totalItems Total item list to paginate
 * @param {any[]} currentItems Item list of current page
 * @param {Number} itemsPerPage items count per page
 * @param {Number} currentPage current selected page
 * @param {PaginatorAlignmentType} alignment Pagination alignment
 * @param {(currPage: number) => any} getCurrentPage get current selected page
 */
export default {
    name: 'PaginatorComponent',
    props: {
        totalItems: { type: Array, required: true },
        currentItems: { type: Array, required: true },
        itemsPerPage: { type: Number, default: itemsPerPageMusicalListChooseComponent },
        currentPage: { type: Number, default: 1 },
        alignment: { type: String as PropType<PaginatorAlignmentType>, default: PaginatorAlignmentType.Center },
        getCurrentPage: Function as PropType<(currPage: number) => any>,
    },
    setup(props: any) {
        const selectedCurrentPage = ref<number>(props.currentPage);
        const displayedPages = ref<string[]>([]);
        const totalPages = ref<number>(0);
        /**
         * Page number are being selected
         *
         * @returns {string[]} page list
         */
        const generatePages = (): string[] => {
            const pages: string[] = [];
            // fill pages to display in pagination
            if (totalPages.value <= maxPagesMusicalListChooseComponent)
                for (let i = 1; i <= totalPages.value; i++) pages.push(i.toString());
            else {
                const middleIndex = Math.ceil(maxPagesMusicalListChooseComponent / 2); // find pagination mid
                if (selectedCurrentPage.value <= middleIndex + 1) {
                    // managing case where selected page lies at left side of pagination
                    for (let i = 1; i <= middleIndex + 1; i++) pages.push(i.toString());
                    pages.push('...');
                    pages.push((totalPages.value - 1).toString());
                    pages.push(totalPages.value.toString());
                } else if (selectedCurrentPage.value >= totalPages.value - middleIndex) {
                    // managing case where selected page lies at right side of pagination
                    pages.push('1');
                    pages.push('2');
                    pages.push('...');
                    for (let i = totalPages.value - middleIndex; i <= totalPages.value; i++) pages.push(i.toString());
                } else {
                    // managing case where selected page lies at middle of pagination
                    pages.push('1');
                    pages.push('2');
                    pages.push('...');
                    pages.push((selectedCurrentPage.value - 1).toString());
                    pages.push(selectedCurrentPage.value.toString());
                    pages.push((selectedCurrentPage.value + 1).toString());
                    pages.push('...');
                    pages.push((totalPages.value - 1).toString());
                    pages.push(totalPages.value.toString());
                }
            }
            return pages;
        };

        /**
         * Generating the new array to be shown in pagination pages
         *
         * @param array any[]
         * @param currentPage number
         * @param itemsPerPage number
         */
        const paginateArray = (array: any[], currentPage: number, itemsPerPage: number = props.itemsPerPage) => {
            const startIndex = Math.ceil((currentPage - 1) * itemsPerPage);
            const endIndex = Math.ceil(startIndex + itemsPerPage);
            const newArray = array;
            return newArray.slice(startIndex, endIndex);
        };

        /**
         * Handling onClick event of page numbers
         *
         * @param page number
         */
        const handlePaginationItem = (pageStr: string) => {
            // make sure click are not on "..."
            if (pageStr !== '...') {
                switch (pageStr) {
                    case 'PREV':
                        if (selectedCurrentPage.value > 1) selectedCurrentPage.value--;
                        break;

                    case 'NEXT':
                        if (selectedCurrentPage.value < totalPages.value) selectedCurrentPage.value++;
                        break;

                    default:
                        const page: number = parseInt(pageStr);
                        selectedCurrentPage.value = page;
                        break;
                }
                displayedPages.value = generatePages(); // generate pages
                sentResult(paginateArray(props.totalItems, selectedCurrentPage.value));
            }
        };

        /**
         * Calculate total pages depending upon item received
         *
         * @returns {number} total pages count
         */
        const calculateTotalPages = (): number => {
            const pages = Math.ceil(props.totalItems?.length / props.itemsPerPage);
            if (pages > 0 && pages < selectedCurrentPage.value) selectedCurrentPage.value = pages;
            return pages;
        };

        /**
         * Sent current page items to parent
         *
         */
        const sentResult = (result: any[]) => {
            (props.currentItems as any[]).length = 0;
            (props.currentItems as any[]).push(...result);
        };

        /**
         * Regenerate the pagination
         *
         */
        const reload = () => {
            totalPages.value = calculateTotalPages(); // calculate total pages
            displayedPages.value = generatePages(); // generate pages
            sentResult(paginateArray(props.totalItems, selectedCurrentPage.value)); // sent current page items
        };

        if (props.getCurrentPage) {
            // sent current selected page
            watch(
                () => selectedCurrentPage.value,
                () => props.getCurrentPage(selectedCurrentPage.value)
            );
        }

        // observe received items, and do necessary steps
        watch(
            () => props.totalItems,
            () => reload()
        );

        // load for first time
        onMounted(() => reload());
        return {
            totalPages,
            displayedPages,
            selectedCurrentPage,
            handlePaginationItem,
            PaginatorAlignmentType,
        };
    },
};
