import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45c93e81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "session_main-container" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "filter-container" }
const _hoisted_4 = { class: "list-container" }
const _hoisted_5 = { class: "title-container" }
const _hoisted_6 = { class: "launch-container" }
const _hoisted_7 = { class: "selection-container" }
const _hoisted_8 = { class: "text-container" }
const _hoisted_9 = {
  key: 0,
  class: "selected-container"
}
const _hoisted_10 = { class: "launch-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Filter = _resolveComponent("Filter")!
  const _component_BubbleList = _resolveComponent("BubbleList")!
  const _component_LaunchButton = _resolveComponent("LaunchButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        title: _ctx.$t('views.dashboard.session.participantSelection'),
        "bg-color": "#ffff",
        "title-color": "#36AEC1",
        "title-align": "flex-start"
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Filter, {
        locations: _ctx.locations,
        groups: _ctx.groups,
        "get-filter-data": _ctx.filterData,
        "on-reset": _ctx.resetFilter
      }, {
        title: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.filters')), 1)
        ]),
        _: 1
      }, 8, ["locations", "groups", "get-filter-data", "on-reset"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.session.choseParticipant')), 1)
      ]),
      _createVNode(_component_BubbleList, {
        "data-list": _ctx.userNames,
        "on-select": _ctx.selectUser,
        "max-height": "100%"
      }, null, 8, ["data-list", "on-select"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('views.dashboard.session.selectedParticipant')), 1)
        ]),
        (_ctx.selectedUser)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("span", null, _toDisplayString(_ctx.selectedUser?.par_firstname && _ctx.selectedUser?.par_lastname
                            ? _ctx.selectedUser.par_firstname + ' ' + _ctx.selectedUser.par_lastname
                            : ''), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_LaunchButton, {
          text: _ctx.$t('views.dashboard.session.launchTheSession'),
          onClick: _ctx.onStartSession
        }, null, 8, ["text", "onClick"])
      ])
    ])
  ]))
}