
import { Souvenir } from '@/helper/interface/souvenir.interface';
import { PropType } from 'vue';

/**
 * Memory component to show memory
 *
 * @param {Souvenir} memory memory title.
 * @param {(musicId: number) => any} onPlay asset data.
 */
export default {
    name: 'MemoryComponent',
    props: {
        memory: { type: Object as PropType<Souvenir>, required: true },
        onPlay: { type: Function as PropType<(musicId: number) => any> },
    },
};
