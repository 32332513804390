import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-348e051f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "period-body-container" }
const _hoisted_2 = { class: "period-parent-container" }
const _hoisted_3 = { class: "text-first-content" }
const _hoisted_4 = { class: "filter-periods-text" }
const _hoisted_5 = { class: "first-content-body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "selected-fav-periods-section" }
const _hoisted_8 = { class: "selected-period-as-btns" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "img-btn-content" }
const _hoisted_11 = { class: "image-parent" }
const _hoisted_12 = { class: "img-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeriodList = _resolveComponent("PeriodList")!
  const _component_ImageComponent = _resolveComponent("ImageComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('views.dashboard.questionnaire.filterYourPeriods')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_PeriodList, {
            "on-submit": _ctx.onSubmit,
            "selected-data": _ctx.selectedData
          }, null, 8, ["on-submit", "selected-data"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('views.dashboard.questionnaire.summaryOfFavPeriods')), 1),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedData, (period, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                onClick: ($event: any) => (_ctx.onSubmit && _ctx.onSubmit(period))
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(_component_ImageComponent, {
                      assId: period.muspe_ass_id ?? -1,
                      source: _ctx.ref,
                      class: "img"
                    }, null, 8, ["assId", "source"])
                  ]),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('generic.years')) + " " + _toDisplayString(period.muspe_label), 1)
                ])
              ], 8, _hoisted_9))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}