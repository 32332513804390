import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e2ffee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart_main-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_apexchart, {
      type: "radialBar",
      height: $props.height,
      options: $setup.chartOptions,
      series: $setup.series
    }, null, 8, ["height", "options", "series"])
  ]))
}