
import { Eval } from '@/helper/interface/eval.interface';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
    name: 'QuestionnaireListComponent',
    props: {
        data: { type: Array as PropType<Eval[]>, required: true },
        checkAnswered: Function as PropType<(evalId: number) => boolean>,
        onRowClick: Function as PropType<(evalId: number) => void>,
    },
});
