import { api, melovieApiPrefix } from '@/api';
import { Eval, EvalLayout, IdEval, IdResultEval, ResultEval } from '@/helper/interface/eval.interface';

/**
 * Get all eval
 *
 * @return {*}  {Promise<Eval[]>}
 */
export const getAllEvalApi = async (): Promise<Eval[]> => {
    const result = await api.post(`${melovieApiPrefix}/eval/getall`, {});
    return result.data;
};

/**
 * Get eval layout
 *
 * @param {IdEval} eval_id
 * @return {*}  {Promise<EvalLayout[]>}
 */
export const getEvalLayoutApi = async (reqBody: IdEval): Promise<EvalLayout[]> => {
    const result = await api.post(`${melovieApiPrefix}/eval/getlayout`, reqBody);
    return result.data;
};

/**
 * Set result
 *
 * @param {ResultEval} reqBody
 * @return {*}  {Promise<IdResultEval[]>}
 */
export const setEvalResultApi = async (reqBody: ResultEval): Promise<IdResultEval> => {
    const result = await api.post(`${melovieApiPrefix}/eval/setresult`, reqBody);
    return result.data;
};
