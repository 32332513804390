
/**
 * Header component containing title and header buttons of current page.
 *
 * @param {string} title title to show on middle of header.
 * @param {string} bgColor background color of header.
 * @param {string} titleColor title color of header.
 * @param {string} height height of header.
 * @param {string} fontSize title font size.
 * @param {string} titleAlign text align of title - left,center,right.
 */
export default {
    name: 'HeaderComponent',
    props: {
        title: { type: String, required: true },
        bgColor: String,
        titleColor: String,
        fontSize: String,
        height: String,
        titleAlign: String,
    },
};
