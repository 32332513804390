import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-113c53d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "sidebar-container" }
const _hoisted_3 = { class: "page-container" }
const _hoisted_4 = { class: "alert-text-container" }
const _hoisted_5 = { class: "confirm-span" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Confirm = _resolveComponent("Confirm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Sidebar, {
          "menu-items": _ctx.menuItems,
          "user-name": 
                    _ctx.currentUser
                        ? ` ${_ctx.currentUser?.par_firstname} ${_ctx.currentUser?.par_lastname}`
                        : `${_ctx.$t('generic.userName')}`
                ,
          "profile-photo": require('@/assets/profiles/avatar.png')
        }, null, 8, ["menu-items", "user-name", "profile-photo"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_view)
          ]),
          _: 1
        }))
      ])
    ]),
    (_ctx.isReportPopup)
      ? (_openBlock(), _createBlock(_component_Confirm, {
          key: 0,
          data: _ctx.reportPopupData
        }, {
          alertBody: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('views.dashboard.session.thanksForSession')), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('views.dashboard.session.writeAReport')), 1)
            ])
          ]),
          _: 1
        }, 8, ["data"]))
      : _createCommentVNode("", true)
  ], 64))
}