import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-453e06f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "count-container"
}
const _hoisted_2 = { class: "count" }
const _hoisted_3 = { class: "logo-container" }
const _hoisted_4 = { class: "text-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notification-button_main-container", !$props.onClick ? 'disabled' : 'cursor-pointer']),
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.onClick && $props.onClick()))
  }, [
    ($props.count && $props.count > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", null, _toDisplayString($props.count), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_font_awesome_icon, { icon: $props.logo }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", null, _toDisplayString($props.text), 1)
    ])
  ], 2))
}