import { addStaffFavoriteApi, addStaffHateApi, deleteStaffFavoriteApi, getStaffFavoritesApi } from '@/api/favoriteApi';
import { addStaffDislikeApi, addStaffLikeApi } from '@/api/likeApi';
import { addStaffPlaylistApi, getStaffPlaylistsApi, setPlaylistApi } from '@/api/playlistApi';
import { getStaffApi, getStaffProfileApi, setStaffProfileApi } from '@/api/staffApi';
import {
    AddStaffFavResponse,
    FavoritesStaff,
    GetStaffFavorite,
    IdStaffFav,
    StaffAddFavoriteReqBody,
    StaffFavorite,
} from '@/helper/interface/favorite.interface';
import { AnsweredLayout } from '@/helper/interface/generic.interface';
import { IdStaffLike, StaffLike } from '@/helper/interface/like.interface';
import {
    IdPlayList,
    IdStaffPlayList,
    PlayListStaff,
    PlayListStaffId,
    PlaylistReqBody,
} from '@/helper/interface/playlist.interface';
import { IdStaff, IdStaffProfile, ProfileStaff, ProfileStaffRequest, Staff } from '@/helper/interface/staff.interface';
import { loadUserFavoriteData, sortUserFavorite } from './genericService';
import { staffPlaylists, staffProfile } from './sharedService';
import { UserType } from '@/helper/enums/melovie.enum';
import { addPlaylistTracks } from './music/trackService';

/**
 * Fetch all staffs from the API
 *
 * @returns {Promise<Staff[]>} list of staff.
 */
export const getAllStaffs = () => {
    return new Promise<Staff[]>((resolve, reject) => {
        getStaffApi()
            .then((data: Staff[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Fetch staff profile from the API
 *
 * @returns {Promise<ProfileStaff>} list of staff.
 */
export const getStaffProfile = (staffId: number) => {
    return new Promise<ProfileStaff>((resolve, reject) => {
        getStaffProfileApi(staffId)
            .then((data: ProfileStaff) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * update staff profile to the database
 *
 * @param {number[]} periods staff favorite periods.
 * @param {number[]} genres staff favorite genres.
 * @returns {Promise<IdStaffProfile>} staff profile id.
 */
export const setStaffProfile = (
    staffId: number,
    periods?: number[],
    genres?: number[],
    artists?: number[],
    tracks?: number[],
    answeredLayouts?: AnsweredLayout[]
) => {
    let currentProfile = staffProfile.value ?? {};
    if (typeof currentProfile === 'string') currentProfile = {};
    if (periods) currentProfile.periods = periods;
    if (genres) currentProfile.genres = genres;
    if (artists) currentProfile.artists = artists;
    if (tracks) currentProfile.tracks = tracks;
    if (answeredLayouts)
        currentProfile.answeredLayouts?.length
            ? currentProfile.answeredLayouts.push(...answeredLayouts)
            : (currentProfile.answeredLayouts = answeredLayouts);
    const reqBody: ProfileStaffRequest = {
        staf_id: staffId,
        data: currentProfile,
    };
    return new Promise<IdStaffProfile>((resolve, reject) => {
        setStaffProfileApi(reqBody)
            .then((data: IdStaffProfile) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Fetch staff favorites from the API
 *
 * @returns {Promise<FavoritesStaff>} list of staff.
 */
export const getStaffFavoritesAndHates = (staffId: number, like: boolean = true, unlike: boolean = false) => {
    return new Promise<FavoritesStaff>((resolve, reject) => {
        const reqBody: GetStaffFavorite = {
            staf_id: staffId,
            like,
            unlike,
        };
        getStaffFavoritesApi(reqBody)
            .then((data: FavoritesStaff) => {
                resolve(sortUserFavorite(data));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add staff favorite
 *
 * @returns {Promise<AddStaffFavResponse>} id staff favorite.
 */
export const setStaffFavorite = (staffId: number, trackIds?: number[], artistIds?: number[], albumIds?: number[]) => {
    return new Promise<AddStaffFavResponse>((resolve, reject) => {
        const reqBody: StaffAddFavoriteReqBody = {
            staf_id: staffId,
            favorites: [],
        };
        if (trackIds) {
            reqBody.favorites.push(
                ...trackIds.map((id: number) => {
                    return { trk_id: id };
                })
            );
        }
        if (artistIds) {
            reqBody.favorites.push(
                ...artistIds.map((id: number) => {
                    return { art_id: id };
                })
            );
        }
        if (albumIds) {
            reqBody.favorites.push(
                ...albumIds.map((id: number) => {
                    return { alb_id: id };
                })
            );
        }
        addStaffFavoriteApi(reqBody)
            .then(async (data: AddStaffFavResponse) => {
                await loadUserFavoriteData(UserType.Staff, true);
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Delete staff favorite
 *
 * @returns {Promise<IdStaffFav>} id staff favorite.
 */
export const removeStaffFavorite = (staffId: number, trackId?: number, artistId?: number, albumId?: number) => {
    return new Promise<IdStaffFav>((resolve, reject) => {
        const reqBody: StaffFavorite = {
            staf_id: staffId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        deleteStaffFavoriteApi(reqBody)
            .then(async (data: IdStaffFav) => {
                await loadUserFavoriteData(UserType.Staff, true);
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add staff hate
 *
 * @returns {Promise<IdStaffFav>} id staff hate.
 */
export const setStaffHate = (staffId: number, trackId?: number, artistId?: number, albumId?: number) => {
    return new Promise<IdStaffFav>((resolve, reject) => {
        const reqBody: StaffFavorite = {
            staf_id: staffId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        addStaffHateApi(reqBody)
            .then(async (data: IdStaffFav) => {
                await loadUserFavoriteData(UserType.Staff, true);
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Fetch staff's Playlists from the API
 *
 * @param {number} staffId staff id
 * @returns {Promise<PlayListStaff[]>} list of playlist.
 */
export const getStaffPlaylists = (staffId: number) => {
    return new Promise<PlayListStaff[]>((resolve, reject) => {
        const reqBody: IdStaff = {
            staf_id: staffId,
        };
        getStaffPlaylistsApi(reqBody)
            .then((data: PlayListStaff[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add staff's Playlist
 *
 * @param {number} staffId staff id
 * @param {string} playlistName name of the playlist
 * @param {number[]} trackIds track ids to add in the playlist
 * @returns {Promise<PlayListStaff[]>} list of playlist
 */
export const addStaffPlaylist = (staffId: number, playlistName: string, trackIds?: number[]) => {
    return new Promise<PlayListStaff[]>(async (resolve, reject) => {
        try {
            const reqBodyAddPlaylist: PlaylistReqBody = {
                play_name: playlistName,
            };
            const playIdResponse: IdPlayList = await setPlaylistApi(reqBodyAddPlaylist);
            if (playIdResponse.play_id) {
                const reqBodyAddStaffPlaylist: PlayListStaffId = {
                    play_id: playIdResponse.play_id,
                    staf_id: staffId,
                };
                const playStaffIdResponse: IdStaffPlayList = await addStaffPlaylistApi(reqBodyAddStaffPlaylist);
                if (playStaffIdResponse.plays_id) {
                    // add to cache also
                    staffPlaylists.value.push({
                        play_id: playIdResponse.play_id,
                        play_name: playlistName,
                    });
                    if (trackIds?.length) {
                        await addPlaylistTracks(playIdResponse.play_id, trackIds);
                    }
                    resolve(staffPlaylists.value);
                }
            }
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Add staff like
 *
 * @returns {Promise<IdStaffLike>} id staff like.
 */
export const setStaffLike = (staffId: number, trackId?: number, artistId?: number, albumId?: number) => {
    return new Promise<IdStaffLike>((resolve, reject) => {
        const reqBody: StaffLike = {
            staf_id: staffId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        addStaffLikeApi(reqBody)
            .then((data: IdStaffLike) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add staff dislike
 *
 * @returns {Promise<IdStaffLike>} id staff dislike.
 */
export const setStaffDislike = (staffId: number, trackId?: number, artistId?: number, albumId?: number) => {
    return new Promise<IdStaffLike>((resolve, reject) => {
        const reqBody: StaffLike = {
            staf_id: staffId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        addStaffDislikeApi(reqBody)
            .then((data: IdStaffLike) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
