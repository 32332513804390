
import GenreList from '@/components/genre_list/GenreList.vue';
import PeriodList from '@/components/period_list/PeriodList.vue';
import { UserType } from '@/helper/enums/melovie.enum';
import { Genre } from '@/helper/interface/music/genre.interface';
import { Period } from '@/helper/interface/music/period.interface';
import { ProfileStaff } from '@/helper/interface/staff.interface';
import { getAllGenres } from '@/services/genericService';
import { allGenres, currentUserType, participantProfile, staffProfile } from '@/services/sharedService';
import { PropType, onBeforeMount, ref } from 'vue';
/**
 * Bubble list contain list of string as bubble view.
 *
 * @param {string[]} dataList list of string type data.
 */
export default {
    name: 'ResearchFilterComponent',
    components: {
        PeriodList,
        GenreList,
    },
    props: {
        selectedFavGenres: { type: Array as PropType<Genre[]>, default: [] },
        selectedGenres: { type: Array as PropType<Genre[]>, default: [] },
        selectedPeriods: { type: Array as PropType<Period[]>, default: [] },
    },
    setup(props: any) {
        const favoriteGenres = ref<Genre[]>([]);
        const genres = ref<Genre[]>([]);

        /**
         * handles user selected periods
         *
         * @param {Period} period
         */
        const onSelectPeriod = (period: Period) => {
            const selectedPeriod = (props.selectedPeriods as Period[]).find(
                (checkPeriod: Period) => checkPeriod.muspe_id === period.muspe_id
            );
            if (selectedPeriod) {
                // if already selected then remove
                const index = (props.selectedPeriods as Period[]).findIndex(
                    (checkPeriod: Period) => checkPeriod.muspe_id === period.muspe_id
                );
                if (index != -1) (props.selectedPeriods as Period[]).splice(index, 1);
            } else (props.selectedPeriods as Period[]).push(period); // else add
        };

        /**
         * handles user selected favorite genres
         *
         * @param {Genre} genre
         */
        const onSelectFavGenre = (genre: Genre) => {
            const selectedFavGenre = (props.selectedFavGenres as Genre[]).find(
                (checkGenre: Genre) => checkGenre.gmus_id === genre.gmus_id
            );
            if (selectedFavGenre) {
                // if already selected then remove
                const index = (props.selectedFavGenres as Genre[]).findIndex(
                    (checkGenre: Genre) => checkGenre.gmus_id === genre.gmus_id
                );
                if (index != -1) (props.selectedFavGenres as Genre[]).splice(index, 1);
            } else (props.selectedFavGenres as Genre[]).push(genre); // else add
        };

        /**
         * handles user selected genres
         *
         * @param {Genre} genre
         */
        const onSelectGenre = (genre: Genre) => {
            const selectedGenre = (props.selectedGenres as Genre[]).find(
                (checkGenre: Genre) => checkGenre.gmus_id === genre.gmus_id
            );
            if (selectedGenre) {
                // if already selected then remove
                const index = (props.selectedGenres as Genre[]).findIndex(
                    (checkGenre: Genre) => checkGenre.gmus_id === genre.gmus_id
                );
                if (index != -1) (props.selectedGenres as Genre[]).splice(index, 1);
            } else (props.selectedGenres as Genre[]).push(genre); // else add
        };

        onBeforeMount(async () => {
            if (!allGenres.value?.length) allGenres.value = await getAllGenres();
            const userProfile: ProfileStaff =
                currentUserType.value === UserType.Staff
                    ? staffProfile.value || {}
                    : currentUserType.value === UserType.Participant
                    ? participantProfile.value || {}
                    : ({} as ProfileStaff);
            allGenres.value.forEach((genre: Genre) => {
                if (userProfile.genres?.includes(genre.gmus_id)) favoriteGenres.value.push(genre);
                else genres.value.push(genre);
            });
        });
        return { favoriteGenres, genres, onSelectPeriod, onSelectFavGenre, onSelectGenre };
    },
};
