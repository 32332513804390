import { getAllArtistsApi, getArtistApi, getMultipleArtistsApi } from '@/api/music/artistApi';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { MusicalListItemType } from '@/helper/enums/ui.enum';
import { Artist, GetArtist, GetManyArtists } from '@/helper/interface/music/artist.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { getAssets } from '../assetsService';

/**
 * Get all artists.
 *
 * @returns {Promise<Artist[]>} list of artists.
 */
export const getAllArtists = () => {
    return new Promise<Artist[]>((resolve, reject) => {
        getAllArtistsApi()
            .then((data: Artist[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Get all artist as music list item.
 *
 * @returns {Promise<MusicalListItem[]>} list of artists.
 */
export const getAllArtistList = () => {
    return new Promise<MusicalListItem[]>(async (resolve, reject) => {
        try {
            const artists: Artist[] = await getAllArtists();
            const artistList: MusicalListItem[] = await Promise.all(
                artists.map(async (artist: Artist) => {
                    const result: MusicalListItem = {
                        id: artist.art_id,
                        title: artist.art_name || '',
                        description: artist.art_description || '',
                        asset: artist.ass_id ?? -1,
                        type: MusicalListItemType.Artist,
                    };
                    return result;
                })
            );
            resolve(artistList);
        } catch (error) {
            reject(error);
        }
    });
};

export const getArtist = (artistId: number) => {
    return new Promise<Artist>((resolve, reject) => {
        const reqBody: GetArtist = {
            art_id: artistId,
        };
        getArtistApi(reqBody)
            .then((data: Artist[]) => {
                resolve(data[0]);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getMultipleArtists = (artistIds: number[]) => {
    return new Promise<Artist[]>((resolve, reject) => {
        const reqBody: GetManyArtists = {
            ids: artistIds,
        };
        getMultipleArtistsApi(reqBody)
            .then((data: Artist[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
