import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-703b36bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "final-body-container" }
const _hoisted_2 = { class: "first-section" }
const _hoisted_3 = { class: "first-section-firstpart" }
const _hoisted_4 = { class: "first-section-secondpart" }
const _hoisted_5 = { class: "second-section" }
const _hoisted_6 = { class: "second-section-firstpart" }
const _hoisted_7 = { class: "disabled-fields" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (questionnaire, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "row"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(index + 1), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(questionnaire.eval_data?.title), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_ctx.getAnswerText(questionnaire)), 1)
            ])
          ]),
          _createElementVNode("div", {
            class: "svg-class",
            onClick: ($event: any) => (_ctx.onEdit && _ctx.onEdit(index - _ctx.data.length))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-pen" })
          ], 8, _hoisted_8)
        ])
      ]))
    }), 128))
  ]))
}