
import { PropType } from 'vue';

/**
 * Notification button component to show new notification alert.
 *
 * @param {string} logo logo path.
 * @param {string} text text of button.
 * @param {number} count notification count.
 * @param {function} onClick is the function to be triggered when clicked
 *
 */
export default {
    name: 'NotificationButton',
    props: {
        text: { type: String, required: true },
        logo: { type: String, required: true },
        count: Number,
        onClick: Function as PropType<() => any>,
    },
};
