
import Header from '@/components/header/HeaderComponent.vue';
import { defineComponent } from 'vue';
import QuestionnaireModal from '@/components/questionnaire_modal/QuestionnaireComponent.vue';
import { getQuestionnaireData } from '@/services/questionnaireService';
import { useQuestionnaire } from '@/composable/useQuestionnaire';
import { Eval, QuestionnaireData } from '@/helper/interface/eval.interface';
import { useRoute, useRouter } from 'vue-router';
import { allEval, isAlertOpened, isLoading, openAlertPopup } from '@/services/sharedService';
import { ConfirmType } from '@/helper/enums/ui.enum';
import { useI18n } from 'vue-i18n';
import { UserType } from '@/helper/enums/melovie.enum';
import { firstConnectionEvalName } from '@/helper/utils/constants';

export default defineComponent({
    name: 'QuestionnaireView',
    components: {
        QuestionnaireModal,
        Header,
    },
    async setup() {
        const { t } = useI18n();
        const router = useRouter();
        const route = useRoute();
        const { onSubmit } = useQuestionnaire();
        let parentQuestionnaire: QuestionnaireData = {} as QuestionnaireData;
        try {
            isLoading.value = true;
            const firstConnectionEval = allEval.value.find(
                (resEval: Eval) => resEval.eval_name === firstConnectionEvalName
            );
            if (firstConnectionEval) parentQuestionnaire = await getQuestionnaireData(firstConnectionEval.eval_id);
            else {
                router.back();
                throw new Error(t('views.dashboard.profile.firstConnectionQuestionnaireNotAvailable'));
            }
        } catch (error: any) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: error,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isLoading.value = false;
        }

        /**
         * title
         *
         * @param questionnaireData
         */
        const onSubmitResult = (questionnaireData: QuestionnaireData[]) => {
            isLoading.value = true;
            onSubmit(
                questionnaireData,
                route.path === '/participant/questionnaire' ? UserType.Participant : UserType.Staff
            )
                .then((res: boolean) => {
                    if (res) {
                        router.back();
                        openAlertPopup({
                            confirmType: ConfirmType.Success,
                            title: t('generic.registered'),
                            message: t('views.dashboard.profile.profileUpdated'),
                            right: {
                                onClick: () => {
                                    isAlertOpened.value = false;
                                },
                            },
                        });
                    }
                })
                .catch((error) =>
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    })
                )
                .finally(() => (isLoading.value = false));
        };
        return { parentQuestionnaire, onSubmitResult };
    },
});
