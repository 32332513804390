
import { defineComponent, PropType } from 'vue';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import {
    isAlertOpened,
    draggingMusicId,
    openAlertPopup,
    recentSessionFavorites,
    recentSessionHates,
    touchMoveStartX,
    touchMoveStartY,
    touchMoveEndX,
    touchMoveEndY,
    draggingElement,
    playingTrackBilan,
    currentUserType,
    staffFavorites,
    participantFavorites,
    staffHates,
    participantHates,
    participantPlaylists,
    staffPlaylists,
    participantMemories,
} from '@/services/sharedService';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { UserType } from '@/helper/enums/melovie.enum';
import { FavoritesStaff, TrackFavorite } from '@/helper/interface/favorite.interface';
import { musicalListItemIdTextPrefix } from '@/helper/utils/constants';
import { PlayListStaff } from '@/helper/interface/playlist.interface';
import { Souvenir } from '@/helper/interface/souvenir.interface';
import ImageComponent from '../ImageComponent.vue';
import { getDefault, getSource } from '@/helper/utils/MusicalTypeImageComponent';

export default defineComponent({
    name: 'MusicalListComponent',
    components: {
        ImageComponent,
    },
    props: {
        listItems: { type: Array as PropType<MusicalListItem[]>, required: true },
        height: { type: String, default: '29em' },
        rowColor: { type: String, default: 'transparent' },
        draggable: { type: Boolean, default: false },
        onRowClick: { type: Function as PropType<(row: MusicalListItem) => void> },
        onPlay: { type: Function as PropType<(row: MusicalListItem) => any> },
        onAdd: { type: Function as PropType<(row: MusicalListItem) => any> },
        onRemove: { type: Function as PropType<(row: MusicalListItem) => any> },
        onComment: { type: Function as PropType<(row: MusicalListItem) => any> },
        onFavorite: { type: Function as PropType<(row: MusicalListItem) => any> },
        onShare: { type: Function as PropType<(row: MusicalListItem) => any> },
        onHate: { type: Function as PropType<(row: MusicalListItem) => any> },
    },
    setup(props: any) {
        const { t } = useI18n();
        const route = useRoute();
        const checkFavorite = (item: MusicalListItem): boolean => {
            if (route.name === 'ParticipantMusicReport') {
                return recentSessionFavorites.value.find((fav: MusicalListItem) => fav.id === item.id) ? true : false;
            }
            const userFavorite: FavoritesStaff | undefined = currentUserType.value === UserType.Staff ? staffFavorites.value : participantFavorites.value;
            return userFavorite?.tracks?.find((track: TrackFavorite) => track.id === item.id) ? true : false;
        };
        const checkHate = (item: MusicalListItem): boolean => {
            if (route.name === 'ParticipantMusicReport') {
                return recentSessionHates.value.find((fav: MusicalListItem) => fav.id === item.id) ? true : false;
            }
            const userHates: FavoritesStaff | undefined = currentUserType.value === UserType.Staff ? staffHates.value : participantHates.value;
            return userHates?.tracks?.find((track: TrackFavorite) => track.id === item.id) ? true : false;
        };
        const checkAddedToPlaylist = (item: MusicalListItem): boolean => {
            const userPlaylist: PlayListStaff[] = currentUserType.value === UserType.Staff ? staffPlaylists.value : participantPlaylists.value;
            return userPlaylist.find((playlist: PlayListStaff) => playlist.tracks?.includes(item.id!)) ? true : false;
        };
        const checkSouvenirAdded = (item: MusicalListItem): boolean => {
            const found = participantMemories.value.find((souvenir: Souvenir) => {
                if (souvenir.track?.id) {
                    return souvenir.track.id === item.id;
                }
                if (souvenir.artist?.id) {
                    return souvenir.artist.id === item.id;
                }
                if (souvenir.album?.id) {
                    return souvenir.album.id === item.id;
                }
                return false;
            });
            return found ? true : false;
        };
        const startDrag = (event: any, item: any) => {
            playingTrackBilan.value = undefined;
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';
            event.dataTransfer.setData('item_id', item.id);
        };
        const handleTouchMove = (event: TouchEvent, track: MusicalListItem) => {
            if (!touchMoveStartX.value && !touchMoveStartY.value) {
                touchMoveStartX.value = event.touches[0].clientX;
                touchMoveStartY.value = event.touches[0].clientY;
                playingTrackBilan.value = undefined;
                draggingMusicId.value = track.id;
                draggingElement.value = document
                    .elementFromPoint(touchMoveStartX.value, touchMoveStartY.value)
                    ?.closest('.card-item');
            }
            touchMoveEndX.value = event.touches[0].clientX;
            touchMoveEndY.value = event.touches[0].clientY;
            if (draggingElement.value) {
                draggingElement.value.style.position = 'fixed';
                draggingElement.value.style.width = '39vw';
                draggingElement.value.style.left = `${event.touches[0].pageX}px`;
                draggingElement.value.style.top = `${event.touches[0].pageY}px`;
            }
        };
        const onPlayTrack = (row: MusicalListItem) => {
            if (props.onPlay) {
                playingTrackBilan.value = props.onPlay(row);
            }
            else
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: t('generic.noData'),
                    right: {
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
        };
        return {
            startDrag,
            checkHate,
            onPlayTrack,
            checkFavorite,
            handleTouchMove,
            checkSouvenirAdded,
            checkAddedToPlaylist,
            playingTrackBilan,
            recentSessionHates,
            MusicalListItemType,
            recentSessionFavorites,
            musicalListItemIdTextPrefix,
            getSource,
            getDefault,
        };
    },
});
