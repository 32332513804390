import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Confirm = _resolveComponent("Confirm")!
  const _component_PlaylistCreator = _resolveComponent("PlaylistCreator")!
  const _component_SouvenirCreatorComponent = _resolveComponent("SouvenirCreatorComponent")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Loader, {
      active: _ctx.isLoading,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isLoading) = $event)),
      "can-cancel": false,
      "is-full-page": true,
      loader: "dots",
      width: 120,
      color: "#fed154"
    }, null, 8, ["active"]),
    (_ctx.isAlertOpened)
      ? (_openBlock(), _createBlock(_component_Confirm, {
          key: 0,
          data: _ctx.alertData
        }, null, 8, ["data"]))
      : _createCommentVNode("", true),
    (_ctx.isPlaylistCreatorOpened)
      ? (_openBlock(), _createBlock(_component_PlaylistCreator, {
          key: 1,
          tracks: _ctx.tracksToAddInPlaylist
        }, null, 8, ["tracks"]))
      : _createCommentVNode("", true),
    (_ctx.isSouvenirCreatorOpened)
      ? (_openBlock(), _createBlock(_component_SouvenirCreatorComponent, {
          key: 2,
          item: _ctx.itemToAddSouvenir,
          souvenirData: _ctx.souvenirToEdit
        }, null, 8, ["item", "souvenirData"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view)
  ], 64))
}