import { api, musicApiPrefix } from '@/api';
import { Genre, GenreReqBody } from '@/helper/interface/music/genre.interface';
/**
 * Get all genres
 *
 * @return {*}  {Promise<Genre[]>}
 */
export const getAllGenresApi = async (reqBody: GenreReqBody): Promise<Genre[]> => {
    const result = await api.post(`${musicApiPrefix}/list/genre`, reqBody);
    return result.data;
};
