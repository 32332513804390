import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-326f2c1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "library-main-container" }
const _hoisted_2 = { class: "library-title" }
const _hoisted_3 = { class: "filter-section" }
const _hoisted_4 = { class: "filtered-musics-section" }
const _hoisted_5 = {
  key: 1,
  class: "no-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_LibraryFilterComponent = _resolveComponent("LibraryFilterComponent")!
  const _component_MusicalList = _resolveComponent("MusicalList")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        title: _ctx.$t('views.dashboard.library.myLibrary'),
        "bg-color": "transparent",
        "title-color": "#36AEC1",
        "title-align": "flex-start"
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_LibraryFilterComponent, { "on-search": _ctx.onSearch }, null, 8, ["on-search"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.currentData.length)
        ? (_openBlock(), _createBlock(_component_MusicalList, {
            key: 0,
            "list-items": _ctx.currentData,
            height: "100%",
            "on-row-click": _ctx.onRowClick,
            "on-add": _ctx.onAddToPlaylist,
            "on-comment": _ctx.$route.name === 'ParticipantLibrary' ? _ctx.onAddToSouvenir : undefined
          }, null, 8, ["list-items", "on-row-click", "on-add", "on-comment"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('generic.noData')), 1)
          ]))
    ]),
    _createVNode(_component_Paginator, {
      totalItems: _ctx.filteredData,
      currentItems: _ctx.currentData,
      "get-current-page": _ctx.getCurrentSelectedPage,
      "current-page": _ctx.currentSelectedLibraryPage
    }, null, 8, ["totalItems", "currentItems", "get-current-page", "current-page"])
  ]))
}