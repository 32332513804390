import {
    addParticipantFavoriteApi,
    addParticipantHateApi,
    deleteParticipantFavoriteApi,
    getParticipantFavoritesApi,
} from '@/api/favoriteApi';
import { addParticipantDislikeApi, addParticipantLikeApi, getParticipantLikesApi } from '@/api/likeApi';
import { getParticipantApi, getParticipantProfileApi, setParticipantProfileApi } from '@/api/participantApi';
import { addParticipantPlaylistApi, getParticipantPlaylistsApi, setPlaylistApi } from '@/api/playlistApi';
import { UserType } from '@/helper/enums/melovie.enum';
import {
    AddPartFavResponse,
    FavoritesPart,
    GetPartFavorite,
    IdPartFav,
    PartAddFavoriteReqBody,
    PartFavorite,
} from '@/helper/interface/favorite.interface';
import { AnsweredLayout } from '@/helper/interface/generic.interface';
import { GetPartLikes, IdPartLike, LikesPart, PartLike } from '@/helper/interface/like.interface';
import {
    IdPart,
    IdProfileParticipant,
    Participant,
    ProfileParticipant,
    ProfileParticipantRequest,
} from '@/helper/interface/participant.interface';
import {
    IdPartPlayList,
    IdPlayList,
    PlayListParticipant,
    PlaylistReqBody,
    SetPlayListPart,
} from '@/helper/interface/playlist.interface';
import { loadUserFavoriteData, sortUserFavorite } from './genericService';
import { participantPlaylists, participantProfile } from './sharedService';
import { addPlaylistTracks } from './music/trackService';

/**
 * Fetch all participants from the API
 *
 * @returns {Promise<Participant[]>} list of participant.
 */
export const getAllParticipants = () => {
    return new Promise<Participant[]>((resolve, reject) => {
        getParticipantApi()
            .then((data: Participant[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/**
 * Fetch participant profile from the API
 *
 * @returns {Promise<ProfileParticipant>} list of participant.
 */
export const getParticipantProfile = (participantId: number) => {
    return new Promise<ProfileParticipant>((resolve, reject) => {
        getParticipantProfileApi(participantId)
            .then((data: ProfileParticipant) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * update participant profile to the database
 *
 * @param {number[]} periods participant favorite periods.
 * @param {number[]} genres participant favorite genres.
 * @returns {Promise<IdProfileParticipant>} participant profile id.
 */
export const setParticipantProfile = async (
    participantId: number,
    periods?: number[],
    genres?: number[],
    artists?: number[],
    tracks?: number[],
    answeredLayouts?: AnsweredLayout[]
) => {
    let currentProfile = participantProfile.value ?? {};
    if (typeof currentProfile === 'string') currentProfile = {};
    if (periods) currentProfile.periods = periods;
    if (genres) currentProfile.genres = genres;
    if (artists) currentProfile.artists = artists;
    if (tracks) currentProfile.tracks = tracks;
    if (answeredLayouts)
        currentProfile.answeredLayouts?.length
            ? currentProfile.answeredLayouts.push(...answeredLayouts)
            : (currentProfile.answeredLayouts = answeredLayouts);
    const reqBody: ProfileParticipantRequest = {
        par_id: participantId,
        data: currentProfile,
    };
    return new Promise<IdProfileParticipant>((resolve, reject) => {
        setParticipantProfileApi(reqBody)
            .then((data: IdProfileParticipant) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Fetch participant favorites and hates
 *
 * @returns {Promise<FavoritesPart>} participant favorites.
 */
export const getParticipantFavoritesAndHates = (
    participantId: number,
    like: boolean = true,
    unlike: boolean = false
) => {
    return new Promise<FavoritesPart>((resolve, reject) => {
        const reqBody: GetPartFavorite = {
            par_id: participantId,
            like,
            unlike,
        };
        getParticipantFavoritesApi(reqBody)
            .then((data: FavoritesPart) => {
                resolve(sortUserFavorite(data));
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add participant favorite
 *
 * @returns {Promise<IdPartFav>} id participant favorite.
 */
export const setParticipantFavorite = (
    participantId: number,
    trackIds?: number[],
    artistIds?: number[],
    albumIds?: number[]
) => {
    return new Promise<AddPartFavResponse[]>((resolve, reject) => {
        const reqBody: PartAddFavoriteReqBody = {
            par_id: participantId,
            favorites: [],
        };
        if (trackIds) {
            reqBody.favorites.push(
                ...trackIds.map((id: number) => {
                    return { trk_id: id };
                })
            );
        }
        if (artistIds) {
            reqBody.favorites.push(
                ...artistIds.map((id: number) => {
                    return { art_id: id };
                })
            );
        }
        if (albumIds) {
            reqBody.favorites.push(
                ...albumIds.map((id: number) => {
                    return { alb_id: id };
                })
            );
        }
        addParticipantFavoriteApi(reqBody)
            .then(async (data: AddPartFavResponse[]) => {
                await loadUserFavoriteData(UserType.Participant, true);
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Delete participant favorite
 *
 * @returns {Promise<IdPartFav>} id participant favorite.
 */
export const removeParticipantFavorite = (
    participantId: number,
    trackId?: number,
    artistId?: number,
    albumId?: number
) => {
    return new Promise<IdPartFav>((resolve, reject) => {
        const reqBody: PartFavorite = {
            par_id: participantId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        deleteParticipantFavoriteApi(reqBody)
            .then(async (data: IdPartFav) => {
                await loadUserFavoriteData(UserType.Participant, true);
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add participant hate
 *
 * @returns {Promise<IdPartFav>} id participant hate.
 */
export const setParticipantHate = (participantId: number, trackId?: number, artistId?: number, albumId?: number) => {
    return new Promise<IdPartFav>((resolve, reject) => {
        const reqBody: PartFavorite = {
            par_id: participantId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        addParticipantHateApi(reqBody)
            .then(async (data: IdPartFav) => {
                await loadUserFavoriteData(UserType.Participant, true);
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Fetch participant likes and unlike
 *
 * @returns {Promise<LikesPart>} participant likes.
 */
export const getParticipantLikeUnlike = (participantId: number, like: boolean = true, unlike: boolean = true) => {
    return new Promise<LikesPart>((resolve, reject) => {
        const reqBody: GetPartLikes = {
            par_id: participantId,
            like,
            unlike,
        };
        getParticipantLikesApi(reqBody)
            .then((data: LikesPart) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add participant like
 *
 * @returns {Promise<IdPartLike>} id participant like.
 */
export const setParticipantLike = (participantId: number, trackId?: number, artistId?: number, albumId?: number) => {
    return new Promise<IdPartLike>((resolve, reject) => {
        const reqBody: PartLike = {
            par_id: participantId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        addParticipantLikeApi(reqBody)
            .then((data: IdPartLike) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add participant dislike
 *
 * @returns {Promise<IdPartLike>} id participant dislike.
 */
export const setParticipantDislike = (participantId: number, trackId?: number, artistId?: number, albumId?: number) => {
    return new Promise<IdPartLike>((resolve, reject) => {
        const reqBody: PartLike = {
            par_id: participantId,
            trk_id: trackId,
            art_id: artistId,
            alb_id: albumId,
        };
        addParticipantDislikeApi(reqBody)
            .then((data: IdPartLike) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Fetch participant's Playlists from the API
 *
 * @param {number} participantId participant id
 * @returns {Promise<PlayListParticipant[]>} list of playlist.
 */
export const getParticipantPlaylists = (participantId: number) => {
    return new Promise<PlayListParticipant[]>((resolve, reject) => {
        const reqBody: IdPart = {
            par_id: participantId,
        };
        getParticipantPlaylistsApi(reqBody)
            .then((data: PlayListParticipant[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Add participant's Playlist
 *
 * @param {number} parId participant id
 * @param {string} playlistName name of the playlist
 * @param {number[]} trackIds track ids to add in the playlist
 * @returns {Promise<PlayListStaff[]>} list of playlist
 */
export const addParticipantPlaylist = (parId: number, playlistName: string, trackIds?: number[]) => {
    return new Promise<PlayListParticipant[]>(async (resolve, reject) => {
        try {
            const reqBodyAddPlaylist: PlaylistReqBody = {
                play_name: playlistName,
            };
            const playIdResponse: IdPlayList = await setPlaylistApi(reqBodyAddPlaylist);
            if (playIdResponse.play_id) {
                const reqBodyAddStaffPlaylist: SetPlayListPart = {
                    play_id: playIdResponse.play_id,
                    par_id: parId,
                };
                const playStaffIdResponse: IdPartPlayList = await addParticipantPlaylistApi(reqBodyAddStaffPlaylist);
                if (playStaffIdResponse.playp_id) {
                    // add to cache also
                    participantPlaylists.value.push({
                        play_id: playIdResponse.play_id,
                        play_name: playlistName,
                    });
                    if (trackIds?.length) {
                        await addPlaylistTracks(playIdResponse.play_id, trackIds);
                    }
                    resolve(participantPlaylists.value);
                }
            }
        } catch (error) {
            reject(error);
        }
    });
};
