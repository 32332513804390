import { api, melovieApiPrefix } from '@/api';
import {
    IdProfileParticipant,
    Participant,
    ProfileParticipant,
    ProfileParticipantRequest,
} from '@/helper/interface/participant.interface';

/**
 * Get list of participants from the API
 *
 * @returns {Participant[]} list of participants.
 */
export const getParticipantApi = async (): Promise<Participant[]> => {
    const result = await api.post(`${melovieApiPrefix}/list/participants`, {});
    return result.data;
};

/**
 * Get profile of a participant
 *
 * @param {number} participantId
 * @return {*}  {Promise<ProfileParticipant>}
 */
export const getParticipantProfileApi = async (participantId: number): Promise<ProfileParticipant> => {
    const result = await api.post(`${melovieApiPrefix}/participant/profil/get`, { par_id: participantId });
    return result.data;
};

/**
 * Set profile of a participant
 *
 * @param {ProfileParticipantRequest} reqBody
 * @return {*}  {Promise<IdProfileParticipant>}
 */
export const setParticipantProfileApi = async (reqBody: ProfileParticipantRequest): Promise<IdProfileParticipant> => {
    const result = await api.post(`${melovieApiPrefix}/participant/profil/set`, reqBody);
    return result.data;
};
