import { UserType } from '@/helper/enums/melovie.enum';
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import { TrackFavorite } from '@/helper/interface/favorite.interface';
import { Genre } from '@/helper/interface/music/genre.interface';
import { Period } from '@/helper/interface/music/period.interface';
import { Track } from '@/helper/interface/music/track.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import router from '@/router';
import { getTrack, searchTracks } from '@/services/music/trackService';
import { removeParticipantFavorite, setParticipantFavorite, setParticipantHate } from '@/services/participantService';
import {
    currentUserType,
    staffFavorites,
    participantFavorites,
    currentStaff,
    currentParticipant,
    openAlertPopup,
    isAlertOpened,
    staffHates,
    participantHates,
    isLoading,
    setMusicPlayerData,
    isResearchResultGenerated,
    researchFilteredData,
    researchSelectedFavGenres,
    researchSelectedGenres,
    researchSelectedPeriods,
    researchText,
    researchedData,
    currentSelectedResearchPage,
    openSouvenirCreator,
} from '@/services/sharedService';
import { removeStaffFavorite, setStaffFavorite, setStaffHate } from '@/services/staffService';
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export function useSearch() {
    const { t } = useI18n();
    const isFilterModalOpened = ref<boolean>(false);
    // count the selected filters
    const filterCount = computed(
        () =>
            researchSelectedFavGenres.value.length +
            researchSelectedGenres.value.length +
            researchSelectedPeriods.value.length
    );
    // filter modal data
    const filterModalData = ref<ConfirmProps>({
        confirmType: ConfirmType.Alert,
        title: t('views.research.chooseFilters'),
        headerIcon: 'fa-filter',
        left: {
            text: t('generic.cancel'),
            onClick: () => {
                isFilterModalOpened.value = false;
            },
        },
        right: {
            text: t('generic.validate'),
            onClick: () => {
                onSearchClick();
                isFilterModalOpened.value = false;
            },
        },
    });

    /**
     * handle search
     *
     */
    const onSearchClick = async () => {
        try {
            isLoading.value = true;
            // get the search result from API
            const resTracks: Track[] = await searchTracks(
                researchText.value,
                [
                    ...researchSelectedFavGenres.value.map((genre: Genre) => genre.gmus_id),
                    ...researchSelectedGenres.value.map((genre: Genre) => genre.gmus_id),
                ],
                researchSelectedPeriods.value.map((period: Period) => period.muspe_id)
            );
            // if result then convert it as UI
            if (resTracks.length > 0) {
                researchedData.value = await Promise.all(
                    resTracks.map(async (track: Track) => {
                        const result: MusicalListItem = {
                            id: track.trk_id,
                            title: track.trk_name || '',
                            artist: track.art_name || '',
                            description: track.trk_description || '',
                            asset: track.alb_ass_id ?? -1,
                            type: MusicalListItemType.Track,
                        };
                        return result;
                    })
                );
            } else researchedData.value = []; // else make UI list empty
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isResearchResultGenerated.value = true;
            isLoading.value = false;
        }
    };

    /**
     * open filter modal
     *
     */
    const onFilterClick = () => {
        isFilterModalOpened.value = true;
    };

    /**
     * reset filter data
     *
     */
    const onReset = () => {
        researchSelectedFavGenres.value = [];
        researchSelectedGenres.value = [];
        researchSelectedPeriods.value = [];
    };

    /**
     * toggle favorite for a music
     *
     * @param {MusicalListItem} track music to make favorite
     */
    const onFavorite = async (track: MusicalListItem) => {
        try {
            isLoading.value = true;
            // load user favorite from cache
            const userFavorite =
                currentUserType.value === UserType.Staff ? staffFavorites.value : participantFavorites.value;

            // check the music is already in favorite list or not
            const alreadyFavorite = userFavorite?.tracks?.find((trk: TrackFavorite) => track.id === trk.id);

            if (alreadyFavorite) {
                // remove from favorite list
                currentUserType.value === UserType.Staff
                    ? await removeStaffFavorite(currentStaff.value?.staf_id!, track.id)
                    : await removeParticipantFavorite(currentParticipant.value?.par_id!, track.id);
            } else {
                // add to favorite list
                currentUserType.value === UserType.Staff
                    ? await setStaffFavorite(currentStaff.value?.staf_id!, [track.id!])
                    : await setParticipantFavorite(currentParticipant.value?.par_id!, [track.id!]);
            }
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isLoading.value = false;
        }
    };

    /**
     * toggle hate for a music
     *
     * @param {MusicalListItem} track music to hate
     */
    const onHate = async (track: MusicalListItem) => {
        try {
            isLoading.value = true;
            // get user hates from cache
            const userHates = currentUserType.value === UserType.Staff ? staffHates.value : participantHates.value;

            // check if the music already in hate list or not
            const alreadyHate = userHates?.tracks?.find((trk: TrackFavorite) => track.id === trk.id);

            if (alreadyHate) {
                // remove from hate list
                currentUserType.value === UserType.Staff
                    ? await removeStaffFavorite(currentStaff.value?.staf_id!, track.id)
                    : await removeParticipantFavorite(currentParticipant.value?.par_id!, track.id);
            } else {
                // add to hate list
                currentUserType.value === UserType.Staff
                    ? await setStaffHate(currentStaff.value?.staf_id!, track.id)
                    : await setParticipantHate(currentParticipant.value?.par_id!, track.id);
            }
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        } finally {
            isLoading.value = false;
        }
    };

    /**
     * redirect to music player by clicking a list item
     *
     * @param {MusicalListItem} listItem music to play
     */
    const onRowClick = (listItem: MusicalListItem) => {
        switch (listItem.type) {
            case MusicalListItemType.Track:
                isLoading.value = true;
                getTrack([listItem.id!])
                    .then((trackData: Track[]) => {
                        if (trackData.length) {
                            setMusicPlayerData(trackData);
                            router.push('/music-player');
                        } else
                            openAlertPopup({
                                confirmType: ConfirmType.Error,
                                message: t('generic.noData'),
                                right: {
                                    text: t('generic.back'),
                                    onClick: () => {
                                        isAlertOpened.value = false;
                                    },
                                },
                            });
                    })
                    .catch((error) =>
                        openAlertPopup({
                            confirmType: ConfirmType.Error,
                            message: error,
                            right: {
                                text: t('generic.back'),
                                onClick: () => {
                                    isAlertOpened.value = false;
                                },
                            },
                        })
                    )
                    .finally(() => (isLoading.value = false));
                break;
        }
    };

    /**
     * save current selected page in cache
     *
     * @param {number} page selected page number
     */
    const getCurrentSelectedPage = (page: number) => {
        currentSelectedResearchPage.value = page;
    };

    /**
     * Open souvenir popup
     *
     * @param {MusicalListItem} track track to add souvenir
     */
    const onAddToSouvenir = (track: MusicalListItem) => {
        openSouvenirCreator(track);
    };

    return {
        filterCount,
        researchText,
        researchedData,
        filterModalData,
        isFilterModalOpened,
        researchFilteredData,
        researchSelectedGenres,
        researchSelectedPeriods,
        researchSelectedFavGenres,
        isResearchResultGenerated,
        currentSelectedResearchPage,
        onHate,
        onReset,
        onFavorite,
        onRowClick,
        onSearchClick,
        onFilterClick,
        onAddToSouvenir,
        getCurrentSelectedPage,
    };
}
