import { setMedicalReportActivityApi, setMedicalReportApi } from '@/api/medicalReportApi';
import {
    Report,
    ReportActivityData,
    ReportActivityParticipant,
    ReportData,
} from '@/helper/interface/medicalReport.interface';

/**
 * Set medical report
 *
 * @param {ReportData} reqBody
 * @return {*}  {Promise<Report>}
 */
export const setMedicalReport = (reportData: ReportData) => {
    return new Promise<Report>((resolve, reject) => {
        setMedicalReportApi(reportData)
            .then((data: Report) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Set medical report activity
 *
 * @param {ReportActivityData} reqBody
 * @return {*}  {Promise<ReportActivityParticipant>}
 */
export const setMedicalReportActivity = (reportActivityData: ReportActivityData) => {
    return new Promise<ReportActivityParticipant>((resolve, reject) => {
        setMedicalReportActivityApi(reportActivityData)
            .then((data: ReportActivityParticipant) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
