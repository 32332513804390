
import { getDefault, getSource } from '@/helper/utils/MusicalTypeImageComponent';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { PropType } from 'vue';
import ImageComponent from '../ImageComponent.vue';

/**
 * Modal Sidebar contain list of tracks.
 *
 * @param {String} title title of items.
 * @param {Track[]} tracks list of music object.
 */
export default {
    name: 'ModalSidebarComponent',
    props: {
        title: { type: String, required: true },
        tracks: { type: Array as PropType<MusicalListItem[]>, required: true },
        closeBtn: { type: Function as PropType<(item: MusicalListItem) => void> },
    },
    components: { ImageComponent },
    setup() {
        return {
            getDefault,
            getSource,
        };
    },
};
