import { getAllTracksApi, getTrackApi, searchTracksApi } from '@/api/music/trackApi';
import { addPlaylistTracksApi } from '@/api/playlistApi';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { MusicalListItemType } from '@/helper/enums/ui.enum';
import {
    GetTrack,
    GetTracksWithCriteria,
    SearchTracksRequestBody,
    Track,
} from '@/helper/interface/music/track.interface';
import { IdTracksPlayList, PlayListStaff, PlayListTracks } from '@/helper/interface/playlist.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { getAssets } from '../assetsService';
import { participantPlaylists, staffPlaylists, currentUserType } from '../sharedService';
import { UserType } from '@/helper/enums/melovie.enum';

/**
 * Get tracks by track ids
 *
 * @param {number[]} trackIds list of track id
 * @returns {Promise<Track[]>} list of services.
 */
export const getTrack = (trackIds: number[]) => {
    return new Promise<Track[]>((resolve, reject) => {
        const reqBody: GetTrack = {
            ids: trackIds,
        };
        getTrackApi(reqBody)
            .then((data: Track[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Get all filtered tracks.
 *
 * @returns {Promise<Track[]>} list of services.
 */
export const getAllTracks = (genres?: number[], periods?: number[], count?: number) => {
    return new Promise<Track[]>((resolve, reject) => {
        let reqBody: GetTracksWithCriteria = {
            genres,
            periods,
            count,
        };
        getAllTracksApi(reqBody)
            .then((data: Track[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Get all tracks as music list item.
 *
 * @returns {Promise<MusicalListItem[]>} list of musics.
 */
export const getAllMusicList = (genres?: number[], periods?: number[], count?: number) => {
    return new Promise<MusicalListItem[]>(async (resolve, reject) => {
        try {
            const tracks: Track[] = await getAllTracks(genres, periods, count);
            const musicList: MusicalListItem[] = await Promise.all(
                tracks.map(async (track: Track) => {
                    const result: MusicalListItem = {
                        id: track.trk_id,
                        title: track.trk_name || '',
                        artist: track.art_name || '',
                        description: track.trk_description || '',
                        asset: track.alb_ass_id ?? -1,
                        type: MusicalListItemType.Track,
                    };
                    return result;
                })
            );
            resolve(musicList);
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Add tracks to a playlist
 *
 * @param {number} playlistId
 * @param {number[]} trackIds
 * @return {*}  {Promise<IdTracksPlayList[]>}
 */
export const addPlaylistTracks = (playlistId: number, trackIds: number[]) => {
    return new Promise<IdTracksPlayList[]>((resolve, reject) => {
        let reqBody: PlayListTracks = {
            play_id: playlistId,
            tracks: trackIds,
        };
        addPlaylistTracksApi(reqBody)
            .then((data: IdTracksPlayList[]) => {
                // add to cache also
                const userPlaylist: PlayListStaff | undefined = (
                    currentUserType.value === UserType.Staff ? staffPlaylists.value : participantPlaylists.value
                ).find((playlist: PlayListStaff) => playlist.play_id === playlistId);

                if (userPlaylist?.tracks) userPlaylist.tracks.push(...trackIds);
                else if (userPlaylist) userPlaylist.tracks = trackIds;

                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Search tracks.
 *
 * @param {string} searchText text for search
 * @param {number[]} [genres] genres ids
 * @param {number[]} [periods] periods ids
 * @param {number} [count] track count
 * @param {boolean} [scopeTrack=true] search with track or not
 * @param {boolean} [scopeAlbum=true] search with album or not
 * @param {boolean} [scopeArtist=true] search with artist or not
 * @return {Promise<Track[]>} list of tracks that matches those criteria
 */
export const searchTracks = (
    searchText: string,
    genres?: number[],
    periods?: number[],
    count?: number,
    scopeTrack: boolean = true,
    scopeAlbum: boolean = true,
    scopeArtist: boolean = true
) => {
    return new Promise<Track[]>((resolve, reject) => {
        const reqBody: SearchTracksRequestBody = {
            search: searchText,
            scope: {
                track: scopeTrack,
                album: scopeAlbum,
                artist: scopeArtist,
            },
            genres,
            periods,
            count,
        };
        searchTracksApi(reqBody)
            .then((data: Track[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
