import { CheckPin, IsGood } from '@/helper/interface/auth.interface';
import { getAllAnimationPlaces, getAllServices } from './establishmentService';
import { ConnectionPageData } from '@/helper/interface/ui/ui.interface';
import { checkStaffPinApi } from '@/api/staffApi';
import { getAllGroups } from './genericService';
import { getAllStaffs } from './staffService';

/**
 * Fetch all data to make connection, from the API
 *
 * @returns {ConnectionPageData} list of user, service, groups and animation place data.
 */
export const getConnectionPageData = () => {
    return new Promise<ConnectionPageData>(async (resolve, reject) => {
        let connectionPageData: ConnectionPageData = {} as ConnectionPageData;
        try {
            connectionPageData.staffs = await getAllStaffs();
            connectionPageData.services = await getAllServices();
            connectionPageData.groups = await getAllGroups();
            connectionPageData.animationPlaces = await getAllAnimationPlaces();
            resolve(connectionPageData);
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Authenticate a staff
 *
 * @param {number} staffId
 * @param {number[]} numbers should be length 4
 * @return {*}  {Promise<IsGood>}
 */
export const connectStaff = (staffId: number, numbers: number[]): Promise<IsGood> => {
    const pin: string = numbers.join('');
    const reqBody: CheckPin = {
        staf_id: staffId,
        pin,
    };
    return checkStaffPinApi(reqBody);
};
