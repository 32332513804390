
import { ConfirmType } from '@/helper/enums/ui.enum';
import {
    Report,
    ReportActivityData,
    ReportActivityParticipant,
    ReportData,
    ReportResult,
    Trouble,
} from '@/helper/interface/medicalReport.interface';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import { getTroubles } from '@/services/genericService';
import { setMedicalReport, setMedicalReportActivity } from '@/services/medicalReportService';
import { currentStaff, currentParticipant, isAlertOpened, isLoading, openAlertPopup, startDate, currentSessionId } from '@/services/sharedService';
import { defineComponent, onBeforeMount, onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';

export default defineComponent({
    name: 'Report',
    components: {
        Confirm,
    },
    props: {
        sessionId: String,
    },
    setup(props: any) {
        const { t, locale } = useI18n();
        const router = useRouter();
        const reportData = ref<ReportData>({
            staf_id: currentStaff.value?.staf_id,
            par_id: currentParticipant.value?.par_id,
            sess_id: parseInt(props.sessionId),
            date: new Date(),
            results: {
                0: 0,
                1: 0,
                2: 0,
                3: 0,
                4: 0,
            },
            is_therapeutic: false,
            global_comment: '',
        } as ReportData);
        const reportActivityData = ref<ReportActivityData>({
            par_id: currentParticipant.value?.par_id,
        } as ReportActivityData);

        const troubles = ref<Trouble[]>([]);
        // Speech recognition
        const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
        const voiceSource = SpeechRecognition ? new SpeechRecognition() : null;
        const isMicOn = ref<boolean>(false);
        const reportFooterPopup = ref<boolean>(false);
        const reportPopupData = ref<ConfirmProps>({
            confirmType: ConfirmType.Alert,
            title: t('generic.information'),
            right: {
                text: t('generic.back'),
                onClick: () => {
                    reportFooterPopup.value = false;
                },
            },
        });

        const toggleMic = () => (isMicOn.value ? voiceSource?.stop() : voiceSource?.start());

        const onAppreciation = (index: number, value: number) => {
            const val = reportData.value.results[index as keyof ReportResult] === value;
            reportData.value.results[index as keyof ReportResult] = val ? 0 : value;
        }

        const onEvaluation = (index: number) =>
            (reportActivityData.value.score = index === 0 ? 3 : index === 2 ? 0 : index);

        const getTimetoHHMM = (date: Date) =>
            (date.toTimeString().split(' ')[0].substring(0, 5)); // Get only 'HH:MM' value

        const generateReportTitle = () => {
            const result = {
                name: `${currentParticipant.value?.par_firstname} ${currentParticipant.value?.par_lastname}`,
                date: (startDate?.value ?? new Date()).toLocaleDateString('fr-FR'),
                time: getTimetoHHMM(startDate?.value ?? new Date()),
            };
            return result;
        };

        const onSelectTrouble = (trouble: Trouble) => {
            const val = reportData.value.tro_id === trouble.tro_id;
            reportData.value.tro_id = val ? undefined : trouble.tro_id;
        }

        const onClose = () => {
            isLoading.value = true;

            // set default values of report data
            Object.entries(reportData.value.results).forEach(([key, value]) => {
                if (!value) reportData.value.results[key as unknown as keyof ReportResult] = 0;
            });
            if (!reportData.value.global_comment?.length) delete reportData.value.global_comment;

            // set default values of report activity data
            if (reportActivityData.value.score === undefined) reportActivityData.value.score = -1;

            setMedicalReport(reportData.value)
                .then((res: Report) => {
                    if (res.rep_id) {
                        reportActivityData.value.rep_id = res.rep_id;
                        setMedicalReportActivity(reportActivityData.value)
                            .then((response: ReportActivityParticipant) => {
                                if (response.rap_id) {
                                    currentSessionId.value = undefined;
                                    router.push('/session');
                                }
                            })
                            .catch((error) =>
                                openAlertPopup({
                                    confirmType: ConfirmType.Error,
                                    message: error,
                                    right: {
                                        text: t('generic.back'),
                                        onClick: () => {
                                            isAlertOpened.value = false;
                                        },
                                    },
                                })
                            )
                            .finally(() => (isLoading.value = false));
                    }
                })
                .catch((error) => {
                    isLoading.value = false;
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    });
                });
        };

        const openInfo = (type: number) => {
            switch (type) {
                case 1:
                    openAlertPopup({
                        title: t('generic.information'),
                        confirmType: ConfirmType.Alert,
                        message: t('views.participantDashboard.report.infoObjective'),
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    });
                    break;

                case 2:
                    reportFooterPopup.value = true;
                    break;
            }
        };

        onBeforeMount(() => {
            isLoading.value = true;
            getTroubles()
                .then((res: Trouble[]) => (troubles.value = res))
                .catch((error) =>
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    })
                )
                .finally(() => (isLoading.value = false));
        });

        onMounted(() => {
            // Speech recognition
            if (voiceSource !== null) {
                voiceSource.lang = locale.value;
                voiceSource.continuous = true;
                voiceSource.interimResults = true;

                voiceSource.onstart = () => (isMicOn.value = true);
                voiceSource.onend = () => (isMicOn.value = false);

                voiceSource.onresult = (event: any) => {
                    const data = Array.from(event.results as SpeechRecognitionResultList)
                        .map((result: SpeechRecognitionResult) => result[0]);
                    // Ugly behavior for tab android ...
                    (reportData.value.global_comment = data[data.length - 1].transcript);
                }
            }
        });

        const hasSpeechRecognition = computed(() => SpeechRecognition !== undefined);

        return {
            isMicOn,
            troubles,
            reportData,
            currentParticipant,
            onClose,
            openInfo,
            toggleMic,
            onEvaluation,
            onAppreciation,
            onSelectTrouble,
            generateReportTitle,
            reportFooterPopup,
            reportPopupData,
            reportActivityData,
            hasSpeechRecognition,
        };
    },
});
