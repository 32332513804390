import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27e26daf"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "period-icon-title" }
const _hoisted_3 = { class: "img-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageComponent = _resolveComponent("ImageComponent")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periodData, (period, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      class: _normalizeClass(_ctx.isPeriodSelected(period) ? 'contents-selected' : 'contents'),
      onClick: ($event: any) => (_ctx.onSubmit && _ctx.onSubmit(period))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(period.muspe_label), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ImageComponent, {
          assId: period.muspe_ass_id ?? -1,
          source: _ctx.ref,
          class: _normalizeClass(_ctx.isPeriodSelected(period) ? 'icon-image' : 'hide-image')
        }, null, 8, ["assId", "source", "class"])
      ])
    ], 10, _hoisted_1))
  }), 128))
}