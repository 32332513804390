import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0c4d1fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "backdrop-container" }
const _hoisted_2 = {
  key: 0,
  class: "close-container"
}
const _hoisted_3 = { class: "title-container" }
const _hoisted_4 = {
  key: 0,
  class: "icon-container"
}
const _hoisted_5 = { class: "alert-body" }
const _hoisted_6 = {
  key: 0,
  class: "message-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "alert-container",
      style: _normalizeStyle({ maxHeight: _ctx.maxHeight, maxWidth: _ctx.maxWidth })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["alert-header", { 'title-only': !_ctx.data.onClose }])
      }, [
        (_ctx.data.onClose)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.data.onClose && _ctx.data.onClose()))
              }, "X")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.data.headerIcon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_font_awesome_icon, {
                  icon: _ctx.data.headerIcon
                }, null, 8, ["icon"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("h3", null, _toDisplayString(_ctx.data.title
                                ? _ctx.data.title
                                : _ctx.$t(
                                      _ctx.data.confirmType === _ctx.ConfirmType.Success
                                          ? 'generic.success'
                                          : _ctx.data.confirmType === _ctx.ConfirmType.Error
                                          ? 'generic.error'
                                          : 'generic.alert'
                                  ).toUpperCase()), 1)
        ]),
        _renderSlot(_ctx.$slots, "alertHeader", {}, undefined, true)
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.data.message)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", null, _toDisplayString(_ctx.data.message), 1)
            ]))
          : _renderSlot(_ctx.$slots, "alertBody", { key: 1 }, undefined, true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["alert-footer", { 'single-button': !_ctx.data.left }])
      }, [
        (_ctx.data.left)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.data.left?.onClick && _ctx.data.left.onClick()))
            }, _toDisplayString(_ctx.data.left?.text
                            ? _ctx.data.left.text
                            : _ctx.data.confirmType === _ctx.ConfirmType.Error
                            ? _ctx.$t('generic.cancel')
                            : _ctx.data.confirmType === _ctx.ConfirmType.Alert
                            ? _ctx.$t('generic.later')
                            : _ctx.$t('generic.exit')), 1))
          : _createCommentVNode("", true),
        (_ctx.data.right)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.data.right?.onClick && _ctx.data.right?.onClick()))
            }, _toDisplayString(_ctx.data.right?.text
                            ? _ctx.data.right.text
                            : _ctx.data.confirmType === _ctx.ConfirmType.Alert
                            ? _ctx.$t('generic.yes')
                            : _ctx.$t('generic.ok')), 1))
          : _createCommentVNode("", true)
      ], 2)
    ], 4)
  ]))
}