import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-151af512"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "music-report-container" }
const _hoisted_2 = { class: "musics-header" }
const _hoisted_3 = { class: "songs-container" }
const _hoisted_4 = { class: "liked-songs_heading" }
const _hoisted_5 = { class: "like-button" }
const _hoisted_6 = { class: "share-button" }
const _hoisted_7 = { class: "add-button" }
const _hoisted_8 = { class: "songs-list" }
const _hoisted_9 = { class: "disliked-songs_heading" }
const _hoisted_10 = { class: "songs-list" }
const _hoisted_11 = { class: "close-filtered-musics" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_MusicalList = _resolveComponent("MusicalList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        title: _ctx.$t('views.participantDashboard.discovery.assessmentOfDiscovery'),
        "bg-color": "transparent",
        "title-color": "#36AEC1",
        "title-align": "flex-start"
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "liked-songs",
        onTouchend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args))),
        onDrop: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDropLiked($event))),
        "dragenter.prevent": "",
        "dragover.prevent": "",
        onDragover: _cache[3] || (_cache[3] = ($event: any) => (_ctx.allowDrop($event)))
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", null, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-thumbs-up" })
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", null, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-share-nodes" })
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAddToPlaylist && _ctx.onAddToPlaylist(...args)))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "fa-regular fa-square-plus" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_MusicalList, {
            "list-items": _ctx.likedSongLists,
            draggable: true,
            height: "29em",
            "on-comment": (item) => _ctx.openSouvenirCreator(item),
            "on-play": (row) => _ctx.onPlay(row),
            "on-favorite": _ctx.onFavorite
          }, null, 8, ["list-items", "on-comment", "on-play", "on-favorite"])
        ])
      ], 32),
      _createElementVNode("div", {
        class: "disliked-songs",
        onTouchend: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleTouchEnd && _ctx.handleTouchEnd(...args))),
        onDrop: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onDropDisliked($event))),
        "dragenter.prevent": "",
        "dragover.prevent": "",
        onDragover: _cache[6] || (_cache[6] = ($event: any) => (_ctx.allowDrop($event)))
      }, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", null, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-thumbs-up" })
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_MusicalList, {
            "list-items": _ctx.dislikedSongLists,
            draggable: true,
            height: "29em",
            "on-comment": (item) => _ctx.openSouvenirCreator(item),
            "on-play": (row) => _ctx.onPlay(row, false),
            "on-favorite": _ctx.onFavorite,
            "on-hate": _ctx.onHate
          }, null, 8, ["list-items", "on-comment", "on-play", "on-favorite", "on-hate"])
        ])
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onExit()))
      }, _toDisplayString(_ctx.$t('views.participantDashboard.discovery.fence')), 1)
    ])
  ]))
}