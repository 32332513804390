import { api, musicApiPrefix } from '@/api';
import { AssetFormatEnum } from '@/helper/enums/music.enum';
import { AssetReqBody } from '@/helper/interface/music/asset.interface';
import { AxiosResponse } from 'axios';

/**
 * Get asset
 *
 * @return {*}  {Promise<File>}
 */
export const getAssetApi = async (reqBody: AssetReqBody) => {
    let result: AxiosResponse = {} as AxiosResponse;
    if (reqBody.format === AssetFormatEnum.Binary) {
        result = await api.post(`${musicApiPrefix}/asset`, reqBody, { responseType: 'arraybuffer' });
    } else {
        result = await api.post(`${musicApiPrefix}/asset`, reqBody);
    }
    return result.data;
};

/**
 * Get track as stream
 *
 * @return {*}  {Promise<File>}
 */
export const getTrackStreamApi = (trackId: number) => {
    return api.post(`${musicApiPrefix}/musicstream`, { id: trackId }, { responseType: 'stream' });
};
