import { library } from '@fortawesome/fontawesome-svg-core';

/* import specific icons */
import {
    faDeleteLeft,
    faUser,
    faUsers,
    faHeart as solidHeart,
    faMagnifyingGlass,
    faRightFromBracket,
    faPen,
    faCalendarDay,
    faRotateRight,
    faHeadphonesSimple,
    faSpa,
    faCameraRetro,
    faCamera,
    faArrowLeft,
    faArrowRight,
    faTriangleExclamation,
    faSmile,
    faThumbsUp,
    faThumbsDown,
    faAngleRight,
    faPlay,
    faPause,
    faCheck,
    faArrowRightFromBracket,
    faMicrophone,
    faInfo,
    faChevronRight,
    faShareNodes,
    faSquarePlus,
    faMessage,
    faAdd,
    faShuffle,
    faMusic,
    faBackwardStep,
    faForwardStep,
    faChevronLeft,
    faFileSignature,
    faFilter,
} from '@fortawesome/free-solid-svg-icons';
import {
    faCirclePlay,
    faClock,
    faHeart as regularHeart,
    faSquarePlus as regularFaSquarePlus,
    faMessage as regularFaMessage,
    faMinusSquare as regularFaMinusSquare,
} from '@fortawesome/free-regular-svg-icons';

/* add icons to the library */
library.add(
    faDeleteLeft,
    faCirclePlay,
    faUser,
    faUsers,
    solidHeart,
    regularHeart,
    faMagnifyingGlass,
    faRightFromBracket,
    faPen,
    faCalendarDay,
    faRotateRight,
    faHeadphonesSimple,
    faSpa,
    faCameraRetro,
    faCamera,
    faArrowLeft,
    faArrowRight,
    faTriangleExclamation,
    faSmile,
    faThumbsUp,
    faThumbsDown,
    faAngleRight,
    faPlay,
    faPause,
    faCheck,
    faArrowRightFromBracket,
    faMicrophone,
    faInfo,
    faChevronRight,
    faShareNodes,
    faSquarePlus,
    faMessage,
    regularFaMessage,
    regularFaSquarePlus,
    faAdd,
    faClock,
    faShuffle,
    faMusic,
    regularFaMinusSquare,
    faBackwardStep,
    faForwardStep,
    faChevronLeft,
    faFileSignature,
    faFilter
);
