
import { PropType } from 'vue';

/**
 * launch button component to launch a session
 *
 * @param {string} text text to show on middle of button.
 * @param {Function} onClick function to trigeer the session
 */
export default {
    name: 'LunchButton',
    props: {
        text: { type: String, required: true },
        onClick: Function as PropType<() => void>,
    },
};
