import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b95b898"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "questionnaire-container" }
const _hoisted_2 = { class: "title-texts" }
const _hoisted_3 = { class: "ques-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_QuestionnaireModal = _resolveComponent("QuestionnaireModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Header, {
        title: _ctx.$t('views.dashboard.questionnaire.firstConnection'),
        "bg-color": "#fff",
        "title-color": "#36AEC1",
        "title-align": "left",
        "font-size": "2rem"
      }, null, 8, ["title"]),
      _createVNode(_component_Header, {
        title: _ctx.$t('views.dashboard.questionnaire.fillProfile'),
        "bg-color": "#fff",
        "title-color": "#000000",
        "title-align": "left",
        "font-size": "1rem"
      }, null, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_QuestionnaireModal, {
        data: _ctx.parentQuestionnaire,
        "on-submit": _ctx.onSubmitResult
      }, null, 8, ["data", "on-submit"])
    ])
  ]))
}