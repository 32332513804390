import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { getStoreConfig, getAuthToken } from './storage';

// prefixes
export const melovieApiPrefix = '/melovie';
export const musicApiPrefix = '/music';

// API
export const api = axios.create();

// ajout d'un interceptor pour récupérer le bon token
api.interceptors.request.use((config) => {
    config = {
        ...config,
        headers: {
          ...config.headers,
          'X-Auth-Token':getAuthToken(),
        },
      } as AxiosRequestConfig
    return config;
  });

export const getConfig = async () => {
    // on laisse le store récupérer la config
    const conf = await getStoreConfig();
    api.defaults.baseURL = conf.apiUrl;
    api.defaults.headers.common['X-Api-Version'] = conf.apiVersion.toString();
    api.defaults.headers.common['X-Api-Key'] = conf.apiKey;
    api.defaults.headers.common['X-Auth-Token'] = conf?.apiToken;
    return conf;
};
