import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import LoginView from '@/views/auth/LoginView.vue';
import AutoLoginView from '@/views/auth/AutoLoginView.vue';
import NotFoundView from '@/views/error/NotFoundView.vue';
import NotAutorizedView from '@/views/error/NotAutorizedView.vue';
import { getConfig } from '@/api';
import MusicPlayerView from '@/views/music-player/MusicPlayerView.vue';
import PlaylistView from '@/views/playlist/PlayListView.vue';
import DashboardView from '@/views/dashboard/DashboardView.vue';
import ProfileView from '@/views/dashboard/profile/ProfileView.vue';
import LibraryView from '@/views/dashboard/library/LibraryView.vue';
import SearchView from '@/views/dashboard/search/SearchView.vue';
import SessionView from '@/views/dashboard/session/SessionView.vue';
import ReportView from '@/views/report/ReportView.vue';
import MusicReportView from '@/views/participant/dashboard/music-report/MusicReportView.vue';
// ================================= participants =======================================
import ParticipantDashboardView from '@/views/participant/dashboard/ParticipantDashboardView.vue';
import ParticipantProfileView from '@/views/participant/dashboard/profile/ParticipantProfileView.vue';
import ParticipantDiscoveryView from '@/views/participant/dashboard/discovery/ParticipantDiscoveryView.vue';
import ParticipantRelaxView from '@/views/participant/dashboard/relax/ParticipantRelaxView.vue';
import QuestionnaireView from '@/views/questionnaire/QuestionnaireView.vue';
import { isAuthenticated } from '@/services/sharedService';
import { refresh } from '@/storage'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login/:uid',
        name: 'Login',
        component: AutoLoginView,
        props: true
    },
    {
        path: '/auth',
        name: 'Auth',
        component: LoginView,
    },
    {
        path: '/logout',
        name: 'Logout',
        redirect: '/auth',
    },
    {
        path: '/participant/logout',
        name: 'ParticipantLogout',
        redirect: '/session',
    },
    {
        path: '/music-player',
        name: 'MusicPlayer',
        component: MusicPlayerView,
    },
    {
        path: '/discovery/music-player',
        name: 'DiscoveryMusicPlayer',
        component: MusicPlayerView,
    },
    {
        path: '/playlist/music-player',
        name: 'PlaylistMusicPlayer',
        component: MusicPlayerView,
    },
    {
        path: '/report/:sessionId',
        name: 'Report',
        props: true,
        component: ReportView,
    },
    {
        path: '/',
        redirect: '/profile',
        component: DashboardView,
        children: [
            {
                path: 'profile',
                name: 'Profile',
                component: ProfileView,
            },
            {
                path: 'questionnaire',
                name: 'Questionnaire',
                component: QuestionnaireView,
            },
            {
                path: 'library',
                name: 'Library',
                component: LibraryView,
            },
            {
                path: 'search',
                name: 'Search',
                component: SearchView,
            },
            {
                path: 'session',
                name: 'Session',
                component: SessionView,
            },
            {
                path: '/playlist/:playlistId',
                name: 'PlaylistStaff',
                props: true,
                component: PlaylistView,
            },
        ],
    },
    {
        path: '/participant',
        redirect: '/participant/profile',
        component: ParticipantDashboardView,
        children: [
            {
                path: 'questionnaire',
                name: 'ParticipantQuestionnaire',
                component: QuestionnaireView,
            },
            {
                path: 'profile',
                name: 'ParticipantProfile',
                component: ParticipantProfileView,
            },
            {
                path: 'discovery',
                name: 'ParticipantDiscovery',
                component: ParticipantDiscoveryView,
            },
            {
                path: 'music-report',
                name: 'ParticipantMusicReport',
                component: MusicReportView,
            },
            {
                path: 'library',
                name: 'ParticipantLibrary',
                component: LibraryView,
            },
            {
                path: 'relax',
                name: 'ParticipantRelax',
                component: ParticipantRelaxView,
            },
            {
                path: 'search',
                name: 'ParticipantSearch',
                component: SearchView,
            },
            {
                path: 'playlist/:playlistId',
                name: 'PlaylistParticipant',
                props: true,
                component: PlaylistView,
            },
        ],
    },
    {
        path: '/401',
        name: '401',
        component: NotAutorizedView,
    },
    {
        path: '/404',
        name: '404', 
        component: NotFoundView,
    },
    {
        path: '/:catchAll(.*)',
        redirect: "/404"
    }
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// auth guard
router.beforeEach(async (to, from) => {
    await getConfig(); // initializing configuration of a request

    if (to.name !== 'Login' && to.name !== '401' && to.name !== '404'){

        refresh(); // maintien du stockage local à chaque navigation
        if (to.name !== 'Auth' && !isAuthenticated.value) return { name: 'Login', params: to.params };
        else {
            if (to.name == 'Auth') {
                isAuthenticated.value = false;
            }
        }

    }

});

export default router;
