
import { FilterData } from '@/helper/interface/ui/components/filter';
import { Group } from '@/helper/interface/group.interface';
import { Service } from '@/helper/interface/service.interface';
import { onMounted, PropType, ref, Transition, watch } from 'vue';

/**
 * Filter component to filter users.
 *
 * @param {string} bgColor background color of header.
 * @param {string} fontSize font size.
 * @param {string} titleColor title color of header.
 * @param {string} height height of header.
 * @param {string[]} locations list of locations.
 * @param {string[]} groups list of groups.
 * @param {function} getFilterData sent filter data to parent component.
 * @param {function} onReset call the function upon reset filter.
 */
export default {
    name: 'FilterComponent',
    props: {
        bgColor: String,
        titleColor: String,
        fontSize: String,
        height: String,
        locations: { type: Array as PropType<Array<Service>>, required: true },
        groups: { type: Array as PropType<Array<Group>>, required: true },
        getFilterData: Function as PropType<(data: FilterData) => void>,
        onReset: Function as PropType<() => void>,
    },
    setup(props: any) {
        const alphaNum = Array.from(Array(26)).map((e, i) => i + 65);
        const alphabets = alphaNum.map((x) => String.fromCharCode(x));
        const nameTab: number = 0;
        const locationTab: number = 1;
        const groupTab: number = 2;
        const selectedTab = ref<number>(nameTab);
        const filterData = ref<FilterData>({
            name: '',
            alphabets: [],
            locations: [],
            groups: [],
        });
        /**
         * Initialize filter data.
         */
        const filterInitializer = () => {
            props.onReset ? props.onReset() : null;
            filterData.value = {
                name: '',
                alphabets: [],
                locations: [],
                groups: [],
            };
            selectedTab.value = nameTab;
        };

        /**
         * Handle filter items selection.
         * @param type should be one of these three alphabets, locations, groups.
         * @param value selected item.
         */
        const handleSelectAlphabetTab = (alphabet: string) =>
            filterData.value.alphabets.includes(alphabet)
                ? filterData.value.alphabets.splice(filterData.value.alphabets.indexOf(alphabet), 1)
                : filterData.value.alphabets.push(alphabet);

        const handleSelectTab = (type: string, id: number) => {
            let types: { locations: number[]; groups: number[] };
            type ObjectKey = keyof typeof types;
            filterData.value[type as ObjectKey].includes(id)
                ? filterData.value[type as ObjectKey].splice(filterData.value[type as ObjectKey].indexOf(id), 1)
                : filterData.value[type as ObjectKey].push(id);
        };
        // Run filter when filter data changed.
        watch(
            () => filterData.value,
            () => {
                props.getFilterData ? props.getFilterData(filterData.value) : null;
            },
            { deep: true }
        );
        // After this component mounted
        onMounted(() => filterInitializer());
        return {
            nameTab,
            locationTab,
            groupTab,
            selectedTab,
            filterInitializer,
            filterData,
            handleSelectAlphabetTab,
            handleSelectTab,
            alphabets,
        };
    },
};
