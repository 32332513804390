
/**
 * Activity card component containing text and numbers of current page.
 * @param {number} digits digits shows on the person activity left side numbers
 * @param {string} text title to shows the person activity right side text.
 */
export default {
    name: 'ActivityCard',
    props: {
        digits: { type: Number, required: true },
        text: { type: String, required: true },
    },
};
