import { UserType } from '@/helper/enums/melovie.enum';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import { AlbumLike, ArtistLike, TrackFavorite } from '@/helper/interface/favorite.interface';
import { Track } from '@/helper/interface/music/track.interface';
import { PlayListStaff } from '@/helper/interface/playlist.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { debounce, getIconUrl, isContainSubString } from '@/helper/utils/genericUtils';
import router from '@/router';
import { getAssets } from '@/services/assetsService';
import { getTrack } from '@/services/music/trackService';
import {
    currentSelectedLibraryPage,
    isAlertOpened,
    isLoading,
    openAlertPopup,
    openPlaylistCreator,
    openSouvenirCreator,
    participantFavorites,
    participantPlaylists,
    setMusicPlayerData,
    staffFavorites,
    staffPlaylists,
} from '@/services/sharedService';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export function useGenericLibrary() {
    const { t } = useI18n();
    const currentUserType = ref<UserType>(UserType.Staff);
    const filteredData = ref<MusicalListItem[]>([]);
    const currentData = ref<MusicalListItem[]>([]);
    const userPlaylist = ref<MusicalListItem[]>([]);
    const userFavoriteTracks = ref<MusicalListItem[]>([]);
    const userFavoriteArtists = ref<MusicalListItem[]>([]);
    const userFavoriteAlbums = ref<MusicalListItem[]>([]);

    const onAddToPlaylist = (track: MusicalListItem) => {
        openPlaylistCreator([track]);
    };

    const onAddToSouvenir = (track: MusicalListItem) => {
        openSouvenirCreator(track);
    };

    const onSearch = debounce((search: string, filters: MusicalListItemType[]) => {
        onFilterChange(filters);
        filteredData.value = filteredData.value.filter(
            (filData: MusicalListItem) =>
                isContainSubString(search, filData.title) ||
                (filData.artist && isContainSubString(search, filData.artist))
        );
    });

    const onFilterChange = (filters: MusicalListItemType[]) => {
        filteredData.value = [];
        if (!filters.length)
            filteredData.value = [
                ...userPlaylist.value,
                ...userFavoriteArtists.value,
                ...userFavoriteAlbums.value,
                ...userFavoriteTracks.value,
            ];
        else {
            filters.forEach((filter: MusicalListItemType) => {
                switch (filter) {
                    case MusicalListItemType.Artist:
                        if (!filters.includes(MusicalListItemType.Favorite))
                            filteredData.value.push(...userFavoriteArtists.value);
                        break;
                    case MusicalListItemType.Album:
                        if (!filters.includes(MusicalListItemType.Favorite))
                            filteredData.value.push(...userFavoriteAlbums.value);
                        break;
                    case MusicalListItemType.Playlist:
                        filteredData.value.push(...userPlaylist.value);
                        break;
                    case MusicalListItemType.Track:
                        filteredData.value.push(...userFavoriteTracks.value);
                        break;
                }
            });
        }
    };

    const initializeLibraryData = async () => {
        try {
            if (currentUserType.value === UserType.Staff) {
                if (staffFavorites.value) {
                    if (staffFavorites.value.tracks) {
                        userFavoriteTracks.value = await Promise.all(
                            staffFavorites.value.tracks.map(async (track: TrackFavorite) => {
                                let result: MusicalListItem = {
                                    id: track.id,
                                    title: track.name || '',
                                    artist: track.artist || '',
                                    description: track.description || '',
                                    asset: track.asset ?? -1,
                                    type: MusicalListItemType.Track,
                                };
                                return result;
                            })
                        );
                    }
                    if (staffFavorites.value.artists) {
                        userFavoriteArtists.value = await Promise.all(
                            staffFavorites.value.artists.map(async (art: ArtistLike) => {
                                let result: MusicalListItem = {
                                    id: art.id,
                                    title: art.name || '',
                                    description: art.description || '',
                                    asset: art.asset ?? -1,
                                    type: MusicalListItemType.Artist,
                                };
                                return result;
                            })
                        );
                    }
                    if (staffFavorites.value.albums) {
                        userFavoriteAlbums.value = await Promise.all(
                            staffFavorites.value.albums.map(async (alb: AlbumLike) => {
                                let result: MusicalListItem = {
                                    id: alb.id,
                                    title: alb.name || '',
                                    description: alb.description || '',
                                    asset: alb.asset ?? -1,
                                    type: MusicalListItemType.Album,
                                };
                                return result;
                            })
                        );
                    }
                }
                userPlaylist.value = await Promise.all(
                    staffPlaylists.value.map(async (playlist: PlayListStaff) => {
                        let result: MusicalListItem = {
                            id: playlist.play_id,
                            title: playlist.play_name || '',
                            asset: playlist.play_ass_id ?? -1,
                            type: MusicalListItemType.Playlist,
                        };
                        return result;
                    })
                );
            } else if (currentUserType.value === UserType.Participant) {
                if (participantFavorites.value) {
                    if (participantFavorites.value.tracks) {
                        userFavoriteTracks.value = await Promise.all(
                            participantFavorites.value.tracks.map(async (track: TrackFavorite) => {
                                let result: MusicalListItem = {
                                    id: track.id,
                                    title: track.name || '',
                                    artist: track.artist || '',
                                    description: track.description || '',
                                    asset: track.asset ?? -1,
                                    type: MusicalListItemType.Track,
                                };
                                return result;
                            })
                        );
                    }
                    if (participantFavorites.value.artists) {
                        userFavoriteArtists.value = await Promise.all(
                            participantFavorites.value.artists.map(async (art: ArtistLike) => {
                                let result: MusicalListItem = {
                                    id: art.id,
                                    title: art.name || '',
                                    description: art.description || '',
                                    asset: art.asset ?? -1,
                                    type: MusicalListItemType.Artist,
                                };
                                return result;
                            })
                        );
                    }
                    if (participantFavorites.value.albums) {
                        userFavoriteAlbums.value = await Promise.all(
                            participantFavorites.value.albums.map(async (alb: AlbumLike) => {
                                let result: MusicalListItem = {
                                    id: alb.id,
                                    title: alb.name || '',
                                    description: alb.description || '',
                                    asset: alb.asset ?? -1,
                                    type: MusicalListItemType.Album,
                                };
                                return result;
                            })
                        );
                    }
                }

                userPlaylist.value = await Promise.all(
                    participantPlaylists.value.map(async (playlist: PlayListStaff) => {
                        let result: MusicalListItem = {
                            id: playlist.play_id,
                            title: playlist.play_name || '',
                            asset: playlist.play_ass_id ?? -1,
                            type: MusicalListItemType.Playlist,
                        };
                        return result;
                    })
                );
            }
        } catch (error) {
            isLoading.value = false;
            openAlertPopup({
                confirmType: ConfirmType.Error,
                message: `${error}`,
                right: {
                    text: t('generic.back'),
                    onClick: () => {
                        isAlertOpened.value = false;
                    },
                },
            });
        }
    };

    const onRowClick = (row: MusicalListItem) => {
        switch (row.type) {
            case MusicalListItemType.Track:
                isLoading.value = true;
                getTrack([row.id!])
                    .then((trackData: Track[]) => {
                        if (trackData.length) {
                            setMusicPlayerData(trackData);
                            router.push('/music-player');
                        } else
                            openAlertPopup({
                                confirmType: ConfirmType.Error,
                                message: t('generic.noData'),
                                right: {
                                    text: t('generic.back'),
                                    onClick: () => {
                                        isAlertOpened.value = false;
                                    },
                                },
                            });
                    })
                    .catch((error) =>
                        openAlertPopup({
                            confirmType: ConfirmType.Error,
                            message: error,
                            right: {
                                text: t('generic.back'),
                                onClick: () => {
                                    isAlertOpened.value = false;
                                },
                            },
                        })
                    )
                    .finally(() => (isLoading.value = false));
                break;
            case MusicalListItemType.Playlist:
                router.push(
                    `${currentUserType.value == UserType.Participant ? '/participant' : ''}/playlist/${row.id}`
                );
                break;
        }
    };

    /**
     * save current selected page in cache
     *
     * @param {number} page selected page number
     */
    const getCurrentSelectedPage = (page: number) => {
        currentSelectedLibraryPage.value = page;
    };

    return {
        currentData,
        filteredData,
        currentUserType,
        onSearch,
        onRowClick,
        onFilterChange,
        onAddToPlaylist,
        onAddToSouvenir,
        initializeLibraryData,
        getCurrentSelectedPage,
    };
}
