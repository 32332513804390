
import Header from '@/components/header/HeaderComponent.vue';
import LaunchButton from '@/components/launch_button/LaunchButtonComponent.vue';
import NotificationButton from '@/components/notification_button/NotificationButtonComponent.vue';
import FavoriteCard from '@/components/favorite_card/FavoriteCardComponent.vue';
import Chart from '@/components/chart/ChartComponent.vue';
import { defineComponent, onMounted, ref } from 'vue';
import ActivityCard from '@/components/activity_card/ActivityCardComponent.vue';
import { newStaffQuestionnaireCount } from '@/services/sharedService';
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import { QuestionnaireType, UserType } from '@/helper/enums/melovie.enum';
import QuestionnaireModal from '@/components/questionnaire_modal/QuestionnaireComponent.vue';
import { useGenericProfile } from '@/composable/useGenericProfile';

export default defineComponent({
    name: 'Profile',
    components: {
        Header,
        LaunchButton,
        NotificationButton,
        FavoriteCard,
        ActivityCard,
        Chart,
        Confirm,
        QuestionnaireModal,
    },
    setup() {
        const {
            favoriteGenresItems,
            favoritePeriodsItems,
            favoriteArtistsItems,
            favoriteTrackItems,
            isFirstConnectionPopup,
            onEdit,
            onOpenEval,
            showQuestionnairePopup,
            closePopup,
            questionnaireData,
            onSubmitQuestionnaire,
            firstConnectionPopupData,
            isPopupFinalResult,
            currentUserType,
            initializeProfile,
            checkAnsweredQuestionnaire,
        } = useGenericProfile();

        /// TODO: A faire plus tard !
        // User activity
        const totalResidents = ref<number>(53);
        const totalMemories = ref<number>(102);
        const totalHours = ref<number>(26);
        const totalPlaylists = ref<number>(4);

        // Establishment activity
        const activeResidents = ref<number>(40);
        const wellnessRate = ref<number>(75);
        const activeRelatives = ref<number>(25);
        /// Fin TODO

        onMounted(async () => {
            currentUserType.value = UserType.Staff;
            await initializeProfile();
        });

        return {
            totalResidents,
            totalMemories,
            totalHours,
            totalPlaylists,
            activeResidents,
            activeRelatives,
            wellnessRate,
            QuestionnaireType,
            newStaffQuestionnaireCount,
            // generics
            favoriteGenresItems,
            favoritePeriodsItems,
            favoriteArtistsItems,
            favoriteTrackItems,
            isFirstConnectionPopup,
            showQuestionnairePopup,
            questionnaireData,
            firstConnectionPopupData,
            isPopupFinalResult,
            onEdit,
            onOpenEval,
            closePopup,
            onSubmitQuestionnaire,
            checkAnsweredQuestionnaire,
        };
    },
});
