
import { defineComponent, onUnmounted } from 'vue';
import ResearchFilter from '@/components/research_filter/ResearchFilterComponent.vue';
import MusicalList from '@/components/musical_list/MusicalListComponent.vue';
import Modal from '@/components/confirm_modal/ConfirmModalComponent.vue';
import Paginator from '@/components/paginator/PaginatorComponent.vue';
import { useSearch } from '@/composable/useSearch';
import { resetResearchData, isResearchResultGenerated } from '@/services/sharedService';
import { useRoute } from 'vue-router';
export default defineComponent({
    name: 'Search',
    components: { Modal, ResearchFilter, MusicalList, Paginator },
    setup() {
        const route = useRoute();
        const {
            filterCount,
            researchText,
            researchedData,
            filterModalData,
            isFilterModalOpened,
            researchFilteredData,
            researchSelectedGenres,
            researchSelectedPeriods,
            isResearchResultGenerated,
            researchSelectedFavGenres,
            currentSelectedResearchPage,
            onHate,
            onReset,
            onFavorite,
            onRowClick,
            onSearchClick,
            onFilterClick,
            onAddToSouvenir,
            getCurrentSelectedPage,
        } = useSearch();

        onUnmounted(() => {
            // if single music player view going to open, then don't reset research data
            if (route.name !== 'MusicPlayer') resetResearchData(); // else reset
        });
        return {
            filterCount,
            researchText,
            researchedData,
            filterModalData,
            isFilterModalOpened,
            researchFilteredData,
            researchSelectedGenres,
            researchSelectedPeriods,
            isResearchResultGenerated,
            researchSelectedFavGenres,
            currentSelectedResearchPage,
            onHate,
            onReset,
            onFavorite,
            onRowClick,
            onSearchClick,
            onFilterClick,
            onAddToSouvenir,
            getCurrentSelectedPage,
        };
    },
});
