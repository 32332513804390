import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { MusicalListItemType } from '@/helper/enums/ui.enum';
import { AssetSourceEnum } from '@/helper/enums/music.enum';

export function getSource(item: MusicalListItem) {
  switch (item.type) {
      case MusicalListItemType.Track:
      case MusicalListItemType.Album:
          return AssetSourceEnum.Album;
      case MusicalListItemType.Artist:
          return AssetSourceEnum.Artist;
      default:
          return AssetSourceEnum.Album;
  };
};

export function getDefault(item: MusicalListItem) {
  switch (item.type) {
      case MusicalListItemType.Artist:
          return '/profiles/avatar.png';
      case MusicalListItemType.Album:
      case MusicalListItemType.Playlist:
          return '/musics/playlist.png';
      default:
          return '/musics/music-round.png';
  };
};