import { api, musicApiPrefix } from '@/api';
import { Album, AlbumDetailed, Albums, GetAlbum, GetAlbums } from '@/helper/interface/music/album.interface';

/**
 * Get album
 *
 * @return {*}  {Promise<Album[]>}
 */
export const getAlbumApi = async (reqBody: GetAlbum): Promise<AlbumDetailed[]> => {
    const result = await api.post(`${musicApiPrefix}/album/get`, reqBody);
    return result.data;
};

export const getMultipleAlbumsApi = async (reqBody: GetAlbums): Promise<AlbumDetailed[]> => {
    const result = await api.post(`${musicApiPrefix}/album/gets`, reqBody);
    return result.data;
};

/**
 * Get all albums
 *
 * @return {*}  {Promise<Album[]>}
 */
export const getAllAlbumsApi = async (reqBody: Albums): Promise<Album[]> => {
    const result = await api.post(`${musicApiPrefix}/album/list`, reqBody);
    return result.data;
};
