
import { defineComponent, onMounted, PropType, ref } from 'vue';
import ModalSidebarComponent from '@/components/modal_sidebar/ModalSidebarComponent.vue';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { MusicalListItemType } from '@/helper/enums/ui.enum';
import Paginator from '@/components/paginator/PaginatorComponent.vue';
import { debounce, isContainSubString } from '@/helper/utils/genericUtils';
import ImageComponent from '@/components/ImageComponent.vue';
import { getDefault, getSource } from '@/helper/utils/MusicalTypeImageComponent';

/**
 * Component to choose musical list item.
 *
 * @param {MusicalListItem[]} items item array
 * @param {MusicalListItem[]} selectedItems selected item array
 * @param {string} title shows the title
 * @param {string} listTitle selected item header.
 * @param {string} searchPlaceholder text search placeholder
 * @param {string} noItemFound text for no item found
 */
export default defineComponent({
    name: 'QuestionnaireInputComponent',
    props: {
        items: { type: Array as PropType<MusicalListItem[]>, required: true },
        selectedItems: { type: Array as PropType<MusicalListItem[]>, required: true },
        title: { type: String, required: true },
        listTitle: { type: String, required: true },
        searchPlaceholder: { type: String, required: true },
        noItemFound: { type: String, required: true },
    },
    components: { ModalSidebarComponent, Paginator, ImageComponent },
    setup(props: any) {
        const filteredData = ref<MusicalListItem[]>([]);
        const searchedData = ref<MusicalListItem[]>([]);
        const searchText = ref<string>('');

        /**
         * Handling the object when user selects an item
         *
         * @param item MusicalListItem
         */
        const handleSelectedItem = (item: MusicalListItem) => {
            const index = (props.items as MusicalListItem[]).findIndex(
                (element: MusicalListItem) => element.id === item.id
            );
            if (index >= 0) (props.items as MusicalListItem[]).splice(index, 1);
            props.selectedItems.push(item);
            onSearch(searchText.value);
        };

        /**
         * Handling the object when user deselects an item
         *
         * @param item MusicalListItem
         */
        const handleDeselectItem = (item: MusicalListItem) => {
            const index = (props.selectedItems as MusicalListItem[]).findIndex(
                (element: MusicalListItem) => element.id === item.id
            );
            if (index >= 0) (props.selectedItems as MusicalListItem[]).splice(index, 1);
            props.items.push(item);
            onSearch(searchText.value);
        };

        /**
         * Handling search
         *
         * @param event input event
         */
        const handleSearch = (event: any) => {
            searchText.value = event.target.value;
            debounce(onSearch)(searchText.value);
        };

        /**
         * search in item list
         *
         * @param search string
         */
        const onSearch = (search: string) => {
            searchedData.value = props.items.filter(
                (filData: MusicalListItem) =>
                    isContainSubString(search, filData.title) ||
                    (filData.artist && isContainSubString(search, filData.artist))
            );
        };

        onMounted(() => {
            // remove already selected items from main list
            (props.selectedItems as MusicalListItem[]).forEach((item: MusicalListItem) => {
                const index = (props.items as MusicalListItem[]).findIndex(
                    (element: MusicalListItem) => element.id === item.id
                );
                if (index >= 0) (props.items as MusicalListItem[]).splice(index, 1);
            });
            onSearch('');
        });

        return {
            searchText,
            filteredData,
            searchedData,
            MusicalListItemType,
            handleSearch,
            handleDeselectItem,
            handleSelectedItem,
            getSource,
            getDefault,
        };
    },
});
