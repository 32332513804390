
import { ConfirmType } from '@/helper/enums/ui.enum';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import { defineComponent, PropType } from 'vue';

/**
 * Alert to show alert popup.
 * @param {Object} data data is an object having all needed values for confirm modal popup.
 * @param {ConfirmType} data.confirmType shows the type of component- alert/error/success.
 * @param {String} data.headerIcon shows the icon of the component header.
 * @param {string} data.title shows the title of the component.
 * @param {string} data.message shows the content/message of the component.
 * @param {any} data.onClose is a function to close the component.
 * @param {any} data.onCancel is a function to be shown in the bottom left section of the component.
 * @param {any} data.onSubmit is a function to be shown in the bottom right section of the component to submit;
 */
export default defineComponent({
    name: 'ConfirmModalComponent',
    props: {
        data: { type: Object as PropType<ConfirmProps>, required: true },
        maxHeight: { type: String, default: '40vh' },
        maxWidth: { type: String, default: '50vw' },
    },
    setup() {
        return { ConfirmType };
    },
});
