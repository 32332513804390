import { api, melovieApiPrefix } from '@/api';
import { Session, SessionData } from '@/helper/interface/session.interface';

/**
 * Set Session
 *
 * @param {SessionData} reqBody
 * @return {*}  {Promise<Session>}
 */
export const setSessionDataApi = async (reqBody: SessionData): Promise<Session> => {
    const result = await api.post(`${melovieApiPrefix}/session`, reqBody);
    return result.data;
};
