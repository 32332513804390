import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-576d2a38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "genre-body-container" }
const _hoisted_2 = { class: "genre-container" }
const _hoisted_3 = { class: "genre-list" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "text"
}
const _hoisted_6 = { class: "genre-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageComponent = _resolveComponent("ImageComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genres, (genre, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "genre",
            key: index
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["image-container", { selected: _ctx.isGenreSelected(genre) }]),
              onClick: ($event: any) => (_ctx.onSelect && _ctx.onSelect(genre))
            }, [
              (genre.gmus_ass_id)
                ? (_openBlock(), _createBlock(_component_ImageComponent, {
                    key: 0,
                    assId: genre.gmus_ass_id ?? -1,
                    source: _ctx.ref,
                    class: "icon-image"
                  }, null, 8, ["assId", "source"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(genre.gmus_label), 1)
                  ]))
            ], 10, _hoisted_4),
            _createElementVNode("span", _hoisted_6, _toDisplayString(genre.gmus_label), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}