
import FavoriteCardComponent from '@/components/favorite_card/FavoriteCardComponent.vue';
import { FavoriteCardItem } from '@/helper/interface/ui/components/favoriteCard';
import Header from '@/components/header/HeaderComponent.vue';
import { defineComponent, ref } from 'vue';
import GenreList from '@/components/genre_list/GenreList.vue';
import PeriodList from '@/components/period_list/PeriodList.vue';
import { useQuestionnaire } from '@/composable/useQuestionnaire';
import { useRouter } from 'vue-router';
import { Genre } from '@/helper/interface/music/genre.interface';
import { Period } from '@/helper/interface/music/period.interface';
import { getAllTracks } from '@/services/music/trackService';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { isAlertOpened, isLoading, musicPlayerData, openAlertPopup } from '@/services/sharedService';
import { Track } from '@/helper/interface/music/track.interface';
import { ConfirmType } from '@/helper/enums/ui.enum';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    name: 'ParticipantDiscovery',
    components: {
        FavoriteCardComponent,
        Header,
        PeriodList,
        GenreList,
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const recommendedTracks = ref<FavoriteCardItem[]>([]);
        const playlistTracks = ref<FavoriteCardItem[]>([]);
        const { selectedPeriods, onSelectPeriod, selectedGenres, onSelectGenre } = useQuestionnaire();
        const trackCount = ref<number>(5);

        /**
         * traceTrackCount is a function to set the track filter value
         *
         * @param count :number is the value to set number of songs
         */
        const traceTrackCount = (count: number) => (trackCount.value = count);

        /**
         * playButtonClicked is a function to display filtermusics
         *
         */
        const playButtonClicked = () => {
            isLoading.value = true;
            getAllTracks(
                selectedGenres.value.map((genre: Genre) => genre.gmus_id).length
                    ? selectedGenres.value.map((genre: Genre) => genre.gmus_id)
                    : undefined,
                selectedPeriods.value.map((period: Period) => period.muspe_id).length
                    ? selectedPeriods.value.map((period: Period) => period.muspe_id)
                    : undefined,
                trackCount.value
            )
                .then((res: Track[]) => {
                    if (res?.length && res.length <= trackCount.value) {
                        musicPlayerData.value = res;
                        router.push('/discovery/music-player');
                    } else
                        openAlertPopup({
                            confirmType: ConfirmType.Error,
                            headerIcon: 'fa-triangle-exclamation',
                            message: t('views.participantDashboard.discovery.noMatchFound'),
                            right: {
                                text: t('generic.back'),
                                onClick: () => {
                                    isAlertOpened.value = false;
                                },
                            },
                        });
                })
                .catch((error) =>
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    })
                )
                .finally(() => (isLoading.value = false));
        };
        return {
            recommendedTracks,
            playlistTracks,
            selectedPeriods,
            selectedGenres,
            onSelectPeriod,
            onSelectGenre,
            traceTrackCount,
            trackCount,
            playButtonClicked,
        };
    },
});
