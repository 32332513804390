import { getAnimationPlaceApi, getServicesApi } from '@/api/listApi';
import { AnimationPlace } from '@/helper/interface/animationPlace.interface';
import { Service } from '@/helper/interface/service.interface';

/**
 * Get all services.
 *
 * @returns {Promise<Service[]>} list of services.
 */
export const getAllServices = () => {
    return new Promise<Service[]>((resolve, reject) => {
        getServicesApi()
            .then((data: Service[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
/**
 * Get all animation place.
 *
 * @returns {Promise<AnimationPlace[]>} list of animation place.
 */
export const getAllAnimationPlaces = () => {
    return new Promise<AnimationPlace[]>((resolve, reject) => {
        getAnimationPlaceApi()
            .then((data: AnimationPlace[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
