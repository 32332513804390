
import { defineComponent, ref } from 'vue';
import Sidebar from '@/components/sidebar/SidebarComponent.vue';
import { currentStaff } from '@/services/sharedService';
import { useI18n } from 'vue-i18n';
import { SidebarMenuItem } from '@/helper/interface/generic.interface';
import { Staff } from '@/helper/interface/staff.interface';
/**
 * layout of dashboard page
 */
export default defineComponent({
    name: 'Dashboard',
    components: {
        Sidebar,
    },
    setup() {
        const { t } = useI18n();
        const menuItems: SidebarMenuItem[] = [
            {
                id: 1,
                name: t('components.sidebar.profile'),
                icon: 'fa-user',
                path: '/profile',
            },
            {
                id: 2,
                name: t('components.sidebar.library'),
                icon: 'fa-heart',
                path: '/library',
            },
            {
                id: 3,
                name: t('components.sidebar.search'),
                icon: 'fa-magnifying-glass',
                path: '/search',
            },
            {
                id: 4,
                name: t('components.sidebar.session'),
                icon: 'fa-regular fa-circle-play',
                path: '/session',
            },
            {
                id: 5,
                name: t('components.sidebar.complete'),
                icon: 'fa-right-from-bracket',
                path: '/logout',
            },
        ];
        // get saved current user
        const currentUser = ref<Staff>();
        currentUser.value = currentStaff.value;
        return { menuItems, currentUser };
    },
});
