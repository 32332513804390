import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a7436678"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "data-container",
    style: _normalizeStyle({ maxHeight: $props.maxHeight })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.dataList, (data) => {
      return (_openBlock(), _createElementBlock("div", {
        key: data.id
      }, [
        _createElementVNode("div", {
          onClick: ($event: any) => ($props.onSelect ? $props.onSelect(data) : null, ($setup.selectedDataId = data.id)),
          class: _normalizeClass(["data", { 'selected-data': data.id === $setup.selectedDataId }]),
          style: _normalizeStyle({ width: $props.bubbleWidth })
        }, [
          _createElementVNode("span", null, _toDisplayString(data.value), 1)
        ], 14, _hoisted_1)
      ]))
    }), 128))
  ], 4))
}