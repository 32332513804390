import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f4335bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "final-body-container" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "number-container" }
const _hoisted_4 = { class: "title-container" }
const _hoisted_5 = { class: "icon-container checked" }
const _hoisted_6 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (evalData, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "row",
        onClick: ($event: any) => (_ctx.onRowClick && _ctx.onRowClick(evalData.eval_id))
      }, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(index + 1), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(evalData.eval_name), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_ctx.checkAnswered && _ctx.checkAnswered(evalData.eval_id))
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: "fa-check"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_font_awesome_icon, {
            icon: _ctx.checkAnswered && _ctx.checkAnswered(evalData.eval_id) ? 'fa-magnifying-glass' : 'fa-pen'
          }, null, 8, ["icon"])
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}