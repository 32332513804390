
import { defineComponent, PropType, onUnmounted } from 'vue';
import { MusicalListItemType } from '@/helper/enums/ui.enum';
import { selectedLibraryFilters, librarySearchText } from '@/services/sharedService';
export default defineComponent({
    name: 'LibraryFilterComponent',
    props: {
        onClick: { type: Function as PropType<(types: MusicalListItemType[]) => any> },
        onSearch: { type: Function as PropType<(search: string, types: MusicalListItemType[]) => any> },
    },
    setup(props: any) {
        const handleClick = (type: MusicalListItemType) => {
            selectedLibraryFilters.value.includes(type)
                ? selectedLibraryFilters.value.splice(selectedLibraryFilters.value.indexOf(type), 1)
                : selectedLibraryFilters.value.push(type);
            if (props.onSearch) props.onSearch(librarySearchText.value, selectedLibraryFilters.value);
        };

        const handleChange = (event: any) => {
            librarySearchText.value = event.target.value;
            if (props.onSearch) props.onSearch(librarySearchText.value, selectedLibraryFilters.value);
        };

        return {
            selectedLibraryFilters,
            librarySearchText,
            MusicalListItemType,
            handleClick,
            handleChange,
        };
    },
});
