
/**
 * Login page to authenticate an user.
 */
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import Header from '@/components/header/HeaderComponent.vue';
import BubbleList from '@/components/bubble_list/BubbleListComponent.vue';
import Filter from '@/components/filter/FilterComponent.vue';
import Loader from 'vue-loading-overlay';
import { onMounted, ref, computed } from 'vue';
import { connectStaff, getConnectionPageData } from '@/services/connectionService';
import {
    currentStaff,
    isAlertOpened,
    isAuthenticated,
    openAlertPopup,
    resetStaffData,
    resetParticipantData,
    resetMusicalSessionData,
    resetGenericData,
    currentAnimationPlace,
    currentSessionId,
} from '@/services/sharedService';
import { ConnectionPageData } from '@/helper/interface/ui/ui.interface';
import { BubbleListData } from '@/helper/interface/ui/components/bubbleList';
import { useFilter } from '@/composable/useFilter';
import { Staff } from '@/helper/interface/staff.interface';
import { IsGood } from '@/helper/interface/auth.interface';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { AnimationPlace } from '@/helper/interface/animationPlace.interface';
import { ConfirmType } from '@/helper/enums/ui.enum';
export default {
    name: 'LoginView',
    components: {
        Header,
        Loader,
        BubbleList,
        Filter,
        Confirm,
    },
    setup() {
        currentSessionId.value = undefined;
        const { users, userNames, locations, groups, filterData, resetFilter } = useFilter();
        const { t } = useI18n();
        const router = useRouter();
        const isLoading = ref<boolean>(true);
        const animationPlaces = ref<AnimationPlace[]>([]);
        const animationPlacesNames = ref<BubbleListData[]>([]);
        const selectedUser = ref<Staff>();
        const selectedPlaceName = ref<BubbleListData>();
        const selectedNum = ref<number[]>([]);
        const checkForDisableConnection = computed(() => {
            return selectedUser.value === undefined || selectedNum.value.length < 4 || (selectedPlaceName.value?.value?.length ?? 0) === 0
        })
        const showSelectedUser = computed(() => {
            let title = '';
            if (selectedUser?.value?.staf_firstname) {
                title += selectedUser?.value?.staf_firstname + ' ';
            }
            if (selectedUser?.value?.staf_lastname) {
                title += selectedUser?.value?.staf_lastname;
            }
            return title;
        });
        /**
         * Handle select user
         *
         * @param {BubbleListData} userName selected user name from interface.
         */
        const selectUser = (userName: BubbleListData) =>
            (selectedUser.value = users.value.find((user: Staff) => user.staf_id === userName.id));
        /**
         * Handle select place
         *
         * @param {BubbleListData} placeName selected place name from interface.
         */
        const selectPlace = (placeName: BubbleListData) => (selectedPlaceName.value = placeName);
        const numClick = (number: number) => {
            if (selectedNum.value.length < 4) {
                selectedNum.value.push(number);
            }
        };
        const clearNum = () => {
            if (selectedNum.value.length) selectedNum.value.pop();
        };
        const onConnect = () => {
            if (selectedUser.value && animationPlaces.value) {
                isLoading.value = true;
                connectStaff(selectedUser.value?.staf_id, selectedNum.value)
                    .then((res: IsGood) => {
                        if (res.good) {
                            isAuthenticated.value = true;
                            currentStaff.value = selectedUser.value;
                            if (currentAnimationPlace.value === undefined) {
                                currentAnimationPlace.value = {anp_id: -1, anp_label: '', anp_data: undefined};
                            }
                            currentAnimationPlace.value.anp_id = selectedPlaceName?.value?.id ?? -1;
                            currentAnimationPlace.value.anp_label = selectedPlaceName?.value?.value ?? '';
                            openAlertPopup({
                                confirmType: ConfirmType.Success,
                                title: t('generic.welcome'),
                                message: t('views.auth.connectedSuccessfully'),
                                right: {
                                    text: t('generic.ok'),
                                    onClick: () => {
                                        isAlertOpened.value = false;
                                        router.push('/profile');
                                    },
                                },
                            });
                        } else {
                            selectedNum.value = [];
                            openAlertPopup({
                                confirmType: ConfirmType.Error,
                                message: t('views.auth.wrongPin'),
                                right: {
                                    text: t('generic.back'),
                                    onClick: () => {
                                        isAlertOpened.value = false;
                                    },
                                },
                            });
                        }
                    })
                    .catch((error) =>
                        openAlertPopup({
                            confirmType: ConfirmType.Error,
                            message: error,
                            right: {
                                text: t('generic.back'),
                                onClick: () => {
                                    isAlertOpened.value = false;
                                },
                            },
                        })
                    )
                    .finally(() => (isLoading.value = false));
            }
        };
        // initialization
        onMounted(() => {
            // fetching data
            isLoading.value = true;
            resetGenericData();
            resetStaffData();
            resetParticipantData();
            resetMusicalSessionData();

            getConnectionPageData()
                .then((data: ConnectionPageData) => {
                    users.value = data.staffs;
                    userNames.value = users.value.map((user: Staff) => {
                        return { id: user.staf_id, value: `${user.staf_firstname} ${user.staf_lastname}` };
                    });
                    animationPlaces.value = data.animationPlaces;
                    animationPlacesNames.value = animationPlaces.value.map((anmPlace: AnimationPlace) => {
                        return { id: anmPlace.anp_id, value: anmPlace.anp_label };
                    }).sort((a, b) => { return a.value.toLowerCase().localeCompare(b.value.toLowerCase())});
                    groups.value = data.groups;
                    locations.value = data.services;
                })
                .catch((error) =>
                    openAlertPopup({
                        confirmType: ConfirmType.Error,
                        message: error,
                        right: {
                            text: t('generic.back'),
                            onClick: () => {
                                isAlertOpened.value = false;
                            },
                        },
                    })
                )
                .finally(() => (isLoading.value = false));
        });
        return {
            userNames,
            animationPlacesNames,
            selectedUser,
            selectedPlaceName,
            selectUser,
            selectPlace,
            locations,
            groups,
            filterData,
            resetFilter,
            numClick,
            selectedNum,
            clearNum,
            onConnect,
            t,
            isLoading,
            ConfirmType,
            showSelectedUser,
            checkForDisableConnection,
        };
    },
};
