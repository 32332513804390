
import { ref } from 'vue';
/**
 * chart component to show chart
 *
 * @param {number} value chart value.
 * @param {string} height chart height.
 */
export default {
    name: 'ChartComponent',
    props: {
        value: { type: Number, required: true },
        height: String,
    },
    setup(props: any) {
        const series = ref<number[]>([props.value]);
        const chartOptions = {
            colors: ['#178aa1'],
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        margin: 0,
                        size: '40%',
                    },
                    startAngle: -180,
                    endAngle: 180,
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            offsetY: 6,
                            color: '#000000',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            show: true,
                            fontFamily: 'Raleway',
                        },
                    },
                },
            },
        };
        return { chartOptions, series };
    },
};
