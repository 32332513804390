import { getAlbumApi, getAllAlbumsApi, getMultipleAlbumsApi } from '@/api/music/albumApi';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import { MusicalListItemType } from '@/helper/enums/ui.enum';
import { Album, Albums, GetAlbum, GetAlbums } from '@/helper/interface/music/album.interface';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { getIconUrl } from '@/helper/utils/genericUtils';
import { getAssets } from '../assetsService';

/**
 * Get all albums.
 *
 * @returns {Promise<Album[]>} list of albums.
 */
export const getAllAlbums = (genres?: number[], periods?: number[], count?: number) => {
    return new Promise<Album[]>((resolve, reject) => {
        const reqBody: Albums = {
            genres,
            periods,
            count,
        };
        getAllAlbumsApi(reqBody)
            .then((data: Album[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

/**
 * Get all albums as music list item.
 *
 * @returns {Promise<MusicalListItem[]>} list of albums.
 */
export const getAllAlbumList = (genres?: number[], periods?: number[], count?: number) => {
    return new Promise<MusicalListItem[]>(async (resolve, reject) => {
        try {
            const albums: Album[] = await getAllAlbums(genres, periods, count);
            const albumList: MusicalListItem[] = await Promise.all(
                albums.map(async (album: Album) => {
                    const result: MusicalListItem = {
                        id: album.alb_id,
                        title: album.alb_name || '',
                        description: album.alb_description || '',
                        asset: album.ass_id ?? -1,
                        type: MusicalListItemType.Album,
                    };
                    return result;
                })
            );
            resolve(albumList);
        } catch (error) {
            reject(error);
        }
    });
};

export const getAlbum = (albumId: number) => {
    return new Promise<Album>((resolve, reject) => {
        const reqBody: GetAlbum = {
            alb_id: albumId,
        };
        getAlbumApi(reqBody)
            .then((data: Album[]) => {
                resolve(data[0]);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getMultipleAlbums = (albumIds: number[]) => {
    return new Promise<Album[]>((resolve, reject) => {
        const reqBody: GetAlbums = {
            ids: albumIds,
        };
        getMultipleAlbumsApi(reqBody)
            .then((data: Album[]) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
