
import Confirm from '@/components/confirm_modal/ConfirmModalComponent.vue';
import Header from '@/components/header/HeaderComponent.vue';
import NotificationButton from '@/components/notification_button/NotificationButtonComponent.vue';
import FavoriteCard from '@/components/favorite_card/FavoriteCardComponent.vue';
import QuestionnaireModal from '@/components/questionnaire_modal/QuestionnaireComponent.vue';
import { defineComponent, onMounted, ref } from 'vue';
import Memory from '@/components/memory/MemoryComponent.vue';
import { QuestionnaireType, UserType } from '@/helper/enums/melovie.enum';
import { useGenericProfile } from '@/composable/useGenericProfile';
import {
    souvenirSelectedPageNumber,
    isSouvenirOpened,
    musicPlayerData,
    newParticipantQuestionnaireCount,
    openSouvenirCreator,
    participantMemories,
    isAlertOpened,
    isLoading,
    openAlertPopup,
} from '@/services/sharedService';
import { ConfirmProps } from '@/helper/interface/ui/ui.interface';
import { ConfirmType, MusicalListItemType } from '@/helper/enums/ui.enum';
import { useI18n } from 'vue-i18n';
import { getIconUrl, isContainSubString } from '@/helper/utils/genericUtils';
import { MusicalListItem } from '@/helper/interface/ui/components/musicalList.interface';
import { Souvenir } from '@/helper/interface/souvenir.interface';
import { maxNumberOfSouvenirInProfile } from '@/helper/utils/constants';
import { getTrack } from '@/services/music/trackService';
import { useRouter } from 'vue-router';
import { getAssets } from '@/services/assetsService';
import { AssetSourceEnum } from '@/helper/enums/music.enum';
import Paginator from '@/components/paginator/PaginatorComponent.vue';

export default defineComponent({
    name: 'Profile',
    components: {
        Confirm,
        Header,
        NotificationButton,
        FavoriteCard,
        Memory,
        Paginator,
        QuestionnaireModal,
    },
    setup() {
        const { t } = useI18n();
        const isDropdownOpen = ref<boolean>(false);
        const isShowAllMemory = ref<boolean>(false);
        const filterByMonthDays = ref<number>(30);
        const searchedText = ref<string>('');
        const allMemoryPopupData = ref<ConfirmProps>({
            confirmType: ConfirmType.Alert,
            title: t('generic.memory'),
            headerIcon: 'fa-file-signature',
            onClose: () => {
                isShowAllMemory.value = false;
                searchedText.value = '';
                souvenirSelectedPageNumber.value = 1;
            },
        });
        const allMemories = ref<Souvenir[]>([]);
        const currentMemories = ref<Souvenir[]>([]);
        const profileMemories = ref<Souvenir[]>([]);
        const router = useRouter();
        const selectedDatePeriod = ref<string>(`1 ${t('generic.month')}`);
        const editSouvenirPopup = ref<boolean>(false);
        const {
            favoriteGenresItems,
            favoritePeriodsItems,
            favoriteArtistsItems,
            favoriteTrackItems,
            isFirstConnectionPopup,
            onEdit,
            onOpenEval,
            showQuestionnairePopup,
            closePopup,
            questionnaireData,
            onSubmitQuestionnaire,
            firstConnectionPopupData,
            isPopupFinalResult,
            currentUserType,
            initializeProfile,
            checkAnsweredQuestionnaire,
        } = useGenericProfile();

        // Function to compare dates
        const compareDates = (a: Souvenir, b: Souvenir) => {
            const dateA: Date = new Date(a.date);
            const dateB: Date = new Date(b.date);
            return dateB.getTime() - dateA.getTime();
        };

        /**
         * Filtering souvenir data depending on date period and search result
         *
         * @param {number} days filtering souvenir depending on days
         */
        const getFilteredData = (days: number) => {
            filterByMonthDays.value = days;
            switch (filterByMonthDays.value) {
                case 30:
                    selectedDatePeriod.value = `1 ${t('generic.month')}`; // Storing filtered period
                    break;
                case 90:
                    selectedDatePeriod.value = `3 ${t('generic.month')}`; // Storing filtered Period
                    break;
                case 180:
                    selectedDatePeriod.value = `6 ${t('generic.month')}`; // Storing filtered Period
                    break;
                case 270:
                    selectedDatePeriod.value = `9 ${t('generic.month')}`; // Storing filtered period
                    break;
                case 365:
                    selectedDatePeriod.value = `1 ${t('generic.year')}`; // Storing filtered period
                    break;
            }
            const today = new Date();

            // Getting the date of nth days ago
            const nthDaysAgo: Date = new Date(today.setDate(today.getDate() - filterByMonthDays.value));

            // Filter memories based on the date range
            const filteredMemories = participantMemories.value.filter(
                (item: Souvenir) =>
                    nthDaysAgo.getTime() < new Date(item.date).getTime() &&
                    (isContainSubString(searchedText.value, item.title || '') ||
                        isContainSubString(searchedText.value, item.description) ||
                        isContainSubString(searchedText.value, item.track?.artist || ''))
            );

            allMemories.value = filteredMemories.sort(compareDates);
        };

        /**
         * handle memory popup open/close
         *
         *
         */
        const handleAllSouvenirs = () => {
            getFilteredData(filterByMonthDays.value);
            isShowAllMemory.value = true;
        };

        /**
         * handle memory play button function
         *
         * @param {number} musicId for playing track
         */
        const handlePlayButton = async (musicId: number) => {
            try {
                isLoading.value = true;
                const music = await getTrack([musicId]);
                musicPlayerData.value = music;
                isSouvenirOpened.value = true;
                router.push('/music-player');
            } catch (error) {
                openAlertPopup({
                    confirmType: ConfirmType.Error,
                    message: `${error}`,
                    right: {
                        text: t('generic.back'),
                        onClick: () => {
                            isAlertOpened.value = false;
                        },
                    },
                });
            } finally {
                isLoading.value = false;
            }
        };

        /**
         * handle searched memory
         *
         * @param { HTMLInputEvent } event input event
         */
        const handleSearchedText = (event: any) => {
            searchedText.value = event.target.value;
            getFilteredData(filterByMonthDays.value);
        };

        /**
         * handle souvenir edit popup
         *
         * @param { HTMLInputEvent } event input event
         */
        const handleEditSouvenirPopup = async (memory: Souvenir) => {
            let musicalItem: MusicalListItem;
            if (memory.track?.id) {
                musicalItem = {
                    id: memory.track.id,
                    title: memory.track.name || '',
                    asset: -1,
                    artist: memory.track.artist,
                    description: memory.track.description,
                    type: MusicalListItemType.Track,
                };
            } else if (memory.artist?.id) {
                musicalItem = {
                    id: memory.artist.id,
                    title: memory.artist.name || '',
                    asset: memory.artist.asset ?? -1,
                    description: memory.artist.description,
                    type: MusicalListItemType.Artist,
                };
            } else if (memory.album?.id) {
                musicalItem = {
                    id: memory.album.id,
                    title: memory.album.name || '',
                    asset: memory.album.asset ?? -1,
                    description: memory.album.description,
                    type: MusicalListItemType.Album,
                };
            }
            openSouvenirCreator(musicalItem!, memory);
        };

        /**
         * getting current page selected in pagination
         *
         * @param { number } page current page number
         */
        const getCurrentPage = (page: number) => {
            souvenirSelectedPageNumber.value = page;
        };

        onMounted(async () => {
            currentUserType.value = UserType.Participant;
            await initializeProfile();
            // Filtering & sorting souvenirs to show in participant profile souvenir section
            profileMemories.value = participantMemories.value.slice(
                participantMemories.value.length - maxNumberOfSouvenirInProfile,
                participantMemories.value.length
            );
            profileMemories.value?.sort(compareDates);
            if (isSouvenirOpened.value === true) {
                handleAllSouvenirs();
                isSouvenirOpened.value = false;
            }
        });

        return {
            newParticipantQuestionnaireCount,
            QuestionnaireType,
            // memory
            searchedText,
            isShowAllMemory,
            allMemoryPopupData,
            handleAllSouvenirs,
            handleSearchedText,
            allMemories,
            maxNumberOfSouvenirInProfile,
            handlePlayButton,
            getFilteredData,
            selectedDatePeriod,
            isDropdownOpen,
            profileMemories,
            handleEditSouvenirPopup,
            editSouvenirPopup,
            currentMemories,
            souvenirSelectedPageNumber,
            getCurrentPage,
            // generics
            favoriteGenresItems,
            favoritePeriodsItems,
            favoriteArtistsItems,
            favoriteTrackItems,
            isFirstConnectionPopup,
            participantMemories,
            onEdit,
            onOpenEval,
            showQuestionnairePopup,
            closePopup,
            questionnaireData,
            onSubmitQuestionnaire,
            firstConnectionPopupData,
            isPopupFinalResult,
            checkAnsweredQuestionnaire,
        };
    },
});
